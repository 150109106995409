.settings-container {
    margin: 100px auto;
    max-width: unset !important;
    // padding: 0px 30px;
    svg {
        height: 16px;
        width: 18px;
        // margin-right: 15px;
    }

    .settings-subscription-state {
        display: flex;
        justify-content: space-between;

        .settings-subscription-span-state {
            display: flex;
            align-items: center;
        }

        svg {
            margin-right: 6px;
            height: 6px;
            width: 6px;
			color: #03c5ad;
        }
    }

    .settings-subscription {
        display: block;
        background-color: #191c21;
        padding: 30px;
        border-radius: 8px;
        margin-bottom: 10px;
    }
    
    .settings-subscription-hr-line {
        width: 100%;
        height: 2px;
        margin: 10px auto;
        background: #FFFFFF;
        opacity: 0.7;
        text-align: center;
    }

    .settings-subscription-content {
        .settings-subscription-plan-content{
            div{
                font-family: 'latoregular';
                font-size: 18px;
            }
            small{
                font-size: 18px;
                font-family: 'latoregular';
                color: #5fc4b8;
            }
        }
        .settings-subscription-no-subscription {
            button {
                margin-top:20px;
                width: 100%;
                display: block;
                // background-color: #02aab0;
            }
        }
    }
}

.settings-head {
    background-color: #191c21;
    padding: 30px;
    margin-bottom: 30px;
    display: flex;
    // gap: 20px;
    align-items: center;
    border-radius: 8px;
    font-size: 18px;
    span {
        color: #9d9d9d;
        font-size: 18px;
        font-weight: 600;
    }
    .breadcrumbs-links{
        a{
            cursor: default;
            &:hover{
                color: #9d9d9d;
            }
        }
    }

    svg{
        // transform: rotate3d(50, 50, 0, 180deg);
        margin-right: 10px;
        cursor: pointer;
    }
}

.setting-icons {
    display: block;
    background-color: #191c21;
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 10px;

    .nav-item {
        a {
            padding: 20px;
            border-radius: 6px;
            margin-bottom: 20px;
            color: #fff;
            cursor: pointer;
            font-weight: 600;

            svg {
                margin-right: 10px;
            }
        }

        .nav-link.active {
            background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
        }
    }
}

.delete-modal{
    max-width: 800px !important;
    .modal-content{
        background: none;
        .signup-box{
            h3{
                text-align: center;
                margin: 30px 0 15px;
            }
        }
    }
    .myaccount-info{
        width: 100%;
        .myaccount-info-title{
            justify-content: center;
            padding: 0;
    
            h3{
                font-size: 24px;
            }
        }
        .myaccount-head-btn{
            gap: 15px;
            margin: 5px 0 15px;
            span{
                height:unset;
                cursor: pointer;
                &.danger{
                    border: 1px solid #ff3f3f;
                    background: none;
                    color: #ff3f3f;
                    transition: all 0.2s ease-in;
                    &:hover{
                        transition: all 0.2s ease-in;
                        border: 1px solid #ff3f3f;
                        background: #ff3f3f;
                        color: #000;
                    }
                }
                &.green{
                    border: 1px solid #d2d2d2;
                    background: #d2d2d2;
                    color: #000000;
                    transition: all 0.2s ease-out;
                    &:hover{
                        transition: all 0.2s ease-in;
                        border: 1px solid #d2d2d2;
                        background: #24282F;
                        color: #d2d2d2;
                    }
                }
                &.disabled{
                    border: 1px solid #d2d2d23b;
                    background: none;
                    color: #ffffff47;
                    cursor: default;
                }
            }
        }
        .myaccount-checkbox{
            margin: 10px 0;
            text-align: center;
            // display: flex;
            // justify-content: center;
            // align-items: baseline;
            input{
                cursor: pointer;
                vertical-align: middle;
            }
            label{
                margin: 0 0 0 10px;
                font-size: 12px;
            }
        }
    }
}

.delete-account-box{
    // border: 1px solid #ccc;
    padding: 30px;
    border-radius: 8px;
    background-color: #191c21;
    span{
        border: 1px solid #ff3f3f;
        color: #ff3f3f;
        display: inline-block;
        width: 100%;
        padding: 10px 20px;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
        svg{
            margin-right: 10px;
        }
    }
}

.account-delete-otp-container{
    text-align: center;
    // width: 60%;
    margin: 0 auto;
    padding: 30px 0 15px;
    label{
        margin-bottom: 20px;
    }
    .otp-separator{
        color: white;
    }
    .otp-input{
        border: 1px solid #d2d2d2;
        background: transparent;
        border-radius: 10px;
        width: 100% !important;
        text-align: center;
        font-size: 20px;
        line-height: 3;
        margin: 0 10px;
        outline:  none;
        color: white;
    }
    .myaccount-head-btn{
        gap: 15px;
        .cancel{
            border: 1px solid #d2d2d2;
            background: none;
            color: #d2d2d2;
            transition: all 0.2s ease-out;
            &:hover{
                transition: all 0.2s ease-in;
                border: 1px solid #dbdbdb;
                background: #24282F;
                color: #fff;
            }
        }
        .submit{
            border: 1px solid #dbdbdb;
            background: #dbdbdb;
            color: #000000;
            transition: all 0.2s ease-out;
            &:hover{
                transition: all 0.2s ease-in;
                border: 1px solid #d2d2d2;
                background: #24282F;
                color: #d2d2d2;
            }
        }
    }
}

.disable-account-resend-container{
    width: fit-content;
    display: inline-block;
}
.delete-account-info-title{
    text-align: center;
    .delete-account-info-container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin-bottom: 15px;
        h2{
            margin: 0;
        }
    }
    .delete-account-info-icon-container{
        border: 1px solid #d2d2d2;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        // margin: 0 auto 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ff3f3f;
        background: radial-gradient(#eba7a7, #dcdcdc);  
    }
    p{
        margin: 5px 0;
        color: #fff;
        // max-width: 60%;
        text-align: center;
        line-height: 1.3;
        // font-weight: 600;
    }
}

.delete-account-error{
    font-size: 14px;
}

.myaccount {
    .delete-account-box{
        display: none;
    }
}

// Myaccount Component
.myaccount-head,
.customer-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    // margin-top: -20px;
    // margin-bottom: 20px;
}

.myaccount-head-btn {
    display: flex;
    justify-content: center;

    .button-loader-container{
        width: 100px;
        margin-bottom: 0;
        overflow: hidden;
        margin-right: 10px;
        .button-loader{
            height: 44px;
        }
    }

    svg{
        background: none !important;
        margin-right: 10px;
    }

    button {
        padding: 10px 20px;
        border-radius: 8px;
        border: none;
        background-color: #191c21;
        color: #fff;
        margin-right: 10px;
        width: 100px;
        &:last-child{
            margin-right: 0;
        }
        &.danger{
            background-color: #ff3f3f;
        }
        &.green{
            background-color: #5fc4b8;
        }
    }

    :nth-child(1) {
        // background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
        background-color: #5fc4b8;
    }
    :nth-child(2) {
        background: #000;
    }
}

.myaccount-info {
    background: #191c21;
    width: 60%;

    h3 {
        // padding: 30px;
        // padding-bottom: 10px;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0;
    }

    hr{
        margin: unset;
    }
}

.myaccount-info-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}

.myaccount-form {
    padding: 30px;
}

.form-inputs {
    display: flex;
    // flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 30px;
    justify-content: space-between;
}

.lastName {
    align-self: flex-end;
}

.input-info,.contact-input-info {
    width: 50%;
    position: relative;

    .span-tooltip{
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: #fff;
        color: #000 !important;
        font-size: 14px;
        width: 90%;
        margin: 0 auto;
        border: 1px solid #1a1a1a;
        border-radius: 4px;
        transition: 0.5s;
        display: flex;
        align-items: center;

        img{
            width: 25px;
            height: 25px;
            display: inline-block;
            margin-right: 5px;
        }
        &::after {
            content: " ";
            position: absolute;
            bottom: 100%;  /* At the top of the tooltip */
            left: 10%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent white transparent;
          }
    }

    label {
        display: block;
        font-size: 20px;
        margin-bottom: 20px;
    }

    input,
    select,
    textarea,
    .dropdown-field {
        padding: 10px 15px;
        border-radius: 8px;
        width: 100%;
        border: 1px solid #1a1c21;
        color: #fff;
        display: inline-block;
        background: #ffffff12;
    }

    .span-tooltip{
        // border: 1px solid #fff;
        width: 100%;
        display: inline-block;
        padding: 10px 15px;
        border-radius: 8px;
        // background: #ffffff12;
    }

    input:disabled {
        background-color: #c4c4c4;
    }

    &.long-input{
        width: 100%;
    }

    .void-input{
        padding: 10px 15px;
        border-radius: 8px;
        width: 100%;
        border: 1px solid #1a1c21;
        color: #fff;
        display: inline-block;
        border: 1px solid #ffffff12;
    }
}

.contact-input-info{
    width: 100%;
}

.location-input {
    width: 100%;
}

.address-input {
    display: block;
    margin-top: 20px;
}

.myaccount-infos {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    // flex-wrap: wrap;
}

.myaccount-info2 {
    width: 40%;
    background: #191c21;
    height: fit-content;
    position: relative;

    h3 {
        padding: 35px 30px;
        // padding-bottom: 10px;
        font-size: 22px;
        font-weight: 400;
    }

    hr{
        margin: unset;
    }

    small{
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
        color: red;
    }
}

.info2-your-photo {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    // margin-bottom: 30px;

    .default-image{
        // width: 100%;
        // height: 65px;
        // width: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        // padding: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        svg{
            margin-right: 0;
            height: 20px;
            width: 22px;
        }
    }

    .info2-image-container {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .info2-image-overlay{
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            background: #000000d6;
            text-align: center;
            font-size: 13px;
            height: 25px;
            display: none;

            input[type="file"]{
                position: absolute;
                width: 100%;
                z-index: 1;
            }

            span{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                cursor: pointer;
            }
        }
    }
}

.info2-your-photo-btn {
    :nth-child(2) {
        margin-left: 10px;
        color: #00cdac;
    }
}

.billing-info{
    .myaccount-info{
        width: 100%;
    }
}

.setting-attach {
    // width: 250px;
    margin: 0 20px 45px;
    text-align: center;
    padding: 25px 15px;
    color: #fff;
    font-weight: 400;
    border: 2px dashed #02aab0;
    position: relative;

    &:hover{
        border-style: solid;
    }

    svg {
        display: block;
        margin: 0 auto 10px;
        color: #02aab0;
        // margin-top: -30px;
        height: 25px;
        width: 35px;
    }

    span {
        color: #02aab0;
    }
}

// My progress component
.myprogress-card {
    position: relative;
    margin: 0 auto;
    border-radius: 10px;
    background: linear-gradient(to bottom, #191c21, rgba(25, 28, 33, 0));

    img {
        width: auto;
        height: 310px;
        opacity: 0.7;
    }
}

.myprogress-card-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.myprogress-title-info {
    max-width: 25ch;
    margin: 0 auto;

    h3,
    p {
        padding: 0px;
        margin: 0px;
        text-transform: uppercase;
    }
}

.myprogress-infos {
    background: #191c21;

    h2 {
        padding: 30px;
        padding-bottom: 0px;
    }
}

.myprogress-info {
    padding: 30px 30px 90px 30px;
}

.food-cards,
.fashion-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin: 20px 0px 40px 0px;
}

.myprogress-bar {
    padding: 0px 20px;
}

.myprogress-bar-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;

    :nth-child(2) {
        color: #00cdac;
    }
}

.progress {
    background-color: rgba(95, 196, 184, 0.3);
    // margin-top: 10px;
    height: 8px;
}

.progress-bar {
    background-color: #00cdac;
}

.card-icons {
    position: absolute;
    top: 10px;
    right: 20px;
}

// Device
.device-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.device-data {
    background: #191c21;
    width: auto;

    h2 {
        padding: 30px;
        padding-bottom: 0px;
        font-size: 22px;
        font-weight: 400;
    }
}

.looged-text {
    padding: 30px;
    font-size: 22px;
    font-weight: 400;
    padding-bottom: 10px;
}

.device-wrapper {
    padding: 30px;
    padding-top: 0px;
}

.loptop-wrapper {
    background-color: #000;
}

.device-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    width: 500px;
    max-width: 500px;

    span {
        padding: 30px;
        font-weight: 500;
        font-size: 20px;
    }
}

.with-border {
    border-top: 2px solid rgb(39, 39, 39);
    border-bottom: 2px solid rgb(39, 39, 39);
}

.device-name {
    display: inline;

    span {
        display: inline-block;
    }
}

.device-icon {
    background-color: #14171c;
    color: #a6a6a6;
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    gap: 40px;
    align-items: center;

    p {
        margin: 0px;

        svg {
            margin-right: 10px;
        }
    }
}

.device-notifications {
    position: absolute;
	top: 25px;
	right: 0;
    background-color: #37393d;
    width: 300px;
	border-radius: 10px;
    overflow: hidden;
	padding: 20px;


}

.device-notifications-title{
	display: flex;
	justify-content: space-between;
	h3 {
		font-size: 20px;
		font-weight: 600;
		color: #06b6af;
	}
}

.notifications-head {
    // padding: 30px 30px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
	color: #fff;
    // margin-left: auto;
}

.notifications-info {
    // padding: 20px;
	height: 500px;
    width: 100%;
    overflow: scroll;
}

.notification {
    padding: 15px 0px;
    border-bottom: 2px solid rgb(86, 86, 86);
	p, span{
		color: #fff;
	}

}

.notification-date {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
}

// Customer care component
.chatbox {
    background-color: #191c21;
    max-width: 100%;
    width: 100%;
}

.chatbox-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
}

.chatbox-head-info {
    display: flex;
    gap: 20px;
    align-items: center;

    h3 {
        font-size: 20px;
    }
}

.customer-head-btn {
    display: inline-flex;
    // gap: 30px;
    align-items: center;

    :nth-child(1) {
        color: #00cdac;
    }
}

.chatbox-head-img {
    position: relative;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    > div {
        position: absolute;
        right: 0;
        bottom: 5px;
        padding: 5px;
        border: 2px solid #fff;
        border-radius: 50%;
        background-color: #02aab0;
    }
}

.chatbox-head-icons {
    svg {
        color: #00cdac;
    }
}

.chat-area {
    width: 100%;
    background-color: #fff;
    height: 420px;
    position: relative;
}

.chat-area-day {
    padding-top: 100px;

    span {
        color: #000;
        background: #bdbdbd;
        padding: 10px;
        border-radius: 6px;
        display: block;
        width: 10ch;
        margin: 0 auto;
        margin-bottom: -20px;
        text-align: center;
    }

    div {
        width: 100%;
        border: 1px solid #bdbdbd;
    }
}

.chat-area-msg {
    position: absolute;
    bottom: 0;
    right: 30px;

    p {
        max-width: 50ch;
        padding: 20px;
        color: #000;
        background: linear-gradient(to right, #00cdac, #02aab0);
        border-radius: 14px;
        border-bottom-right-radius: 0px;
    }

    span {
        float: right;
        color: #000;
        padding-bottom: 10px;
    }
}

.chat-input {
    padding: 10px 20px;

    .input-group {
        background-color: #000;
        padding: 5px 0px;
        border-radius: 6px;

        input {
            background-color: #000;
            border: none;
        }

        button {
            background-color: #000;
            border: none;
            color: #00cdac;
            padding: 0px 20px;
        }

        :nth-child(4) {
            border-left: 1px solid #00cdac;
        }
    }
}

// Transaction component
.transactions {
    overflow-x: auto;
    .td-flex{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .active-dot-loader{
            margin: 0 0 0 10px;
            svg{
                margin: 0;
            }
        }
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

tr {
    border-bottom: 1px solid #787878;
    text-align: left;
    padding: 8px;

    th {
        background-color: #191c21;
        color: #a6a6a6;
        font-weight: 400;
    }

    td,
    th {
        padding: 20px 15px;
    }

    :last-child {
        // text-align: center;
        margin: 5px 0 0;
    }
}

.table-status {
    padding: 5px 10px;
    background-color: #191c21;
    border-radius: 6px;
    display: inline-block;
    margin-top: 15px;
    width: 100%;
    text-align: center;
}

.billing-info, .checkout-box{
    .dropdown-field{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .active{
        cursor: pointer;
    }
    .overlay{
        background-color: #191c21;
        border: 1px solid #2F2F2F;
        left: 0;
        top: 100px;
        z-index: 1;
        right: 0;
        width: 100%;
        padding: 0;
        .Search{
            border: 1px solid #323336;
            border-radius: 8px;
            background-color: #24282F;
            margin: 24px 16px;
            .formsearch{
                input{
                    border: none;

                }
            }
        }
        .modalcontainer{
            border-top: 1px solid #2F2F2F;
            margin-top: 0;
            padding-right: 15px;
            
            &.default{
                border-bottom: 1px solid #2F2F2F;
                // cursor: default;
            }
            span{
                background: none;
                padding: 16px;
                margin: 0;
                width: 90%;
            }
            .namebox{
                width: 100%;
                justify-content: space-between;
            }
            input[type='radio'] {
                // -webkit-appearance:none;
                cursor: pointer;
                width:20px;
                height:20px;
                min-width: 20px;
                border:3px solid #98A2B3;
                border-radius:50%;
                outline:none;
                background: transparent;
            }
            // input[type='radio']:hover {
                //     box-shadow:0 0 5px 0px red inset;
                // }
            input[type='radio']:before {
                background: #191c21;
                content:'';
                display:block;
                width:100%;
                height:100%;
                margin: auto;
                border: 2px solid #98A2B3;
                border-radius:50%;    
            }
            input[type='radio']:checked{
                background: yellow;
                outline: none;
            }
            input[type='radio']:checked:before {
                // border: 2px solid #98A2B3;
                background:#98A2B3;
                width: 12px;
                height: 12px;
                margin: 20% auto;
                outline: none;
            }
            
        }
        .no-search-data{
            margin: 20px 16px;
        }
    }
}

.transaction-container {
    background-color: #191c21;
    padding: 20px;
    border-radius: 8px;
	width: 100%;

    .transaction-history {
        .transactions {
            th {
                padding: 15px;
            }
            .action{
                position: relative;
                svg{
                    cursor: pointer;
                }

                .dropdown-center{
                    ul{
                        padding: 10px 0;
                        li{
                            p{
                                cursor: pointer;
                                color: #fff;
                                &.dropdown-item{
                                    &:hover{
                                        background-color: #373737
                                    }
                                }
                            }
                        }
                    }
                }
                .pdf-box-element{
                    // position: absolute;
                    // top: 50%;
                    // right: 100px;
                    // background: white;
                    // color: #000;
                    // z-index: 0;
                    // padding: 5px 10px;
                    // transform: translateY(-50%);
                    // border: 1px solid #000;
                    // border-radius: 5px;
                    // display: none;

                    p{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.transaction-history {
    background-color: #000;
    // margin: 20px;
    padding: 20px;
    border-radius: 8px;

    h3 {
        margin-bottom: 30px;
        // margin-left: 30px;
        font-weight: 500;
    }
}

.transaction-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 10px;
}

.pagination-ul {
    list-style: none;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding: 0px;

    li {
        background-color: #191c21;
        padding: 5px 10px;
        border-radius: 6px;
        cursor: pointer;

        a {
            color: #fff;
        }
    }
}

#invoice_front{
    padding: 50px;
    color: #8b8b8b;
}
#invoice_front p{
    font-size: 12px;
}
#invoice_front div.top{
    position: relative;
    margin: 20px 0 40px;
}
#invoice_front div.top div.logo-container{
    width: 100px;
    filter: invert(1);
    display: inline-block;
    margin: 30px;
}
#invoice_front div.top div.address-container{
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}
#invoice_front div.top div.address-container small{
    font-size: 14px;
    font-family: 'latoregular';
    text-transform: uppercase;
    margin-bottom: 10px;
    display: inline-block;
}
#invoice_front div.top div.address-container h3{
    font-size: 40px;
    font-family: 'latoregular';
    text-transform: uppercase;
    margin-bottom: 10px;
}
#invoice_front div.top div.address-container p{
    font-size: 14px;
    font-family: 'latoregular';
    text-transform: uppercase;
    margin-bottom: 10px;
}
#invoice_front div.top div.address-container p span{
    font-size: 14px;
    font-family: 'latolight';
    text-transform: uppercase;
}
#invoice_front hr{
    height: 1px;
    background: #969696;
}
#invoice_front div.bottom{
    margin: 30px 0 10px;
}
#invoice_front div.bottom div.detail-top{
    margin-bottom: 50px;
    position: relative;
}
#invoice_front div.bottom div.detail-top h3{
    font-size: 28px;
    font-family: 'latoregular';
    color: #272727;
    margin-bottom: 10px;
}
#invoice_front div.bottom div.detail-top p{
    font-size: 16px;
    line-height: 25px;
    display: block;
}
#invoice_front div.bottom div.detail-top div.detail-top-left{
    width: 40%;
}
#invoice_front div.bottom div.detail-top div.detail-top-left h3{

}
#invoice_front div.bottom div.detail-top div.detail-top-left p{}
#invoice_front div.bottom div.detail-top div.detail-top-left p{}
#invoice_front div.bottom div.detail-top div.detail-top-right{
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    width: 30%;

}
#invoice_front div.bottom div.detail-top div.detail-top-right h3{}
#invoice_front div.bottom div.detail-top div.detail-top-right p{
}
#invoice_front div.bottom div.detail-bottom{
    margin-bottom: 50px;
}
#invoice_front div.bottom div.detail-bottom table{
    width: 100%;
}
#invoice_front div.bottom div.detail-bottom table tr{
    display: block;
    width: 100%;
    padding: 10px 0;
}
#invoice_front div.bottom div.detail-bottom table tr.detail-bottom-head{
    background: #e0e0e0;
}
#invoice_front div.bottom div.detail-bottom table tr.detail-bottom-head td{
    color: #000000;
    font-family: 'latoregular';
}
#invoice_front div.bottom div.detail-bottom table tr.detail-bottom-total{
    background: #e0e0e0;
}
#invoice_front div.bottom div.detail-bottom table tr.detail-bottom-total td{
    color: #000000;
    font-family: 'latoregular';
}
#invoice_front div.bottom div.detail-bottom table tr th{
    width: 20%;
}
#invoice_front div.bottom div.detail-bottom table tr td{
    width: 20%;
    padding: 0 10px;
}