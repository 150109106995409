/* General styles */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

// Settings
@import url(./settings.scss);

@font-face {
    font-family: "latolight";
    src: url("../fonts/lato-light-webfont.woff2") format("woff2"),
        url("../fonts/lato-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "latoregular";
    src: url("../fonts/lato-regular-webfont.woff2") format("woff2"),
        url("../fonts/lato-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "helveticaregular";
    src: url("../fonts/helvetica-webfont.woff2") format("woff2"),
        url("../fonts/helvetica-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "helveticacompressed";
    src: url("../fonts/helvetica-compressed-5871d14b6903a-webfont.woff2") format("woff2"),
        url("../fonts/helvetica-compressed-5871d14b6903a-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "gorditaregular";
    src: url("../fonts/gordita_regular-webfont.woff2") format("woff2"),
        url("../fonts/gordita_regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato-Black";
    src: url("../fonts/Lato-Black.woff2") format("woff2"),
        url("../fonts/Lato-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Black-Italic";
    src: url("../fonts/Lato-BlackItalic.woff2") format("woff2"),
        url("../fonts/Lato-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Bold";
    src: url("../fonts/Lato-Bold.woff2") format("woff2"),
        url("../fonts/Lato-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Italic";
    src: url("../fonts/Lato-Italic.woff2") format("woff2"),
        url("../fonts/Lato-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Bold-Italic";
    src: url("../fonts/Lato-BoldItalic.woff2") format("woff2"),
        url("../fonts/Lato-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
    background-color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

body {
    background-color: #000;
    font-family: "latolight", "latoregular", "helveticaregular",
        "helveticacompressed", "gorditaregular", -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
}

img {
    display: block;
    width: 100%;
}

// Select tag style change
// select {
// 	-webkit-appearance: none;
//   -moz-appearance: none;
//   background: transparent;
//   background-image: url("https://cdn-icons-png.flaticon.com/512/60/60995.png");
//   background-repeat: no-repeat;
//   background-position-x: 100%;
//   background-position-y: 5px;
//   border: 1px solid #dfdfdf;
//   border-radius: 2px;
//   margin-right: 2rem;
//   padding: 1rem;
//   padding-right: 2rem;
// }

// .select {
// 	position: relative;
// 	display: block;
// 	width: 20em;
// 	height: 3em;
// 	line-height: 3;
// 	background: #2C3E50;
// 	overflow: hidden;
// 	border-radius: .25em;
// }

// select {
// 	width: 100%;
// 	height: 100%;
// 	margin: 0;
// 	padding: 0 0 0 .5em;
// 	color: #fff;
// 	cursor: pointer;
// }

// select::-ms-expand {
// 	display: none;
// }

// .select::after {
// 	content: '\25BC';
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	padding: 0 1em;
// 	background: #34495E;
// 	pointer-events: none;
// }

// .select:hover::after {
// 	color: #F39C12;
// }

// // <!-- For different browsers -->
// .select::after {
// 	-webkit-transition: .25s all ease;
// 	-o-transition: .25s all ease;
// 	transition: .25s all ease;
// }

::-webkit-scrollbar {
    -moz-appearance: none;
    display: none;
}

:global(.scrollable)::-webkit-scrollbar {
    display: block;
}

// .scrollable {
// 	overflow-y: scroll;
// 	// scrollbar-width: thin;
// 	// scrollbar-color: gray lightgray;
// }

// .scrollable::-webkit-scrollbar {
// 	display: block !important;
// }

// .scrollable::-webkit-scrollbar-track {
// 	// background-color: lightgray;
// }

// .scrollable::-webkit-scrollbar-thumb {
// 	background-color: rgb(170, 170, 170);
// }

// .sticky-top {
//     top: 4%;
// }
.form-control:focus {
    box-shadow: 0 0 0 0rem #02aab0;
}

.accordion-button:focus {
    box-shadow: none;
}

.btn {
    border-width: 0;
}

.button-effect-primary {
    background: #5fc4b8;
    transition: background-color 0.5s ease, color 0.5s ease,
        box-shadow 0.5s ease;
}

.button-effect-primary:hover,
.button-effect-primary:focus {
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%) !important;
    background: #2fbcab;
    box-shadow: rgb(84, 184, 141) 0px 2px 10px 0px;
}

.btn:focus {
    box-shadow: 0 0 0 0rem #02aab0;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #000;
    background-color: #02aab0;
    border-color: #02aab0;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem #02aab0;
}

p {
    font-size: 16px;
    line-height: 24px;
    font-family: "latolight";
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    color: #fff;
}

ul {
    list-style: none;

    li {
        font-size: 16px;
        line-height: 24px;
    }
}

.join-membership {
    display: flex;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: #02aab0;
}

:focus-visible {
    outline: none;
}

.overlay-box {
    background: #191c21;
    border-radius: 0;
    align-items: flex-start;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 22222222222;
    padding: 20px;

    width: 100%;
    position: fixed;
    top: 0;
    left: 0%;
    // height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;

    .category-list {
        max-height: 70vh;
        overflow: scroll;
    }

    .category-list li {
        margin-bottom: unset;
    }
}

.wise-craft-mobile{
    display: none;
}

.overlay-box .nav-item {
    margin-bottom: 20px;
    padding: 0 15px;
    cursor: pointer;

    :hover {
        color: greenyellow;
    }
}

.nav {
    align-items: center;

    .nav-home-button {
        margin-right: 15px;
        cursor: pointer;
    }
}

.overlay-header {
    position: absolute;
    top: 0;
    right: -21px;
    z-index: 1049;
    border-radius: 10px;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
    background: transparent;
    color: white;
    border: none;
    width: 225px;
    cursor: default;
    margin-top: 30px;
    padding-top: 20px;
}

.header-subscription {
    padding: 20px;
    border-bottom: 1px solid #4d5760;
    background-color: #1a1c21;
}

.profile-name{
    margin-bottom: 10px !important;
}

.header-profile-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 0.6px solid rgb(42, 42, 42);
    background: black;
    border-radius: 0 0 10px 10px;

    span {
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        transition: 0.5s;
        margin: 10px 0;
        cursor: pointer;

        &:hover {
            color: #02aab0;
        }
    }
}

.contains-overlay-header {
    width: 100%;
    // border: 1px solid #4d5760;
    border-radius: 8px;

    .header-subscription {
        padding: 0 0 20px;
        border-bottom: 1px solid #4d5760;
        background-color: unset;
    }

    .header-profile-content {
        padding: 20px 0 0;
        display: flex;
        flex-direction: column;
        border: none;
        background: black;
    }
}

.slide-down {
    transition: transform 0.2s ease-in-out;
    transform: translateY(0);
}

.slide-up {
    transform: translateY(100%);
}

//  .Nav .fullcontainer{
// 	display: flex;
// }

.header-subscription-state {
    color: #fff;
    display: flex;
    justify-content: space-between;

    .header-subscription-span-state {
        display: flex;
        align-items: center;

        svg {
            margin-right: 6px;
            height: 6px;
            width: 6px;
            color: #03c5ad;
        }
    }
}

.header-subscription-hr-line {
    width: 100%;
    height: 1px;
    margin: 10px auto;
    background: #ffffff;
    opacity: 0.7;
    text-align: center;
}

.header-subscription-content {
    color: #fff;

    h6 {
        text-transform: capitalize;

        &.expiry {
            margin-bottom: 0;
        }
    }

    h5 {
        display: block !important;
        font-size: 16px;

        small {
            font-family: "latoregular";
            font-size: 16px;
            color: #5fc4b8;
        }
    }

    .header-subscription-no-subscription {
        button {
            margin-top: 20px;
            width: 100%;
            display: block;
            color: #000;
            // background-color: #02aab0;
            transition: all 0.4s ease-in-out 0s;

            &:hover {
                transition: all 0.4s ease-in-out 0s;
                color: #fff;
            }
        }
    }
}

.menu-popup-links {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    a {
        text-decoration: none;
        color: #0000;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.235px;
        margin: 10px 0;
        // margin-bottom: 20px;
    }
}

.overlay-box .Burger {
    margin-left: 92%;
}

.overlay-box {
    a {
        color: #a3a4a6;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        transition: 0.5s;
        text-decoration: none;
        padding: 0 15px;
        margin-bottom: 10px;
    }

    .category-list {
        li {
            a {
                font-size: 24px;
                line-height: 32px;
                padding-left: 0;
            }
        }
    }
}

.overlay-box .nav-item:hover {
    color: #02aab0;
}

.overlay-box h3 {
    padding: 0 15px;
    font-size: 33px;
    margin-bottom: 20px;
}

.page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: 100vh;
     height: calc(100vh - 121px);
}

.full-page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 121px);
}

.popper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.button-loader,
.success-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
}

.success-loader {
    height: unset;
}

.section-loader {
    // background: #000;
    filter: grayscale(1);
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
}

.success-check {
    font-size: 60px;
    margin-bottom: 15px;
    color: #02aab0;
}

.placeholder-box {
    position: relative;

    .placeholder-inner-box {
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        align-items: center;
        justify-content: center;

        img {
            width: 34%;
        }
    }
}

/* Website Container */
.website-container {
    margin: -115px auto 0;

    &.top-spacing {
        margin-top: 118px;
    }
}

/* Admin Container */
.admin-container {
    padding-top: 118px;
    margin: 0 auto;
}

/* Header style */
.fixed {
    background-color: #000000b5 !important;
    // padding: 30px 70px !important;
    margin-top: 0 !important;
}

.sticky-fixed {
    height: fit-content;
    position: sticky;
    top: 128px;
    // left: 72px;
    // bottom: 434px;
}

.Header {
    // max-height: 40px;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    // margin: 36px auto -75px;
    // padding: 30px 70px;
    padding: 30px;
    // margin-top: 36px;
    z-index: 1049;
    // position: relative;
    backdrop-filter: blur(5px);
    

    .nav-item-box {
        .nav-item {
            margin-right: 0;
        }
    }

    .nav-item {
        // margin-right: 16px;

        &.icon {
            color: #717579;
            padding: 0 15px;

            h6 {
                margin-bottom: 0;
                transition: 0.5s;

                // width: 110px;
                &:hover {
                    color: #02aab0;
                    transition: 0.5s;
                }
            }

            &:hover {
                color: inherit;
            }
        }
    }

    .nav-item.has-badge {
        position: relative;
        padding: 0 15px;
        cursor: pointer;
        // margin-right: 15px;

        .badge {
            position: absolute;
            // bottom: 16px;
            // left: 22px;
            top: -10px;
            right: 0;
            font-size: 12px;
        }
    }

    .navdrop1 {
        .dropdown-menu a {
            font-size: 16px;
        }
    }

    .category-container {
        display: flex;
        align-items: center;
    }


}
.header-right-container {
    display: flex;
    align-items: center;
    width: 40%;
    .header-logo-container {
        padding-left: 15px;
        padding-right: 30px;
    }
    .flag-container{
        display: flex;
        align-items: center;
        .border-line{
            border: 1px solid #fff;
            height: 24px;
            margin-right: 20px;
        }
        span{
            margin-right: 20px;
        }
        .flag-logo{

        }
    }
}

.fullcontainer{
    display: flex;
    align-items: center;
}

.slick-track {
    margin-left: unset !important;
    margin-right: unset !important;
}

.Logo {
    height: 40px;
    width: 60px;
}

.Nav {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
}

.Nav a {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    transition: 0.5s;
    text-decoration: none;
    // padding: 0 15px;
}

.dropdown-menu {
    background: #000;
    padding: 30px;
    border: 0.6px solid rgb(42, 42, 42);
    min-width: 7em !important;

    :last-child {
        margin-bottom: 0;
    }
}

.dropdown-menu.show {
    transform: translate3d(0, 45px, 0) !important;
    overflow: scroll;
    max-height: 90vh;
}

.dropdown-menu a {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 25px;
    padding: 0;
}

.dropdown-menu a:hover {
    color: #02aab0;
}

.dropdown-menu .last-item {
    margin-bottom: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
}

.dropdown-item .text-center {
    text-align: center;
}

.Nav a:hover,
.Nav a:focus {
    color: #02aab0;
}

.dropdown-btn {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 36px !important;
    color: #000000 !important;
    padding: 6px 70px !important;
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background: #5fc4b8;
    border-color: #02aab0;
    border-radius: 8px;
    margin-bottom: 0 !important;

    &:hover {
        background: #2fbcab;
        box-shadow: rgb(84, 184, 141) 0px 2px 10px 0px;
        color: #fff !important;
    }
}

.search-icon{
    cursor: pointer;
}

.search-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    border-color: rgba(255, 255, 255, 0.1);
    padding: 10px 25px;
    // width: 70%;
    flex: 1;
    margin: 0 auto;
    position: relative;

    .close-icon{
        cursor: pointer;
    }
    .search-form-icon {
        align-items: center;
        
    }

    .drop-height {
        height: 0;
        overflow: hidden;
        width: 100%;
    }

    .load-more {
        margin: auto;
    }

    .search-data {
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        border-radius: 8px;
        padding: 16px;
        background-color: #1a1a1a;
        transition: 0.4s height ease-in;
        // height: 416px;
        overflow-y: scroll;
        flex-direction: column;

        .search-container-scroll-box {
            overflow-y: scroll;
            max-height: 378px;
            flex-direction: column;
            width: 100%;

            .search-data-main-container {
                align-items: center;
                padding: 16px;
                cursor: pointer;
                width: 100%;
                border-radius: 4px;

                &:hover {
                    background-color: #2f2f2f;
                }

                .search-box-image-container {
                    width: 60px;
                    height: 60px;
                    border-radius: 4px;
                    overflow: hidden;
                    margin-right: 10px;
                }

                .search-box-content {
                    flex-direction: column;

                    h3 {
                        font-size: 16px;
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.search-form div {
    display: flex;
    align-items: flex-start;
}

.search-form input[type="text"] {
    background: transparent;
    color: #ffffff;
    border-color: transparent;
    padding: 0;
    margin-left: 11px;
}

.search-form input[type="text"]:focus {
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}

.search-form input[type="text"]::placeholder {
    color: #ffffff;
}

.mobile-serach {
    display: none;
}

.mobile-box {
    display: none;
}

.Burger {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
    color: #fff;
}

.Burger:active {
    transform: scale(1.2);
}

.Burger-close {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
    color: #fff;
}

.Burger-close:active {
    transform: scale(1.2);
}

.NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
}

.NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
}

.NavAnimation-exit {
    opacity: 1;
}

.NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
}

.pop-up-box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: #000000;
    z-index: 1050;
    color: #fff;
    text-align: center;

    .pop-up-box-child {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        background-color: #16181f;
        padding: 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        .pop-up-box-close {
            margin-left: auto;
            height: 30px;
        }

        .pop-up-box-icon {
            border: 4px solid #ffffff63;
            border-radius: 50%;
            padding: 15px;
            height: 3em;
            margin-bottom: 10px;
        }

        .pop-up-box-button {
            width: fit-content;
            margin: 0 auto;
            background: #5fc4b8;
        }
    }
}

/* Hero section style */
.hero-box {
    padding-top: 118px;
    padding-bottom: 120px;
    background-image: url("../images/pattern.png");
    background-repeat: no-repeat;
    background-position: top left;

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-50%);
        }
    }

    .slider {
        display: flex;
        animation: scroll 10s linear infinite;
    }

    .slider-item {
        flex: 0 0 auto;
        width: 20%;
        /* Adjust based on the number of visible slides */
    }

    .marginTop {
        margin-top: 80px;
    }

    // .marginTop:nth-child(even) {
    // 	margin-top: 40px;
    //   }

    //   .marginTop:first-child:last-child {
    // 	margin-top: 0;
    //   }

    .slick-slide {
        transform: scale(0.97);
        // transition: all 0.4s ease-in-out 0s;
    }

    .slick-slide.slick-center {
        transform: scale(1);
        transition: all 0.4s ease-in-out 0s;
    }
}

.main-hero-box-container {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 800px;

    .hero-box-content-container {
        position: absolute;
        left: 90px;
    }

    .hero-box-content-image-mask-container {
        // width: 70%;
        -webkit-mask-image: linear-gradient(90deg, transparent, #000 42.3rem);
    }

    .hero-box-content-image-mask-container-less-data {
        -webkit-mask-image: linear-gradient(90deg, transparent, #000 42.3rem);

        .hero-image-container {
            width: unset !important;
        }

        .slick-track {
            margin-left: auto !important;
        }
    }

    .hero-image-container {
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
            0 4px 6px -2px rgba(255, 255, 255, 0.05);
        // height: 540px;
        width: 100% !important;
        // width: 50%;
        // margin: 0 10px;
    }

    .hero-box-content-image-not-multiple {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
    }
}

.hero-box .container-fluid {
    padding-left: 90px;
    padding-right: 0;
    overflow: hidden;

    @media all and (max-width: 980px) {
        padding-left: 50px;
    }
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.hero-box .row {
    align-items: center;
    justify-content: flex-end;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.overlay-hero {
    background: rgba(38, 168, 153, 0.4);
    filter: blur(460px);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.hero-box h3 {
    font-weight: 300;
    font-size: 50px;
    line-height: 65px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
    // text-transform: uppercase;

    @media all and (max-width: 1280px) {
        font-size: 38px;
    }

    @media all and (max-width: 1080px) {
        font-size: 34px;
        line-height: 45px;
    }

    @media all and (max-width: 980px) {
        font-size: 28px;
    }
}

.hero-box p {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.4;
    // letter-spacing: 0.6px;
    word-spacing: 1.6px;
    margin-bottom: 15px;
    font-family: "latolight";

    @media all and (max-width: 1080px) {
        font-size: 17px;
    }

    @media all and (max-width: 980px) {
        font-size: 16px;
    }
}

.hero-btn {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 2px;
    // text-transform: uppercase;
    color: #000000;
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    // background-color: #5FC4B8;
    border-radius: 6px;
    padding: 10px 40px;
    border-color: #5fc4b8;
    letter-spacing: 1px;

    @media all and (max-width: 980px) {
        line-height: 26px;
    }

    @media all and (max-width: 768px) {
        font-size: 20px;
    }

    @media all and (max-width: 480px) {
        line-height: 22px;
    }
}

.hero-box .slick-slide {
    // padding: 0 10px;
}

.hero-box .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // cursor: pointer;
}

/* Discover Life style */
.discover-box {
    overflow-x: hidden;
    padding-bottom: 120px;

    .discover-box-content-head {
        p {
            font-size: 18px;
            opacity: 0.8;
            text-align: center;
            // margin-bottom: 36px;
        }
    }

    .discover-tabs {
        margin-bottom: 40px;
    }

    // .slick-slider .slick-track{
    // 	transform: translate3d(0, 0, 0) !important;
    // }

    .blue-btn {
        width: fit-content;
        margin: 60px auto 20px;
        font-size: 16px;
        padding: 10px 40px;
    }
}

.discover-title {
    font-weight: 300;
    font-size: 52px;
    line-height: 77px;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
}

.discover-box {
    .discover-box-head {
        margin-bottom: 36px;
    }

    .slick-slide {
        padding: 0 15px;
        text-align: center;
    }

    .slick-track {
        // width: unset !important;
        justify-content: center;
    }

    .centerize {
        .slick-track {
            transform: translateX(-50%) !important;
            left: 50%;
        }
    }
}

/* === Hover Effect Start === */
.discover-box .content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #000;
    border-radius: 10px;
    transition: border-color 0.5s ease;

    &:hover {
        border: 2px solid #5fc4b8;
        transition: border-color 0.5s ease;

        .discover-hr {
            background: #5fc4b8;
            transition: all 0.5s ease;
        }
    }
}

.discover-box .content .content-overlay {
    background: linear-gradient(180deg,
            rgba(25, 28, 33, 0.02) 45%,
            #191c21 96.32%);
    position: absolute;
    // height: 100%;
    // width: 295px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    // opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.discover-box .content:hover .content-overlay {
    // opacity: 1;
}

.discover-box .content-details {
    position: absolute;
    text-align: center;
    // width: 295px;
    // top: 50%;
    // left: 45.5%;
    // opacity: 0;
    padding: 30px 32px;
    // -webkit-transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.discover-box .content:hover .content-details {
    // left: 45.5%;
    opacity: 1;
}

.discover-box .fadeIn-bottom {
    // top: 82.5%;
    bottom: 0;
    width: 100%;
}

/* === Hover Effect end=== */
.discover-box .slick-prev:focus,
.discover-box .slick-next:focus,
.discover-box .slick-prev,
.discover-box .slick-next {
    width: 50px;
    height: 50px;
    background: #191c21;
    z-index: 9;
    border-radius: 50%;
}

.discover-box .slick-prev:hover,
.discover-box .slick-next:hover {
    background: #02aab0;
}

.discover-box .slick-prev {
    left: 50px;
    transition: all 0.2s ease;
}

.discover-box .slick-next {
    right: 50px;
    transition: all 0.2s ease;
}

.discover-box .slick-prev:before {
    background-image: url("../images/prev-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.discover-box .slick-next:before {
    background-image: url("../images/nxt-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.landing-instructor-box .slick-disabled,
.discover-box .slick-disabled {
    opacity: 0;
    transition: all 0.2s ease;
}

.discover-subtitle {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lato-Bold";
}

.discover-txt {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 0;
}

.discover-hr {
    width: 17px;
    height: 3px;
    margin: 14px auto 16px;
    background: #ffffff;
    opacity: 0.7;
    text-align: center;
    transition: all 0.5s ease;
}

.discover-tabs {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: center;
    // margin-top: 50px;
    // margin-bottom: 88px;
    flex-wrap: wrap;
}

.discover-tabs li {
    margin: 0 25px;
    font-weight: 700;
    font-size: 17px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    height: 34px;
}

.discover-tabs .arrow-bottom-border {
    border-bottom: 1px solid #ffffff;
}

.discover-tabs .arrow-up {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    width: 0;
    margin: 0 auto;
}

/* Testimonials style */
.testimonials-box {
    padding-top: 120px;
    padding-bottom: 126px;
}

.testimonials-box h3 {
    text-align: center;
    font-weight: 400;
    font-size: 46px;
    line-height: 46px;
    margin-bottom: 50px;
}

.testimonials-box .slick-initialized .slick-slide {
    padding: 0 5px;
    display: block;
}

.testimonial-one {
    position: relative;
}

.testimonial-one img {
    float: right;
}

.testimonial-txt {
    padding: 68px 59px;
    background: #272c33;
    border-radius: 8px;
    width: 612px;
    position: absolute;
    right: 23%;
    top: 20%;
}

.testimonial-txt blockquote {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.testimonial-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
}

.testimonials-box .slick-prev,
.testimonials-box .slick-next {
    width: 62px;
    height: 62px;
    background: #191c21;
    z-index: 9;
    border-radius: 50%;
}

.testimonials-box .slick-prev:hover,
.testimonials-box .slick-prev:focus,
.testimonials-box .slick-next:hover,
.testimonials-box .slick-next:focus {
    background: #02aab0;
}

.testimonials-box .slick-prev {
    left: -170px;
}

.testimonials-box .slick-next {
    right: -170px;
}

.testimonials-box .slick-prev:before {
    background-image: url("../images/prev-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.testimonials-box .slick-next:before {
    background-image: url("../images/nxt-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.testimonials-box .slick-dots li {
    width: auto;
}

.testimonials-box .slick-dots li.slick-active button {
    background: #fff !important;
    width: 20px !important;
    height: 3px;
    border-radius: 8px;
}

.testimonials-box .slick-dots li button {
    background: rgba(255, 255, 255, 0.6) !important;
    border-radius: 50%;
    width: 6px !important;
    height: 6px !important;
    padding: 0 !important;
}

/* FAQ style */
.faq-box {
    padding-top: 63px;
    padding-bottom: 120px;
    max-width: 70%;
    margin: 0 auto;
}

// .faq-box h3 {
// 	font-weight: 400;
// 	font-size: 38px;
// 	line-height: 52px;
// 	margin-bottom: 50px;
// }

.faq-blue-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    // text-transform: uppercase;
    color: #000000;
    padding: 14px 57px;
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    border-radius: 8px;
    border-color: #5fc4b8;
    display: block;
    // width: 200px;
    margin: 0 auto;
    margin-top: 50px;
}

.question {
    padding: 18px;
    margin-bottom: 30px;
    background: #191c21;
    box-shadow: 0px 28.6524px 63.0354px rgba(126, 126, 126, 0.15);
    border-radius: 12px;
    cursor: pointer;
}

.question h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.question p {
    margin-top: 27px;
    color: #e0e0e0;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    font-family: "latolight";
}

.question div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question div h4 {
    margin-bottom: 0;
}

.faq-btn {
    background: transparent;
    border: 0;
}

.faq-btn svg {
    color: #02aab0;
}

.question-title {
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 15px;
    font-size: 20px;
}

.complete-borders-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin: 20px 0px;

    div {
        border: 1px solid #02aab04f;
        width: 15%;
    }
}

.complete-border {
    border-color: #028670 !important;
}

/* Cross Box Style */
.cross-section .cross-padding {
    padding: 0;
}

.cross-title {
    font-weight: 300;
    font-size: 52px;
    line-height: 77px;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
}

.cross-sub-title {
    text-align: center;
    font-size: 18px;
}

.col-four-box {
    margin-left: -190px;
    // margin-top: 87px;
}

.cross-tab {
    width: 100%;
}

.cross-space-bottom {
    margin-bottom: 120px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    align-items: center;
}

.col-four-box-left {
    // margin-top: 87px;
    transform: translateX(200px);
    z-index: 9;
}

.cross-right-box {
    background-color: #191c21;
    padding: 81px 68px 30px 30px;
}

.cross-right-box h3 {
    font-weight: 400;
    font-size: 36px;
    line-height: 53px;
    margin-bottom: 12px;
}

.cross-right-box p {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #ffffff;
    opacity: 0.7;
    margin-bottom: 0;
}

.cross-right-box a {
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #5fc4b8;
}

/* Instructor slider in landing page */
.instructor-title {
    margin-bottom: 36px;
    justify-content: center;

    .instructor-title-content {
        text-align: center;

        // h3{
        // 	font-size: 30px;
        // 	margin-bottom: 0;
        // }
        p,
        span {
            font-size: 18px;
            opacity: 0.8;
        }
    }
}

.landing-instructor-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #1a1c21;
    padding: 0 10px;
    min-height: 126px;
}

.landing-instructor-box {
    padding-bottom: 120px;
    overflow-x: hidden;

    .centerize {
        .slick-track {
            transform: translateX(-50%) !important;
            left: 50%;
        }
    }

    .slick-track {
        align-items: flex-start;
        justify-content: center;
        // width: unset !important;
    }

    .slick-dots {
        bottom: -35px;
    }

    .slick-slider .slick-next {
        right: 50px;
        transition: all 0.2s ease;
    }

    .slick-slider .slick-prev {
        left: 50px;
        transition: all 0.2s ease;
    }

    .slick-dots li.slick-active button:before,
    .slick-dots li button:before {
        color: #5ec4b8;
        // color: #fff;
        font-size: 10px;
    }

    .landing-instructor-sub {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .landing-instructor-txt {
        font-weight: 800;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        // text-transform: uppercase;
        color: #fff;
        opacity: 0.7;
    }
}

.landing-instructor-modal {
    &.modal-dialog {
        width: 550px;
        margin: 0 auto;
        position: fixed;
        top: 10px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 111111;
        overflow: hidden;
        height: 98vh !important;
        align-items: unset !important;
    }

    .modal-content {
        border: 1px solid rgba(255, 255, 255, 0.5);

        .modal-body {
            height: 100%;
        }
    }

    .modal-box-container {
        padding: 0 30px;
        margin: 10px 40px 58px;
        height: 100%;
        // background: #191C21;
        // max-height: 87vh;

        .modal-box {
            max-height: 88vh;
            overflow-x: hidden;

            h2 {
                font-size: 32px;
                margin-bottom: 30px;
                position: relative;
                transition: all 0.2s ease;

                &:hover {
                    transform: scale(1.05);
                    transition: all 0.2s ease;
                }

                &::after {
                    content: "";
                    width: 100%;
                    position: absolute;
                    height: 10px;
                    left: 0px;
                    bottom: -5px;
                    // z-index: -1;
                    // filter: invert(1);
                    background: url("../images/curved-line.png") 50% 0% / contain no-repeat;
                }
            }

            h3,
            p {
                font-size: 18px;
                font-family: "Lato-Bold";
                opacity: 1;
                // text-align: justify;
            }

            .content-head-box {
                p {
                    margin: 15px 0;
                    text-align: center;
                }
            }

            .content-details-box {
                p {
                    font-size: 14px;
                    color: #aaaaaa;
                }

                .content-feature-box {
                    display: flex;
                    justify-content: flex-start;
                    align-items: first baseline;

                    p {
                        width: 88%;
                    }

                    small {
                        width: 4%;
                        text-align: center;
                    }

                    span {
                        // width: 8%;
                        float: none;
                    }
                }

                .content-feature-box-work {
                    display: flex;
                    justify-content: flex-start;
                    align-items: first baseline;

                    p {
                        // width: 88%;
                        font-size: 16px;
                    }

                    small {
                        width: 4%;
                        text-align: center;
                    }

                    span {
                        // width: 8%;
                        float: none;
                        // font-size: 14px;
                    }
                }
            }

            span {
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
    }
}

.modal-box {
    max-height: 80vh;
    overflow: scroll;

    .title-line {
        align-items: center;
        text-align: center;

        h2 {
            cursor: pointer;
        }
    }

    .content-head-box {
        .content-image-box {
            max-height: 250px;
            // max-width: 300px;
            margin: 0 auto;
            overflow: hidden;
            border-radius: 7px;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .hr-line {
            background: #c4c4c4;
            height: 2px;
            width: 95%;
            margin: 0 auto;
        }
    }

    h2 {
        font-family: "Lato-Bold";
    }

    h3 {
        font-family: "Lato-Bold";
        font-size: 22px;
        margin: 15px 0;
    }

    span {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #f2f2f2;
        float: right;
    }

    p {
        font-weight: 400;
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 0.1px;
        color: #f2f2f2;
        opacity: 0.7;
        margin-bottom: 25px;
        max-height: 60vh;
        overflow: scroll;
        margin-bottom: 0;
    }

    .single-notes-navigation {
        cursor: pointer;
        color: #00cdac;
    }

    .modal-box-margin {
        margin-top: 10px;
    }

    span svg {
        font-size: 15px;
        margin-left: 3px;
        vertical-align: middle;
    }
}

.landing-instructor-box .content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
    transition: border-color 0.5s ease;
    border: 2px solid #000;
    max-height: 435px;

    &:hover {
        border: 2px solid #5fc4b8;
        transition: border-color 0.5s ease;
    }

    .content-image-box {
        // height: 400px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

/* Trending box style */
/* === Hover Effect Start === */
.trending-box .content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
    border-radius: 5px;
    transition: border-color 0.5s ease;
    border: 2px solid #000;

    &:hover {
        border: 2px solid #fff;
        transition: border-color 0.5s ease;
    }
}

.landing-instructor-box .content .content-overlay,
.trending-box .content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.landing-instructor-box .content:hover .content-overlay,
.trending-box .content:hover .content-overlay {
    opacity: 1;
}

.landing-instructor-box .content-details {}

.trending-box .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    padding-top: 20px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.landing-instructor-box .content:hover .content-details,
.trending-box .content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.landing-instructor-box .fadeIn-bottom,
.trending-box .fadeIn-bottom {
    top: 80%;
}

/* === Hover Effect end=== */
.landing-instructor-box .slick-slide,
.trending-box .slick-slide {
    padding: 0 10px;
}

.landing-instructor-box .slick-slide {
    text-align: center;
}

.trending-box .slick-slide img {
    width: 100%;
}

.landing-instructor-box .slick-prev,
.landing-instructor-box .slick-next,
.trending-box .slick-prev,
.trending-box .slick-next {
    width: 50px;
    height: 50px;
    background: #191c21;
    z-index: 9;
    border-radius: 50%;
}

.landing-instructor-box .slick-prev:hover,
.landing-instructor-box .slick-prev:focus,
.landing-instructor-box .slick-next:hover,
.landing-instructor-box .slick-next:focus,
.trending-box .slick-prev:hover,
.trending-box .slick-prev:focus,
.trending-box .slick-next:hover,
.trending-box .slick-next:focus {
    background: #02aab0;
}

.landing-instructor-box .slick-prev,
.trending-box .slick-prev {
    left: -15px;
}

.landing-instructor-box .slick-next,
.trending-box .slick-next {
    right: -15px;
}

.landing-instructor-box .slick-prev:before,
.trending-box .slick-prev:before {
    background-image: url("../images/prev-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.landing-instructor-box .slick-next:before,
.trending-box .slick-next:before {
    background-image: url("../images/nxt-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

// .landing-instructor-box h3,
// .trending-box h3 {
// 	font-weight: 400;
// 	font-size: 38px;
// 	line-height: 46px;
// }

.trending-cat {
    background: #ffffff;
    border-radius: 6px;
    padding: 6px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    width: fit-content;
}

.trending-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.trending-sub {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.trending-title,
.landing-instructor-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0 0;
    font-family: "Lato-Black";
    width: 100%;
}

.trending-txt {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    // text-transform: uppercase;
    color: #fff;
    opacity: 0.7;
}

.trending-hr {
    width: 17px;
    height: 3px;
    margin: 14px auto 16px;
    background: #ffffff;
    opacity: 0.7;
    text-align: center;
}

.trending-link {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    opacity: 0.7;
    float: right;
    margin-bottom: 44px;
}

.trend-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    // text-transform: uppercase;
    color: #000000;
    padding: 14px 57px;
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    border-radius: 8px;
    border-color: #5fc4b8;
    display: block;
    width: 255px;
    margin: 0 auto;
    margin-top: 50px;
}

/* Training Includes */
.training-includes {

    // padding: 30px 0 60px;
    .training-title {
        text-align: center;
        margin: 20px 0 40px;
        // h3 {
        // 	font-size: 30px;
        // 	margin-bottom: 15px;
        // 	font-weight: 400;
        // 	line-height: 46px;
        // }
    }

    .hr-line {
        width: 30%;
        height: 1px;
        margin: 20px auto 30px;
        background: #ffffff;
        opacity: 0.7;
        text-align: center;
    }

    .training-content-box {
        text-align: center;
        border: 1px solid #fff;
        transition: all 0.5s ease;
        border-radius: 8px;
        padding: 45px 20px;
        height: 100%;

        &:hover {
            border: 1px solid #5fc4b8;
            transition: all 0.5s ease;
        }

        .training-content-image-box {
            width: 120px;
            height: 120px;
            margin: 0 auto;
            // svg {
            // 	height: 100px;
            // }
        }

        h3 {
            font-size: 20px;
            margin: 20px 0 30px;
            font-family: "Lato-Bold";
        }
    }
}

.download-app {
    .app-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin-bottom: 40px;

        h3 {
            font-size: 40px;
            line-height: 50px;

            span {
                color: #5fc4b8;
            }
        }

        .apps-container {
            display: flex;
            gap: 10px;
        }

        .app-image-container {
            width: 35%;
        }
    }
}

.landing-signup-box {
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
            /* Initial state of the animation */
        }

        100% {
            transform: rotate(360deg);
            /* Final state of the animation */
        }
    }

    .grey-box {
        margin-top: 0;
        border-radius: 10px;
        padding: 50px 100px;

        .row {
            align-items: flex-end;

            .right-title {
                font-family: "Lato-Bold";
                text-transform: uppercase;
            }

            .right-list {
                li {
                    text-transform: capitalize;
                    line-height: 25px;
                    align-items: center;
                }

                svg {
                    width: 18px;
                    animation: rotate 4s linear infinite;
                }
            }

            @keyframes jerk {
                0% {
                    transform: rotate(0);
                    /* Initial and final positions for jerk */
                }

                3% {
                    transform: rotate(5deg);
                    /* Move up */
                }

                6% {
                    transform: rotate(-5deg);
                    /* Move up */
                }

                9% {
                    transform: rotate(0);
                    /* Move up */
                }

                100% {
                    transform: rotate(0);
                    /* Move up */
                }
            }

            @keyframes tilt {

                0%,
                100% {
                    transform: rotate(0);
                    /* Initial and final positions for tilt */
                }

                50% {
                    transform: rotate(-5deg);
                    /* Tilt left */
                }
            }

            .btn-wisetalkies {
                color: #000000;
                font-size: 18px;
                line-height: 1.5;
                text-transform: uppercase;
                border-radius: 10px;
                background-color: #5fc4b8;
                background-position: center center;
                border-color: transparent;
                border-style: solid;
                transition: background-color 0.2s ease-in-out,
                    color 0.2s ease-in-out, border-color 0.2s ease-in-out;
                // padding: 15px 100px;
                height: 65px;
                width: 230px;
                margin-left: auto;
                display: block;
                animation: jerk 2s ease-in-out infinite alternate;
                /* Apply jerk and tilt animations */
            }
        }
    }
}

/* Coming soon style */
.coming-soon {
    // padding-top: 134px;
    padding-bottom: 120px;

    .coming-post {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        max-height: 300px;

        img {
            transition: all 0.2s ease-out;
        }

        &:hover {
            img {
                transform: scale(1.1);
                transition: all 0.2s ease-in;
            }
        }
    }
}

.coming-soon h3 {
    font-weight: 400;
    // font-size: 38px;
    line-height: 46px;
    margin-bottom: 50px;
}

.coming-soon .slick-slide {
    padding: 0 10px;
    position: relative;
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.29, 1.185, 1, 1);
    animation: 24s linear 0s infinite normal none running marquee;
}

.coming-soon .slick-slide :hover {
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.29, 1.185, 1, 1);

    &--zoom {
        transform: scale(1);
        transition: transform 0.3s cubic-bezier(0.29, 1.185, 1, 1);
    }
}

.coming-soon .slick-slide img {
    width: 100%;
}

.coming-soon .slick-prev,
.coming-soon .slick-next {
    width: 62px;
    height: 62px;
    background: #191c21;
    z-index: 9;
    border-radius: 50%;
}

.coming-soon .slick-prev:hover,
.coming-soon .slick-prev:focus,
.coming-soon .slick-next:hover,
.coming-soon .slick-next:focus {
    background: #02aab0;
}

.coming-soon .slick-prev {
    left: -28px;
}

.coming-soon .slick-next {
    right: -28px;
}

.coming-soon .slick-prev:before {
    background-image: url("../images/prev-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.coming-soon .slick-next:before {
    background-image: url("../images/nxt-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.coming-soon .slick-slide .snip1584 {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 22px;
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.snip1584 .coming-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.snip1584 .coming-cat {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: fit-content;
}

.snip1584 .coming-sub {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.snip1584 .coming-cat {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 7px 20px;
    background: #ffffff;
    border-radius: 6px;
}

.snip1584 .coming-title {
    // font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase;
    font-family: "lato-Bold";
    margin-bottom: 5px;
}

.snip1584 .coming-txt {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    // font-family: 'lato-Bold';
}

.coming-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.coming-post:hover .coming-overlay {
    opacity: 1;
}

.play-button {
    background: #5fc4b8;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s ease;
}

.play-button:hover {
    background: #5fc4b8;
}


.video-placeholder {
    width: 100%; 
    height: 68vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: none;
    border: none;
}

.video-placeholder p {
    color: #fff;
    font-size: 16px;
    text-align: center;
}

.snip1584 img {
    vertical-align: top;
    max-width: 100%;
    backface-visibility: hidden;
    margin: 0 auto;
}

/* Right Image Style */
.right-one-box .container-fluid {
    padding: 0 62px;
}

.grey-box {
    background-color: #191c21;
    padding: 51px 63px;
    // margin-top: 60px;
    // margin-bottom: 60px;
}

.right-tab {
    width: 100%;
    margin-top: -104px;
}

.right-title {
    font-size: 36px;
    line-height: 52px;
    font-weight: 400;
    margin-bottom: 16px;
}

.right-list {
    color: #fff;
    list-style: none;
    padding-left: 0;
    text-transform: uppercase;
    margin-bottom: 0;
}

.right-list svg {
    margin-right: 16px;
}

.right-list li {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 14px;

    &:last-child {
        margin-bottom: 0;
    }
}

.rupee-txt {
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    margin-bottom: 0;
}

.rupee-bottom {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    // text-transform: uppercase;
    text-decoration: line-through;
}

.blue-btn {
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    border-radius: 8px;
    border-color: #5fc4b8;
    color: #000;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    // text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 33px;
    transition: background-color 0.5s ease-in-out, color 0.5s ease;
}

.blue-btn:hover {
    transition: background-color 0.5s ease-in-out, color 0.5s ease;
    border-color: #2fbcab;
    background: #2fbcab;
}

.grey-btn {
    transition: background-color 0.5s ease-in-out, color 0.5s ease;
    background: #43454c;
    border-color: #43454c;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    // text-transform: uppercase;
    color: #ffffff;
}

.grey-btn:hover {
    transition: background-color 0.5s ease-in-out, color 0.5s ease;
    border-color: #43454c;
    background: #3c3c3c;
}

/* Footer style */
footer {
    background-color: #191c21;
}

.footer {
    // padding: 71px 140px 0 140px;
    padding: 71px 30px 0;

    .footer-logo-box {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .footer-logo {
            display: inline-block;
            width: 100px;
            // margin-left: -25px;
            margin-right: 20px;
        }

        p {
            font-size: 12px;
        }
        .flag-container{
            display: flex;
            align-items: center;
            
            .border-line{
                border: 1px solid #fff;
                height: 24px;
                margin-right: 20px;

            }
            span{
                margin-right: 20px;

            }
            .flag-logo{
                width: 60px;
            }
        }
    }
}

.footer .footer-logo img {
    // margin-bottom: 14px;
}

.footer-contents {
    display: flex;
    justify-content: space-between;
}

.footer-social-icons {
    margin-left: 15px;
}

.footer p {
    color: #fff;
    font-weight: 500;
    // margin-left: 20px;
    font-size: 16px;
    line-height: 1.5em;
}

.footer h6 {
    margin-bottom: 20px;

    a {
        font-size: 16px;
        line-height: 48px;
        margin-bottom: 12px;
        color: #ffffff;
        font-weight: 700;
    }
}

.footer .footer-link-about {
    list-style: none;
    padding-left: 0;
    width: 220px;

    li {
        margin-bottom: 20px;
    }
}

.footer .footer-link-explore {
    list-style: none;
    padding-left: 0;

    li {
        margin-bottom: 20px;
    }
}

.footer .footer-link li a {
    color: #fff;
    font-weight: 400;
    // line-height: 48px;
    margin-bottom: 12px;
    transition: 0.5s;
}

.footer .footer-link li a:hover {
    transition: 0.5s;
    color: #02aab0;
}

.footer .icon-color {
    margin-right: 5px;
}

.footer-privacy {
    display: flex;
    justify-content: flex-end;
}

.bottom-border {
    border-bottom: 0.6px solid #fff;
    justify-content: space-between;

    .bottom-border-logo-container {
        padding-left: 3%;
    }
}

.twitter-color {
    color: #26a7de;
}

.youtube-color {
    color: #ff0000;
}

.linkedin-color {
    color: #0077b5;
}

.apple-badge,
.google-play-badge {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    width: 100px;
    height: 40px;
    cursor: default;
}

.apple-badge img,
.google-play-badge img {
    // margin-bottom: 20px;
    // width: 100px;
    // height: 40px;
    display: block;
    width: 100%;
}

.bottom-footer {
    // padding: 53px 140px 82px 140px;
    padding: 30px;

    .bottom-footer-text-content {
        display: flex;

        p {
            display: flex;
            align-items: center;

            &:last-child {
                margin-left: 15px;
            }

            span {
                margin-right: 5px;
                width: 13px;
            }
        }
    }
}

.bottom-footer p {
    color: #8491a0;
    font-weight: 400;
}

.bottom-footer p.right-txt {
    text-align: right;
}

/* 404 Page CSS */
.found-box {
    padding: 150px 0 200px;

    // padding-top: 215px;
    // padding-bottom: 279px;
    button {
        padding: 10px 20px;
        font-family: "latoregular";
        // color: #000;
    }
}

.found-title {
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.02em;
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.found-txt {
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
    font-family: "Lato-Bold";
    // margin-top: 64px;
    // color: #23babf;
}

.go-back-txt {
    font-size: 16px;
    font-family: "Lato-Bold";
}

.found-search-btn {
    border: 1px solid #ffffff;
    background: transparent;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 auto;
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.found-search-btn:hover {
    background: transparent;
    border: 1px solid #02aab0;
}

.error-image-container {
    width: 150px;
    margin: 0 auto;
}

.go-back-btn {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border: 0;
    border-radius: 8px;
    padding: 12px 52px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.02em;
    margin-top: 32px;
}

/* FAQ page css */
.faq-page {
    padding-top: 160px;
}

.faq-head {
    text-align: center;

    h3 {
        font-size: 44px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 40px;
    }
}

.faq-search-form {
    margin-bottom: 60px;

    div {
        align-items: center;
    }
}

.faq-search {
    background: rgba(255, 255, 255, 0.1);
    border-width: 0;
    border-radius: 20px;
    padding: 10px 25px;
    margin-bottom: 70px;
    width: 320px;
    text-align: left;
    border: none;
}

.faq-search:hover {
    background: rgba(255, 255, 255, 0.1);
    border-width: 0;
}

.faq-search svg {
    margin-right: 11px;
}

.faq-accordions {
    margin: 0 auto;
}

.faq-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px;
}

.faq-top-space {
    margin-top: 70px;
}

/* Contact page css */
.contact-box {
    padding-top: 160px;
    padding-bottom: 240px;
}

.contact-box h3 {
    margin-bottom: 40px;

    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
}

.contact-box p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 50px;
}

.contact-box li {
    display: flex;
    align-items: center;
}

.contact-box .icon-image-container {
    width: 30px;
    margin-right: 10px;
    display: inline-block;
}

.contact-grey {
    background: #191c21;
    border-radius: 6px;
    padding: 64px;
}

.contact-info {
    padding: 64px;
    background: #000000;
    border-radius: 6px;
}

.contact-info h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    margin-bottom: 22px;
}

.contact-info p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 43px;
}

.contact-txt {
    list-style: none;
    padding-left: 8px;

    li {
        align-items: flex-start;

        span {
            width: 80%;
            font-size: 20px;
        }
    }
}

.contact-txt li {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    margin-bottom: 30px;
}

.contact-txt li img {
    margin-right: 12px;
}

.contact-form {
    padding-left: 98px;
}

.contact-form h3 {
    font-weight: 300;
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 45px;
}

.contact-form div {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.contact-form .form-control {
    padding: 11px 32px;
}

.contact-form textarea {
    resize: none;
}

.contact-form [type="submit"] {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-radius: 8px;
    border-width: 0;
    padding: 14px 67px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    width: auto;
    margin: 80px auto 0;
}

/* Login Page CSS */
.login-box {
    padding-top: 200px;
    padding-bottom: 160px;
    position: relative;
}

.login-box img.login-bg {
    width: 100%;
}

.login-box .container-fluid {
    padding-left: 0;
}

.login-box .form-control {
    border: none;
    color: rgb(0, 0, 0);
    font-size: 17px;
}

.login-box .box-shadow {
    // height: 330px;
    overflow-y: scroll;
}

.login-box .position {
    position: relative;

    .disabled-otp {
        background: #848484;
        color: white;
    }
}

.login-box .position .otp-separator {
    // color:rgb(11, 158, 151);
    // font-size: 20px;
    display: none;
}

form.position {
    .button-loader-container {
        margin-bottom: 0;
    }

    p {
        font-size: 12px;
        margin: 0;
        letter-spacing: 1px;
        text-align: center;
        line-height: 20px;

        span {
            display: inline-block;
            color: #5fc4b8;
            text-decoration: underline;
            text-underline-offset: 2px;
            cursor: pointer;
            margin: 0 3px;
            min-height: unset;
        }
    }
}

.back-button {
    color: #00cdac;
    cursor: pointer;
}

.icon-margin-right {
    margin-right: 5px;
}

.login-sub {
    position: absolute;
    left: 8%;
    transform: translateY(-124px);
}

.login-sub img {
    margin-bottom: 25px;
}

.login-sub p {
    font-style: italic;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    min-height: 35px;

    span {
        min-height: unset;
    }

    small {
        color: #00cdac;
        cursor: pointer;
    }

    .disabled-resend {
        color: #ffffff;
        cursor: wait;
        display: flex;
        gap: 5px;

        span {
            color: red;
            display: inline-block;
        }
    }
}

.login-form {
    padding: 0 56px;

    span {
        min-height: 35px;
        color: red;
        display: block;
    }
}

.login-form h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}
.login-form {
   .login-title{
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
   }
   .error-message{
    color: red;
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
}
}

.signup-form-group{
    margin-bottom: 30px;
    .form-control{
        padding: 16px 12px;
    }

}

.login-form p {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 30px;
    margin-top: 25px;
}

.login-form label {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.login-form .PhoneInput {
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
}

.login-form .PhoneInputInput {
    border-width: 0;
}

.login-form .PhoneInputInput:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.login-form .PhoneInputCountrySelectArrow {
    border-color: #191c21;
}

.login-form [type="text"] {
    // padding: 16px 12px;
    border-radius: 8px;
    border-style: none;
}

.login-form [type="submit"] {
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    border-radius: 8px;
    border-width: 0;
    padding: 14px 67px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    // margin-top: 25px;
    margin-bottom: 5px;
}

.login-form .FlagBox {
    position: relative;
}

.login-form .overlay {
    // height: 300px;
    top: 65px;
    left: 0;
    width: 90%;
}

.button-loader-container {
    background-color: #5fc4b8;
    border-radius: 8px;
    border-width: 0;
    // padding: 14px 67px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    // margin-top: 25px;
    margin-bottom: 16px;
}

// Profile Detail Modal
.detail-modal {
    justify-content: center;
    height: 100vh;
    position: fixed;
    right: 0;
    left: 0;
    top: 30px;

    .modal-content {
        width: 60%;
        padding: 30px;
        // background-color: #0077B5;

        .main-container {
            .signup-box {
                margin: 0;
                border: 2px solid #383838;

                .close-btn {
                    text-align: end;

                    svg {
                        cursor: pointer;
                    }
                }

                .myaccount-info {
                    width: 100%;

                    span {
                        height: unset;
                        color: #fff;
                        margin-top: 20px;
                        width: 90%;
                    }

                    .myaccount-info-title {
                        padding: 0 30px 30px;
                    }

                    .myaccount-form {
                        padding: 30px 30px 0;

                        .form-inputs {
                            margin-bottom: 15px;

                            .input-info {
                                label {
                                    margin-bottom: 15px;
                                }

                                input,
                                select {
                                    background: rgba(255,
                                            255,
                                            255,
                                            0.0705882353);
                                    color: #fff;
                                    // -moz-appearance: none;
                                    // -webkit-appearance: none;
                                    // appearance: none;
                                }
                            }
                        }

                        .myaccount-head-btn {
                            display: block;
                            text-align: center;

                            span {
                                padding: 10px 20px;
                                border-radius: 8px;
                                border: none;
                                background-color: #5fc4b8;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.promotional-modal {
    .modal-content {
        border: 1px solid #ccc;
        border-radius: 8px;
        max-width: 640px;
        margin: 0 auto;
        padding: 20px;

        .close-btn {
            width: 15px;
            margin-left: auto;
            cursor: pointer;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .btn-branded {
            width: 40%;
            margin: 0 auto;
        }

        .cam-image-container {
            width: 100px;
            margin: 0 auto;
            display: inline-block;
        }

        li {
            margin-bottom: 10px;

            .image-container {
                display: inline-block;
                width: 25px;
                vertical-align: middle;
                border: 2px solid #FFBF66;
                border-radius: 50%;
                margin-right: 5px;
                padding: 2px;
            }
        }

        .win-image-container {
            display: inline-block;
            width: 25px;
            vertical-align: middle;

        }

        .winner-date {
            font-weight: bold;
            font-size: larger;
        }

        .secret-code {
            width: 70%;
            border-radius: 4px;
            margin-bottom: 0;
        }
    }
}

/* worshopmodal.css */
.workshop-modal {
    justify-content: center;
    height: 100vh;
    position: fixed;
    right: 0;
    left: 0;
    top: 30px;

    .modal-content {
        border: 1px solid #ccc;
        border-radius: 8px;
        max-width: 460px;
        margin: 0 auto;
        padding: 20px;

        .main-container {
            .close-btn {
                width: 15px;
                margin-left: auto;
                margin-bottom: 10px;
                cursor: pointer;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .workshop-banner {
                width: 100%;
                background: #000;
                border-radius: 8px;
                margin-bottom: 20px;
            }

            .register-container {
                display: flex;
                justify-content: center;

                .register-content {
                    background-color: #5fc4b8;
                    box-shadow: rgb(84, 184, 141);
                    padding: 10px;
                    border: none;
                    border-radius: 8px;
                    font-weight: 700;
                    &:hover {
                        color: #fff !important;
                    }
            
                    a {
                        text-decoration: none;
                        color: #000;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

/* workshop-payment-success-modal.css */

.payment-success-modal{
    justify-content: center;
    height: 100vh;
    position: fixed;
    right: 0;
    left: 0;
    top: 30px;
    .modal-content{
        border: 1px solid #ccc;
        border-radius: 8px;
        max-width: 520px;
        margin: 0 auto;
        padding: 40px;
        background-color: #fff;

        .main-container{
            .header-section{
                text-align: center;
                margin-bottom: 30px;
                .success-icon{
                    width: 30px;
                    margin: 0 auto;
                    margin-bottom: 30px;
                    img{
                        width: 100%;
                        display: block;
                    }
                }
                .success-title{
                    font-family: 'lato-Bold';
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 20px;
                    color: #000;
                }
                .success-txt{
                    font-family: 'latoregular';
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 20px;
                    color: #3C3C4399;
                }
            }

            .details-section{
                .details-title{
                    font-family: 'lato-Bold';
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 20px;
                    color: #000;
                }
                .details-content{
                    margin-bottom: 30px;
                    .details-item{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                       .details-item-title{
                           font-family: 'latoregular';
                           font-size: 16px;
                           line-height: 24px;
                           color: #3C3C4399;
                            margin-bottom: 0px;
                       } 
                       .details-item-value{
                           font-family: 'lato-Bold';
                           font-size: 16px;
                           line-height: 24px;
                           color: #000;
                           margin-bottom: 0px;
                       }
                    }
                }
            }

            .btn-container{
                .btn-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    border: none;
                    background-color: #5fc4b8;
                    padding: 10px 20px;
                    width: 100%;
                    border-radius: 82px;
                    color: #fff;
                    font-weight: 700;
                    .download-icon{
                        width: 30px;
                        margin-left: 10px;
                        img{
                            display: block;
                            width: 100%;
                        }
                    }
                }
                .go-to-home{
                    margin-top: 20px;
                }
            }
        }
    }
}


/* BottomBanner.css */
.bottom-banner {
    position: fixed;
    left: 0;
    bottom: -84px;
    transition: all 1.5s ease;
    width: 100%;
    background-color: #000000;
    /* Black background for high contrast */
    color: #FFFFFF;
    /* White text for clarity */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-top: 0.5px solid #494949;

    &.slideup {
        bottom: 0;
        transition: all 1.5s ease;
    }

    .slider-handle {
        position: absolute;
        background: #ffffff;
        top: -44px;
        right: 0;
        color: #000000;
        padding: 10px;
    }
}

.offer {
    flex: 1;
    text-align: left;
    font-size: 16px;
    width: 55%;

    span {
        font-weight: bold;
        font-size: large;
        color: #FFBF66;
    }
}

.time-container {
    width: 45%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.timer {
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
    margin-left: 10px;
    background-color: #686868;
    padding: 10px;
    border-radius: 4px;
}

.email-input {
    display: flex;
    align-items: center;
}

.email-input .button-static {
    padding: 10px 20px;
    background-color: #FFD700;
    /* Bright yellow button */
    color: #000000;
    /* Black text */
    border: none;
    border-radius: 3px;
}

.email-input .button {
    padding: 10px 20px;
    background-color: #FFD700;
    /* Bright yellow button */
    color: #000000;
    /* Black text */
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.email-input .button:hover {
    background-color: #FFC700;
    /* Slightly darker yellow on hover */
}

.relative {
    position: relative;
    height: 30px;
}

// .danger {
//     color: #f00;
//     position: absolute;
//     inset: 0;
//     margin: 5px 0;
//     line-height: 20px;
// }


.toast-container {
    width: fit-content;

    p {
        margin: 0;
    }
}

/* Modal start css */
.open-popup {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.modal-content {
    background-color: #000;
    // border: 0.1px solid #adabab;
    border-radius: 10px;
}

.modal-dialog {
    max-width: 1122px;
}

.modal-body {
    padding: 0;
}

.signup-modal img.signup-bg {
    width: 100%;
}

.signup-modal {
    // padding-top: 100px;
    // padding-bottom: 160px;
}

.signup-modal .col-lg-6 {
    padding: 0;
    margin: auto;
}

.signup-modal .background-image {
    // width: 50%;
}

.signup-modal input[type="text"] {
    padding: 10px 16px;
    border-radius: 8px;
    border: none;
}

.signup-sub {
    position: absolute;
    top: 7%;
    left: 7%;

    .signup-sub-image-container {
        width: 200px;
    }
}

.signup-sub img {
    margin-bottom: 10px;
}

.signup-sub p {
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
}

.signup-box {
    background: #191c21;
    border-radius: 8px;
    padding: 40px 32px;
    margin: 0 35px 0 25px;
    position: relative;

    .position {
        span {}

        .otp-separator {
            // color: rgb(11, 158, 151);
            // font-size: 20px;
            display: none;
        }

        .disabled-otp {
            background: #848484;
            color: white;
        }
    }

    .form-control {
        border: none;
        color: rgb(0, 0, 0);
        font-size: 17px;
    }
}

.signup-box h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px;
}

.signup-box p.first-txt {
    font-weight: 400;
    font-size: 32px;
    line-height: 24px;
    margin-bottom: 10px;
}

.signup-box p.last-txt {
    margin-top: 40px;
    text-align: center;
}

.signup-box p.sub-txt {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
}

.form-label {
    font-size: 20px;
}

.signup-box .login-google {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 0;
    color: #000000;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 100%;
}

.signup-box .icon-image-container {
    width: 20px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 10px;
}

.signup-box .hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    background-color: #191c21;
}

.signup-box .hr-text:before {
    content: "";
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
}

.signup-box .hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: transparent;
    text-align: center;
    height: 1.5em;
    opacity: 1;
}

.close-modal {
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px 0 0;

    svg {
        cursor: pointer;
    }
}

.signup-box .PhoneInput {
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
}

.signup-box .PhoneInputInput {
    border-width: 0;
}

.signup-box .PhoneInputInput:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.signup-box .PhoneInputCountrySelectArrow {
    border-color: #191c21;
}

.signup-box [type="submit"] {
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    border-radius: 8px;
    border-width: 0;
    padding: 14px 67px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}

/* Modal end css */
.app-btn {
    text-align: center;
    margin-top: 20px;
}

.app-btn a {
    margin: 0 10px;
}

.admin-modal {
    .modal-content {
        background-color: unset;
    }

    .admin-info {
        svg {
            display: block;
            margin: 0 auto;
            border: 4px solid #5fc4b8;
            border-radius: 50%;
            padding: 20px;
            font-size: 30px;
        }

        .admin-info-title {
            margin: 20px 0;
            text-align: center;

            p {
                font-size: 20px;
            }
        }

        .admin-head-btn {
            text-align: center;

            span {
                height: unset;
            }
        }
    }

    .admin-main-container {
        .CloseButton {
            position: absolute;
            right: 30px;
            top: 30px;
            font-size: 25px;
            cursor: pointer;
        }
    }
}

/* Membership page CSS */
.membership-box {
    // padding-top: 130px;
    // padding-bottom: 140px;
    padding: 130px 30px 140px;

    .loader-on-subscription {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        /* height: 100vh; */
        right: 0;
        z-index: 111111111;
    }

    .loader-on-subscription-overlay {
        position: absolute;
        background: #bcbcbc75;
        width: 100%;
        height: 100%;
    }
}

.loading-box {
    padding-top: 160px;
    padding-bottom: 240px;
    text-align: center;
}

.membership-box .plan-width {
    width: 22%;
}

.membership-box .plan-width:first-child {
    width: 34%;
}

.membership-main h3 {
    font-weight: 400;
    font-size: 64px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
}

.membership-main p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px;
}

.plan-title h1 {
    font-weight: 400;
    font-size: 60px;
    line-height: 60px;
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.plan-title span {
    -webkit-text-fill-color: #ffffff;
}

.package-box {
    background: #191c21;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s transform;
}

.package-details {
    padding: 42px 44px;
    cursor: default;
}

.package-name {
    padding: 21px;
    // padding-bottom: 46px;
}

.package-details .main-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 15px;
}

.package-details .plan-hr {
    border-bottom: 0.5px solid #ffffff;
    margin-bottom: 80px;
    display: none;
}

.package-details .sub-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-decoration-line: underline;
    text-transform: capitalize;
    margin-bottom: 60px;
}

.package-box .plan-table {
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;

    tr {
        td {
            svg {
                padding: 0.25em;
            }
        }
    }
}

.plan-table tr {
    border: none;
    height: 75px;
}

.package-box .plan-table td {
    border-width: 0;
    // padding: 30px 0px;
    text-align: center;
}

.plan-first-table td {
    text-align: left !important;
    padding-bottom: 20px 0px;
}

.package-box .table> :not(:first-child) {
    border-width: 0;
}

.package-name {
    text-align: center;
    height: 100%;
    // margin-top: -28px;

    .price-head {
        p {
            font-size: 15px;
        }
    }
}

.package-name h3 {
    font-weight: 900;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
}

.package-name .plan-price {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
}

.package-name .mrp-price {
    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    text-decoration: line-through;
}

.package-name .plan-btn {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-radius: 8px;
    border-width: 0;
    width: 90%;
    max-width: 90%;
    padding: 10px 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 25px;
}

.selected-plan {
    border-width: 1px;
    border-style: solid;
    border-color: #02aab0;
    // margin-top: -84px;
    // padding-bottom: 75px;
    transition: 0.3s transform;
    transform: scale3d(1.08, 1.08, 1.08);
}

.selected-plan .selected-div {
    display: flex;
    justify-content: flex-end;
    transition: 0.3s transform;
}

.unselected-div {
    display: none;
    transition: 0.3s transform;
}

.selected-plan .selected-box {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-width: 0;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #000000;
    padding: 2px 12px;
    margin-bottom: 34px;
}

.selected-block {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%) 1 0 0;
    background: #191c21;
    padding-top: 23px;
    padding-bottom: 23px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
    transition: 0.3s transform;
}

.selected-block h3 {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
}

.selected-block h2 {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
    // margin-right: 8px;
}

.selected-block h4 {
    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    text-decoration: line-through;
}

.selected-block button {
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background: #5fc4b8;
    border-radius: 8px;
    border-width: 0;
    padding: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    float: right;
    height: 50px;
    width: 200px;

    &.subscribe-button {
        transition: all 0.4s ease-in-out;

        &:hover {
            background: #2fbcab;
            box-shadow: rgb(84, 184, 141) 0px 2px 10px 0px;
            transition: all 0.4s ease-in-out;
        }

        &:focus {
            color: #000000;
            background: #5fc4b8;
        }
    }
}

.selected-block .selected-details {
    display: flex;
    justify-content: space-between;
}

.selected-block-price-head {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    h3 {
        margin-right: 5px;
    }
}

.selected-block-price {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .selected-block-price-value {
        display: flex;
        align-items: center;

        h2,
        h6 {
            margin-bottom: 0;
        }
    }

    p {
        font-style: italic;
        margin: 0 0 10px 8px;
    }
}

/* Category Page */
.category-page::-webkit-scrollbar {
    display: unset;
}

.category-page>.row {
    margin: 0 8px;
}

.categorys {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
}

.categorys-links {
    padding-left: 45px;

    &.hide-xl {
        padding: 20px;

        button {
            margin-bottom: 0;
        }
    }
}

.categorys .categorys-links.hide-lg {
    display: none;
    height: calc(100vh - 128px);
    overflow: scroll;
}

.alert-get-started {
    background: #191c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 48px;

    p {
        margin-bottom: 0;
    }

    .alert-action {
        display: flex;
        align-items: center;

        p {
            margin-right: 30px;

            span {
                text-transform: lowercase;
                margin-left: 2px;
            }
        }
    }
}

.category-title {
    font-size: 44px;
    margin-bottom: 30px;
}

.category-list,
.category-filter {
    list-style: none;
    margin: 0 0 48px;
    padding: 0;
    font-size: 20px;

    li {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        color: #fff;
        opacity: 0.6;
        width: fit-content;

        a {
            cursor: pointer;
        }

        &:hover {
            opacity: 1;
            color: #02aab0;
        }
    }

    li.isActive {
        opacity: 1;

        &:hover {
            color: #fff;
        }

        &:before {
            content: " ";
            display: inline-block;
            align-self: center;
            width: 24px;
            height: 4px;
            margin: 0 10px 4px 0;
            background: linear-gradient(0deg, #035552 3.71%, #26a899 103.69%);
            transform: matrix(1, 0, 0, -1, 0, 0);
        }
    }

    li.isActiveBurger {
        a {
            color: #26a899;
        }
    }
}

/* Category Classes Container */
.classes-container {
    margin-bottom: 60px;
    margin-top: 60px;
    margin-left: 5px;

    p {
        text-align: center;
        margin-bottom: 0;
        height: 25px;
    }
}

/* Category Class Card*/
.category-class-card {
    position: relative;
    overflow: hidden;
    // margin-bottom: 30px;
    transition: 0.3s transform;
    border-radius: 10px;

    .my-list {
        position: absolute;
        background: #43454c;
        // padding: 15px 20px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        opacity: 0;
        transition: all 0.25s ease-out;
        top: 15px;
        right: 15px;
        z-index: 1;
        cursor: pointer;

        svg {
            font-size: 20px;
        }

        .popover-box {
            height: 20px;
        }
    }

    .btn-branded {
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 18px;
    }

    &:hover {
        .card-image {
            transition: all 0.25s ease-out;
            transform: scale(1.05);
        }

        .my-list {
            transition: all 0.25s ease-out;
            opacity: 1;
        }
    }

    .card-image {
        width: 100%;
        max-width: 100%;
        transition: all 0.25s ease-out;
    }

    .card-details {
        text-align: center;
        padding: 0 18px;

        p {
            font-size: 14px;
            text-transform: uppercase;
        }

        .course-title {
            margin-bottom: 10px;

            .popover-box {
                display: inline-block;

                .popover-header {
                    color: #000;
                    font-size: 20px;
                }
            }

            span {
                font-size: 20px;
                cursor: pointer;
                text-decoration: underline;
                text-underline-offset: 5px;
            }

            small {
                cursor: pointer;
                font-size: 15px;
                margin-left: 10px;
            }
        }
    }

    .card-overlay,
    .card-details {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 20px;
    }

    hr {
        width: 18px;
        height: 3px;
        margin: 14px auto 16px;
        background: #ffffff;
        opacity: 0.7;
        text-align: center;
    }

    .card-overlay {
        top: 0;
        bottom: 0;
        background: linear-gradient(180deg,
                rgba(25, 28, 33, 0) 35.27%,
                #191c21 99.57%);
    }

    .card-overlay,
    .card-details .btn-branded {
        transform: translateY(100%);
        // transition: 0.3s transform;
        transition: all 0.25s ease-out;
    }

    .card-details {
        transform: translateY(0%);
        // transition: 0.3s transform;
        transition: all 0.25s ease-out;

        h3 {
            font-size: 26px;
        }
    }

    &:hover {

        .card-overlay,
        .card-details .btn-branded {
            transform: translateY(0%);
            // transition: 0.3s transform;
            transition: all 0.25s ease-out;
        }

        .card-details {
            // transition: 0.3s transform;
            transition: all 0.25s ease-out;
            transform: translateY(-45%);
        }
    }
}

.small-screen {
    cursor: pointer;

    .my-list {
        opacity: 1;
        height: 30px;
        width: 30px;

        .popover-box {
            height: 28px;

            svg {
                font-size: 15px;
                vertical-align: middle;
            }
        }
    }

    &:hover {
        .card-details {
            transform: translateY(0);
        }
    }

    .card-overlay {
        transform: translateY(0);
    }

    .card-details {
        bottom: 0;

        h3,
        .course-title span {
            font-size: 18px;
            text-decoration: none;
        }

        hr {
            margin: 5px auto;
        }
    }
}

/* Buttons */
.btn-branded {
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    border-radius: 8px;
    border-color: #5fc4b8;
    color: #000000;
    text-transform: uppercase;
    padding: 14px 24px;
    font-size: 14px;
    display: block;
    text-align: center;

    &:hover {
        color: #fff;
        background: #5fc4b8;
        box-shadow: rgb(84, 184, 141) 2px 2px 5px 0px;
    }
}

/* Badges */
.badge.bg-branded {
    color: #000000;
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    box-shadow: 0px 6px 20px rgba(255, 207, 109, 0.42);
    border-radius: 30px;
}

@import url(./admin.scss);

/* Referral */
.referral-page {
    margin-bottom: 200px;
    padding: 34px;
    margin-top: 100px;
}

.referrals {
    background: #191c21;
    padding: 34px;
}

.referral-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    max-width: 75%;
    margin: 60px 0px;
    margin-left: 70px;
    align-items: baseline;
}

.referral {
    text-align: center;
    width: auto;

    h3 {
        font-size: 24px;
        margin-top: 22px;
    }

    p {
        font-size: 14px;
        margin: 0;
    }
}

.referral-image {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100px;
        height: auto;
    }
}

.vector-image {
    display: flex;
    justify-content: center;
    padding: 0;

    img {
        width: 100px;
        margin-top: 30px;
    }
}

.referral-form {
    margin: 60px 0px;
}

.inviteForm {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    width: 70%;
    max-width: 70%;
    position: relative;
}

.inviteInput {
    width: 80%;
    margin: 0px;

    .input-group-text {
        border: none;
        background: transparent;
    }

    input {
        border: none;
        background: transparent;
        appearance: none;
    }
}

.invite-btn {
    position: absolute;
    right: 20px;
    border-radius: 12px;
    margin: 0px;
    color: #fff;
    top: 10px;
    padding: 6px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    z-index: 1048;
    font-weight: 600;
    background: linear-gradient(to top, #02aab0, #00cdac);
}

.invite-btn2 {
    position: absolute;
    right: 10px;
    top: 12px;
    background: linear-gradient(to top, #02aab0, #00cdac);
    border: none;
    border-radius: 50%;
    padding: 4px 8px;

    svg {
        color: #fff !important;
    }

    display: none;
}

.share-btn {
    position: absolute;
    right: 20px;
    top: 14px;
    margin: 0px;
    border: none;
    font-weight: 600;
    z-index: 1048;
    color: #00cdac;
    padding-left: 10px;
    background: linear-gradient(to top, #fff, #fff);
}

.referral-share-form {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    align-items: center;
}

.referral-share-links {
    display: flex;
    gap: 30px;
    padding: 0px;
    margin: 0px;

    li {
        list-style: none;

        a {
            background-color: #fff;
            padding: 6px 8px;
            border-radius: 50%;
            color: #00cdac;
        }
    }
}

/* Gift Cards */
.gift-container {
    background-color: #191c21;
    padding: 32px;
    margin: 90px auto;
    width: auto;
    max-width: auto;
}

.gift-header {
    text-align: center;
    margin-bottom: 30px;

    .go-back-btn {
        color: #fff;
    }
}

.gift-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.giftCard {
    position: relative;
    width: 350px;
    height: 250px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    span {
        position: absolute;
        margin: 28px;
        padding: 10px 25px;
        left: 0;
        background-color: #fff5cc;
        border-radius: 6px;
        color: #000;
    }
}

.cardsHead {
    margin: 60px 0px;
}

.cardsInfo {
    margin: 28px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 20px;
    }
}

.giftInfo {
    position: absolute;
    bottom: 0;
    padding-left: 28px;

    p {
        font-size: 18px;
        max-width: 30ch;
    }
}

//Quiz page 1,2,3,4

.quiz-container {
    max-width: 100%;
    width: 100%;
    min-height: 920px;
    max-height: 100%;
    background-color: #e9ecef !important;
    position: fixed;
    z-index: 111111111;
}

.quiz-page {
    padding: 60px 20px;
    color: #000;
}

.breadcrumbDetails {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    padding: 45px;

    a {
        color: #000;

        svg {
            height: 30px;
            cursor: pointer;
        }
    }

    ol {
        margin-bottom: 0px;
    }

    ol>li>a {
        color: #000;
    }
}

.quizInfo {
    width: 600px;
    margin: 0 auto;

    h3 {
        color: #000;
        margin-bottom: 20px;
    }

    p {
        color: #828282;
        border-left: 2px solid #828282;
        padding: 10px;
        margin-bottom: 50px;
    }
}

.quiz-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-bottom: 45px;
}

.quiz-option {
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 10px 25px;
    border-radius: 8px;

    label {
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 22px;
    }

    .form-check {
        margin: 0px;

        .form-check-input:checked {
            background-color: transparent;
            border-color: #191c21;
        }
    }
}

.quizBtns {
    display: flex;
    justify-content: center;
}

.quizBtn1,
.quizBtn2 {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 20px 45px;
    border: none;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
}

.quizBtn2 {
    background: linear-gradient(to top, #02aab0, #00cdac);
}

.quizTextarea {
    max-width: 100%;
    width: 100%;
    border: none;
    height: 150px;
    margin-bottom: 45px;
}

.fileQuiz {
    opacity: 0;
    // width: 20px;
    // margin: 0 auto;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.attachment {
    width: 300px;
    margin: 0 auto 45px;
    text-align: center;
    background-color: #fff;
    padding: 20px;
    color: #000;
    font-weight: 400;
    border: 2px dashed #02aab0;

    svg {
        display: block;
        margin: 0 auto 10px;
        color: #02aab0;
        margin-top: -30px;
        cursor: pointer;
    }

    span {
        color: #02aab0;
    }
}

.all-notes-title,
.bookmark-title,
.list-title,
.accomplished-title {
    margin: 25px 0 0;
}

.keep-watching-title {
    margin: 0;
}

// Bookmarks

.bookmarks {
    padding: 90px 30px;
}

.bookmark-video-wrap {
    margin-top: 100px;
}

.bookmark-courses {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.bookmark-courses-card {
    position: relative;
    overflow: hidden;
    width: 320px;
    height: 500px;

    span {
        opacity: 0;
    }

    img {
        max-width: 100%;
        width: 100%;
        height: 500px;
    }

    &:hover {
        img {
            opacity: 50%;
        }

        span {
            position: absolute;
            top: 30px;
            left: 30px;
            opacity: 1;
            background-color: #fff;
            color: #000;
            padding: 8px 16px;
            border-radius: 6px;
        }

        .bookmark-courses-info {
            opacity: 1;
            position: absolute;
            width: 100%;
            margin: 0 auto;
            bottom: 50px;
            text-align: center;
        }
    }
}

.bookmark-courses-info {
    opacity: 0;
}

.bookmark-videos {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    margin-bottom: 60px;
}

.bookmark-video-info {
    position: relative;

    img {
        width: 100%;
        height: 400px;
    }

    a {
        position: absolute;
        bottom: 30px;
        left: 30px;
        color: #fff;
        background-color: #191c21;
        padding: 6px 18px;
        border-radius: 6px;

        svg {
            margin-right: 12px;
        }
    }
}

// Playlist-page

.playlist-info {
    margin: 60px 0px;
}

.playlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: "90px";
}

.playlist-video-info {
    position: relative;

    img {
        width: 100%;
        height: 400px;
        margin-bottom: 60px;
    }

    a {
        position: absolute;
        bottom: 90px;
        left: 30px;
        color: #fff;
        background-color: #191c21;
        padding: 12px 64px 12px 24px;
        border-radius: 6px;

        svg {
            margin-right: 12px;
        }
    }
}

.playlist-categories {
    background-color: #191c21;
    padding: 45px;
    height: fit-content;
    margin-bottom: 60px;

    h3 {
        margin-bottom: 20px;
    }

    li {
        list-style: none;
        padding: 10px 0px;

        >a {
            color: #fff;
            margin-left: 20px;
            display: block;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

// Browse trailer
.trailer {
    position: relative;

    img {
        width: 100%;
        height: 400px;
        margin-bottom: 60px;
        opacity: 0.8;
    }
}

.trailer-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    position: absolute;
    bottom: 60px;
    left: 30px;
    right: 30px;
    text-align: center;
}

.trailer-watch-btn {
    a {
        color: #fff;
        background-color: #191c21;
        padding: 12px 64px 12px 24px;
        border-radius: 6px;
    }

    svg {
        margin-right: 12px;
    }
}

.trailer-details {
    text-transform: uppercase;
    max-width: 20ch;
}

// Articles Deatils Page
.articles-details-head {
    margin: 60px 0px;
}

.articles-details-info {
    text-align: center;
    padding: 30px;

    li {
        list-style: none;
        display: inline-block;
        padding: 10px 50px;
        background-color: #ffe5d3;
        margin: 10px;
        border-radius: 8px;
        font-weight: 600;
    }

    a {
        color: #000;
    }

    h2 {
        margin-bottom: 60px;
        font-size: 48px;
        font-weight: 400;
    }
}

.articles-details-backBtn {
    svg {
        padding-right: 10px;
    }

    color: #fff;
    text-decoration: underline;
}

.articles-details-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 600px;
        margin-bottom: 60px;
    }

    a {
        position: absolute;
        color: #000;
        background-color: #ffffff;
        padding: 15px 20px;
        border-radius: 50%;
    }
}

.articles-share {
    margin: 60px 0px;

    h2 {
        margin-bottom: 30px;
    }
}

.articles-share-links {
    display: flex;
    gap: 30px;
    padding: 0;

    li {
        list-style: none;
        font-size: 30px;
    }
}

.instagram-color {
    color: #8c3aaa;
}

.latest-articles-card {
    position: relative;

    img {
        width: 300px;
        height: 350px;
        border-radius: 10px;
    }

    h3 {
        a {
            color: #fff;
        }
    }
}

.tags {
    position: absolute;
    top: 20px;
    left: 10px;

    span {
        background-color: #dad7fe;
        border-radius: 6px;
        color: #000;
        padding: 5px 15px;
        margin-left: 10px;
    }

    :nth-child(2) {
        background-color: #ffe5d3;
    }

    :nth-child(3) {
        background-color: #e2fbd7;
    }
}

.latest-articles {
    margin: 90px 0px;
}

.latest-articles-Info {
    position: absolute;
    bottom: 0;
    left: 15px;

    p {
        font-size: 16px;
        max-width: 30ch;
    }
}

.latest-article-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

// top-contributors componet
.contributors {
    background-color: #191c21;
    width: auto;
    padding: 20px;
    margin-bottom: 60px;
    margin-left: 17px;
}

.contributors-head {
    margin: 20px 0px 60px;

    h3 {
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        font-size: 20px;
    }
}

.contributors-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    font-weight: 600;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}

.contributors-discussions {
    margin-top: 80px;
}

.contributors-discussion {
    margin: 60px 0px;

    span {
        color: #8e8e8e;
    }
}

.contributors-icons {
    display: inline-flex;
    gap: 20px;

    >div>svg {
        color: #8e8e8e;
        margin-right: 5px;
    }
}

// Discussion-page
.discussion-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 60px;
}

.discussion-container {
    margin-bottom: 60px;
}

.discussion-details {
    background-color: #111010;
    padding: 30px;
    margin-bottom: 60px;
}

.discussion-details-sections {
    background-color: #191c21;
}

.discussion-card {
    padding: 30px 15px;
    width: auto;
    height: auto;
    position: relative;
    background-color: #191c21;
    margin-bottom: 30px;
}

.contributors-top-btn {
    display: inline-flex;
    background-color: #191c21;
    padding: 15px;
    margin-bottom: 30px;
    font-weight: 700;
}

.discussion-card-tag {
    position: absolute;
    right: 30px;

    span {
        padding: 6px 20px;
        color: #000;
        border-radius: 6px;
        margin-left: 20px;
    }

    :nth-child(1) {
        background-color: #ccf8fe;
    }

    :nth-child(2) {
        background-color: #fff5cc;
    }
}

.discussion-card-info {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 45px 0px 30px;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    span {
        padding: 6px 50px;
        background-color: #ffe5d3;
        color: #000;
        border-radius: 6px;
    }
}

.discussion-card-info2 {
    a {
        color: #fff;
        margin-right: 10px;
    }

    svg {
        margin-right: 10px;
    }
}

.info2-icons {
    position: absolute;
    right: 30px;
    top: 150px;

    a {
        font-size: 20px;
        color: #fff;

        svg {
            display: block;
        }
    }
}

.discussion-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 30px 0px;

    >a {
        padding: 10px 40px;
        border-radius: 8px;
        background-color: #191c21;
        color: #fff;

        svg {
            margin-right: 10px;
        }
    }

    .discuss-bg-btn {
        background: linear-gradient(to top, #02aab0, #00cdac);
    }
}

// Discussion Details
.discussion-details-head {
    margin: 60px 0px;

    p {
        margin-top: 30px;
        margin-bottom: 0px;
        display: block;
        font-weight: 600;
        font-size: 20px;
    }

    h2 {
        font-weight: 400;
        color: #02aab0;
    }
}

.repiles-info {
    border-left: 2px solid #8e8e8e;
    padding-left: 20px;
    // margin: 10px;
}

.repiles-card {
    padding: 20px 0px;

    p {
        padding: 6px 0px;
    }
}

.replile-links {
    display: flex;
    gap: 30px;
    color: #00cdac;

    :nth-child(3) {
        color: #fff;
    }

    svg {
        color: #8e8e8e;
        margin-left: 10px;
    }
}

.repiles-comment {
    padding-left: 20px;

    textarea {
        margin-bottom: 20px;
    }

    button {
        background: linear-gradient(to top, #02aab0, #00cdac);
        border: none;
        color: #fff;
        font-size: 16px;
        border-radius: 8px;
        padding: 10px 20px;
        text-transform: uppercase;
        margin-bottom: 60px;
    }
}

.discussion-details-section {
    padding: 0px 30px;
}

.discussion-details-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}

.discussion-details-card-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    p {
        color: #eee;

        span {
            color: #02aab0;
        }
    }
}

.discussion-details-card-icons {
    span {
        margin-left: 10px;

        svg {
            color: #8e8e8e;
        }

        p {
            margin-left: 10px;
            display: inline;
        }
    }
}

.discussion-details-play {
    background-color: #2e4e4e;
    max-width: 60%;
    width: 60%;
    padding: 15px 20px;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;

    p {
        margin-bottom: 0px;
    }

    div {
        border: 1px solid #fff;
        width: 60%;
    }

    svg {
        background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
        color: #fff;
        padding: 12px 16px;
        border-radius: 50%;
        font-size: 15px;
    }
}

// Articles

.articles-main-container {
    margin-top: 100px;
}

.articles-head {
    margin: 60px 0px;

    .articles-tabs {
        list-style: none;
        padding-left: 0;
        display: flex;
        gap: 60px;
        margin-top: 50px;
        margin-bottom: 88px;
        flex-wrap: wrap;
    }

    .articles-tabs li {
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
    }

    .articles-tabs li:first-child {
        border-bottom: 1px solid #ffffff;
    }

    .articles-tabs .arrow-up {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #ffffff;
        width: 0;
        margin: 0 auto;
        padding-top: 6px;
    }
}

.articles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.articles-card {
    background-color: #fff;
    color: #000;
    display: inline-block;
    width: 300px;
    padding: 30px 20px;
    border-radius: 10px;
    margin-bottom: 30px;

    img {
        margin-top: 20px;
    }
}

.articles-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    span {
        background-color: #ccf8fe;
        border-radius: 6px;
        color: #000;
        padding: 5px 15px;
    }

    :nth-child(2) {
        background-color: #fff5cc;
    }

    :nth-child(3) {
        background-color: #dad7fe;
    }
}

.articles-card-img {
    margin-bottom: 30px;
}

.articles-info {
    h3 a {
        color: #000;
        margin-top: 20px;
        display: block;
    }

    p {
        font-weight: 400;
    }
}

/* Notes Details CSS */
.notes-section {
    padding-top: 120px;
    padding-bottom: 120px;
}

.notes-box {
    background: #191c21;
    padding: 40px 30px;
    margin: 25px 40px 58px;
    max-height: 80vh;

    .modal-box-margin {
        margin-top: 10px;
    }
}

.notes-box .title-line {
    align-items: center;
}

.course-completion-container {
    padding-top: 25px;
}

.notes-box h3 {
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
    letter-spacing: 0.1px;
    color: #f2f2f2;
    margin-bottom: 23px;
}

.notes-box span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #f2f2f2;
    float: right;
}

.notes-box .buttons button {
    color: #000000;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    padding: 7px 26px;
    background: #ffe5d3;
    border-radius: 6px;
    border-color: #ffe5d3;
    margin-right: 10px;
    // margin-bottom: 47px;
}

.notes-box img {
    width: 100%;
}

.notes-box .notes-hr {
    background: #ebeff2;
    height: 1px;
    margin-bottom: 24px;
}

.notes-box h4 {
    margin-top: 25px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #f2f2f2;
}

.notes-box p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #f2f2f2;
    opacity: 0.7;
    margin-bottom: 25px;
    max-height: 30vh;
    overflow: scroll;
    margin-bottom: 0;
}

.notes-box span {
    svg {
        font-size: 15px;
        margin-left: 3px;
        vertical-align: middle;
    }
}

.notes-box .single-notes-navigation {
    cursor: pointer;
    color: #00cdac;

    .single-notes-navigation-icon {
        display: inline-block;
        margin-left: 5px;
    }
}

.notes-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;

    p {
        margin: 0px 10px 0px;
    }

    input[type="checkbox"] {
        cursor: pointer;
    }
}

// Communities page
.communities-head {
    margin: 100px 0px 60px;
}

.community-content {
    position: relative;

    img {
        opacity: 0.7;
        width: 95%;
    }

    a {
        color: #fff;
    }
}

.community-trending-sub {
    position: absolute;
    bottom: 0px;
    text-align: center;
    padding: 0px 10px;

    h4 {
        font-size: 25px;
        text-transform: uppercase;
    }

    p {
        text-transform: uppercase;
    }
}

.communities-slider {
    margin-bottom: 60px;

    h2 {
        margin-bottom: 30px;
    }
}

.trending-box .slick-next {
    right: -28px;
}

.communities-form {
    display: flex;
    flex-wrap: wrap;
    gap: "30px";
    justify-content: space-between;
    margin-bottom: 60px;

    h2 {
        color: #02ffd5;
        max-width: 10ch;
        font-size: 60px;
        font-weight: 500;
    }
}

.communities-form-detail {
    width: 70%;
    background-color: #fff;
    border-radius: 4px;
    color: #000;

    ul {
        border: none !important;
    }

    >ul>li>button {
        color: #334d6e;
    }
}

.tab-content {
    padding: 20px 20px 40px;
}

.communities-icons-info {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.communities-icon {
    display: flex;
    gap: 30px;
}

.communities-icon-text {
    a {
        margin-left: 20px;
        color: rgb(154, 154, 154);
    }

    :nth-child(2) {
        color: #00cdac;
    }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: #fff;
}

.communities-discussion-form {
    textarea {
        border: none;
    }
}

// Cousre Details
.course-details-wrapper {
    margin: 20px;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    justify-content: space-between;
    gap: 90px;
}

.course-details-info {
    background-color: #191c21;
    padding: 30px;
    border-radius: 6px;
    margin-top: 30px;
    height: fit-content;

    span {
        color: #00cdac;
        margin-top: 30px;
        display: block;

        svg {
            margin-right: 7px;
        }
    }
}

.course-details-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    margin-top: 20px;

    a {
        color: #00cdac;

        svg {
            margin-right: 7px;
        }
    }
}

// Course Completion
.course-completion-container,
.course-details-container {
    // .topic-quiz-container {
    margin: 60px 0px 0px;
}

.course-detail-main-container {
    // width: 95%;
    margin: 0 auto;
}

.course-detail-container {
    margin: 135px 0 60px;
    position: relative;

    .module-content {
        margin-top: 15px;
        // padding: 0 30px;
    }
}

.course-detail-container .module-lesson-content-complete {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px;

    p {
        color: #d1d1d1;
        font-size: 16px;
        font-family: "latolight";
    }
}

.module-lesson-bookmark-complete {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.module-lesson-button {
    background: rgb(72, 72, 72);
    // width: 100px;
    padding: 10px 20px;
    border-radius: 5px;
    // text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    max-height: 44px;
    min-width: 95px;

    svg {
        font-size: 22px;
        margin-right: 10px;
    }

    p {
        margin-bottom: 0;
    }

    .navigation-icon {
        margin-left: 3px;
        width: 18px;

        svg {
            margin-right: 0;
        }
    }
}

.course-detail-container .complete-button {
    // padding: 10px 20px;
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    // background-color: #5FC4B8;
    // margin-bottom: 15px;
    width: fit-content;
    border-radius: 6px;
    cursor: pointer;
    // align-self: flex-end;
}

.completed-button {
    color: #888888;
    padding: 10px 20px;
    border: 1px solid #02aab0;
    border-style: dashed;
    border-radius: 6px;
    // margin-bottom: 15px;
    width: fit-content;
    align-self: flex-end;
    cursor: default;
}

.lesson-complete-modal-overlay {
    background: #000000;
    opacity: 0.65;
    height: 100vh;
    width: 100vw;
}

.lesson-complete-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111112;
    // height: 100vh;
    // width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
}

.lesson-complete-modal-container {
    position: absolute;
    background-color: #3b3b3b;
    // height: 200px;
    width: 40%;
    border-radius: 15px;
    padding: 20px;

    .image-container {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
    }

    .lesson-complete-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .buttons {
        span {
            padding: 10px 20px;
            background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
            border-radius: 6px;
            margin-bottom: 15px;
            margin-right: 15px;
            width: fit-content;
            cursor: pointer;
            display: inline-block;
        }

        .no {
            background: none;
            border: 1px solid #fff;
        }
    }
}

.course-completion-wrapper {
    background-color: #191c21;
    margin: 20px;
}

.course-completion {
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    justify-content: space-between;

    img {
        border-radius: 12px;
        width: 100%;
    }
}

.course-completion-info {
    h3 {
        margin-bottom: 20px;
    }

    a {
        display: block;
        color: #00cdac;
        margin-top: 30px;

        svg {
            margin-right: 10px;
        }
    }
}

.course-completion-btns {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    button {
        background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
        border: none;
        padding: 5px 20px;
        border-radius: 8px;
        font-weight: 600;

        svg {
            margin-right: 10px;
        }
    }
}

.course-hr {
    width: 100%;
    border: 1px solid #585858;
    margin: 50px 0px;
}

.faq-wrap {
    max-width: 70ch;
    margin: 0 auto;

    h2 {
        margin-bottom: 30px;
        text-align: center;
    }
}

.page-breadcrumbs {
    background-color: #191c21;
    margin: 20px;
    padding: 30px;
    display: flex;
    border-radius: 8px;

    svg {
        color: #fff;
        margin-right: 20px;
    }
}

.breadcrumbs-links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;

    a {
        color: #9d9d9d;
        font-size: 18px;
        font-weight: 600;
    }

    :hover,
    :active {
        color: #fff;
    }
}

//Case Study
.case-study {
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    justify-content: space-between;

    img {
        border-radius: 12px;
        width: 100%;
    }
}

.case-study-wrapper {
    background-color: #191c21;
    margin: 20px;
}

.case-study-wrapper-info {
    padding: 30px;
    padding-bottom: 0px;

    >p {
        font-size: 14px;
        color: gray;
        text-transform: uppercase;

        >span {
            font-size: 10px;
        }
    }
}

.case-study-info {
    h3 {
        margin-bottom: 30px;
    }

    p {
        display: inline-flex;
        align-items: center;

        svg {
            background-color: #00cdac;
            padding: 10px;
            margin-right: 20px;
            border-radius: 50%;
        }
    }
}

.case-study-descp,
.case-study-attach {
    background-color: #191c21;
    margin: 20px;
    padding: 30px;
    border-radius: 12px;

    h3 {
        margin-bottom: 20px;
    }
}

.case-study-attach {
    .setting-attach {
        margin: 0;
        width: 300px;

        svg {
            margin-top: -30px;
        }
    }
}

.read-more-text {
    background-color: #fff;
    padding: 30px;
    color: #000;
    border-radius: 12px;

    span {
        margin-top: 20px;
        display: block;
        font-size: 16px;
        text-decoration: underline;
        cursor: pointer;

        svg {
            margin-left: 10px;
        }
    }
}

.case-study-btn {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    margin-left: 30px;
    font-weight: 600;

    a {
        color: #000;
    }
}

// Instructor
.instructor-wrapper {
    padding: 20px;
}

.instructor-wrapper-infos {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.instructor-info-titles {
    h1 {
        background: -webkit-linear-gradient(#00cdac, #02aab0);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 60px;
        font-weight: 400;
    }

    h3 {
        font-weight: 400;
        margin-bottom: 0px;
    }
}

.instructor-course-container {
    margin-bottom: 50px;

    .border {
        border: 1px solid #fff4;
        border-radius: 8px;
        overflow: hidden;
    }

    .accordion-item {
        background-color: transparent;
        border-bottom: 3px solid rgba(256, 256, 256, 0.125);
        padding: 35px;

        &:hover {
            background-color: #111;
        }

        .accordion-body {
            .btn-branded {
                margin: 0 auto;
                font-size: 14px;
                font-family: "latoregular";
                text-transform: capitalize;
                padding: 10px 20px;
                letter-spacing: 0.5px;

                .button-loader {
                    height: 20px;
                    width: 70px;
                }
            }
        }

        .accordion-button {
            font-weight: 900;
            font-size: 18px;
            line-height: 29px;
            color: #ffffff;
            padding: 0;
            background: transparent;
            align-items: flex-start;
            justify-content: space-between;

            &::after {
                background-image: url("../images/image-svg.svg");
                margin-left: 10px;
            }

            .accordion-image-container {
                margin-right: 25px;
                display: none;
            }

            .accordion-content-container {

                // width: 70%;
                h4 {
                    color: #5fc4b8;
                }

                p {
                    font-weight: 400;
                }
            }
        }

        .topic-box {
            background-color: transparent;
            border-radius: 0;

            .topic-sub {
                // padding: 0 20px;
            }

            h3,
            .topic-txt1 {
                color: #fff;
            }

            // .image-container {
            // 	width: 20%;
            // }
        }
    }
}

.instructor-info-general-title {
    margin: 20px auto 30px;

    h1 {
        font-family: "Lato-Bold";
    }
}

.instructor-info-link {
    a {
        color: #fff;
        margin-left: 10px;
        text-decoration: underline;
    }
}

.instructor-info-images {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    align-items: start;
    width: 85%;
    margin: 0 auto 50px;

    .instructor-info-container {
        ul {
            list-style: disc;
        }

        a {
            color: #5fc4b8;
        }
    }

    >div>img {
        width: 100%;
        max-width: 100%;
        height: 500px;
    }
}

.instructor-info-container {
    .instructor-info-title-box {
        h1 {
            background: -webkit-linear-gradient(#00cdac, #02aab0);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            font-weight: 400;
            font-family: "latoregular";
        }

        h3 {
            font-weight: 400;
            margin-bottom: 0px;
        }
    }

    .short-title {
        font-family: "latoregular";
    }

    .read-more {
        font-family: "latoregular";
    }

    span {
        text-decoration: none;
        transition: all 0.5s ease;
        cursor: pointer;

        svg {
            margin-left: 10px;
            vertical-align: middle;
        }

        &:hover {
            font-size: 17px;
            transition: all 0.5s ease;
        }
    }

    .social-media-links-container {
        .social-media-links-list {
            padding-left: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            li {
                margin-right: 30px;
                padding: 10px;
                border: 1px solid #fff;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                // a svg {
                // 	margin-right: 10px;
                // }
            }
        }
    }

    .instructor-info-container-description {
        ul {
            padding-left: 0;

            li {
                margin-bottom: 10px;
            }
        }
    }
}

.instructor-about-info {
    margin: 90px auto 75px;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 85%;

    span {
        font-family: "latoregular";
        font-size: 22px;
        margin-bottom: 15px;
    }

    q {
        font-family: "latoregular";
        margin: 0 auto;
        font-size: 27px;
        font-style: italic;
    }
}

.instructor-trailer-image {
    position: relative;

    img {
        opacity: 0.7;
    }

    p {
        position: absolute;
        bottom: 20px;
        left: 20px;
        background: #191c21;
        color: #707070;
        border-radius: 6px;
        padding: 10px 30px;

        svg {
            margin-right: 20px;
            color: #fff;
        }
    }
}

.instructor-icons-info {
    margin: 30px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 60px;

    >div {
        >svg {
            color: #00cdac;
            margin-right: 5px;
        }
    }
}

.instructor-read-more,
.module-read-more {
    span {
        margin-top: 10px;
        display: block;
        font-size: 16px;
        // text-decoration: underline;
        cursor: pointer;

        // text-underline-position: under
        svg {
            margin-left: 10px;
        }
    }
}

.instructor-cousre {
    margin: 45px 0px;

    h3 {
        margin-bottom: 30px;
    }
}

.instructor-cousre-images {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    >div {
        // mask-image: -webkit-gradient(linear, center bottom, center top,
        // 		color-stop(0.00, rgba(77, 169, 118, 0.5)),
        // 		color-stop(1.00, rgb(9, 173, 48)));
        position: relative;

        >img {
            width: 280px;
            height: 350px;
            border-radius: 6px;
            background: linear-gradient(to bottom, #095ae4, red);
        }

        >p {
            position: absolute;
            bottom: 5px;
            font-size: 30px;
            // color: #02ffd5;
            width: 100%;
            font-weight: 700;
            text-align: center;
            cursor: pointer;
            line-height: unset;
            z-index: 1;
        }

        .content-overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            bottom: 0;
            background: linear-gradient(180deg,
                    rgba(25, 28, 33, 30%) 61.54%,
                    #191c21 96.32%);
        }
    }
}

.read-more {
    span {

        // font-family: 'latoregular';
        // text-decoration: none;
        // cursor: pointer;
        svg {
            margin-left: 10px;
            vertical-align: middle;
            color: #5fc4b8;
        }
    }

    p {
        margin-bottom: 0;
        font-family: "latoregular";
    }
}

.reviews {
    h3 {
        margin-bottom: 30px;
    }
}

.reviews-card {
    background-color: #191c21;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.reviews-info {
    display: flex;
    gap: 30px;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
}

.review-stars {
    color: #00cdac;
    margin-bottom: 10px;

    svg {
        margin-right: 5px;
    }

    span {
        color: #fff;
        margin-left: 10px;
    }
}

.review-stars-white {
    color: #fff;
}

// Module Component
.module-container {
    // max-width: 500px;
    height: 480px;
    overflow-y: scroll;
    margin: 20px 0;

    scrollbar-width: thin; // For Firefox
    scrollbar-color: gray lightgray; // For Firefox

    &::-webkit-scrollbar {
        -moz-appearance: none;
        display: block;
        width: 8px; // Customize the scrollbar width
        // background-color: #484848; // Customize the scrollbar background color
        scroll-behavior: smooth;
    }

    &::-webkit-scrollbar-thumb {
        scroll-behavior: smooth;
        background-color: #484848; // Customize the scrollbar thumb color
        border-radius: 4px; // Customize the scrollbar thumb border radius
    }
}

.module-overflow-container {
    // overflow-y: scroll;
}

.module-question {
    padding: 20px;
    border-bottom: 1px solid rgb(92, 92, 92);
}

.module-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    cursor: pointer;

    .module-question-title {
        margin: 5px 0;

        span {
            margin-right: 5px;
        }

        small {
            // font-size: 12px;
            z-index: 1;
            margin-right: 7px;
            position: relative;

            .tooltip-box {
                position: absolute;
                top: 25px;
                left: 25px;
                background-color: #191c21;
                border: 2px solid #5c5c5c;
                color: #ffffff;
                border-radius: 5px;
                padding: 15px;
                width: 240px;
            }
        }
    }
}

.module-faq-btn {
    background: transparent;
    border: 0;
}

.module-faq-btn svg {
    color: #5c5c5c;
}

.module-content {
    // margin-top: 20px;

    div {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        gap: 10px;

        p {
            font-size: 18px;
            margin-bottom: 0px;
            padding: 20px 0px;
            cursor: pointer;
        }

        svg {
            color: #5c5c5c;
            margin-right: 10px;
        }
    }

    .assessment-box {
        svg {
            // margin-right: 10px;
            width: 28px;
            height: 25px;
        }

        .assessment-active {
            color: #00cdac;
        }
    }

    .loading-box {
        padding: 10px 0;
        text-align: center;
    }
}

.module-content .module-read-more {
    display: block;
    padding: 20px 0px;

    p {
        color: #d1d1d1;
        font-size: 16px;
        padding: 0px;
        margin-bottom: 10px;
    }

    span {
        display: inline;
        color: #00beae;
    }
}

.module-content-description {
    color: #d1d1d1;
}

.module-content .module-lesson-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0px;

    p {
        // color: #d1d1d1;
        padding: 0px;
    }
}

.module-content .module-lesson-content-head {
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;

    .lesson-image {
        width: 30%;
        border-radius: 5px;
        overflow: hidden;
        position: relative;

        .progress {
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;

            .progress-bar {
                height: 8px;
            }
        }

        .play-icon {
            position: absolute;
            bottom: 5px;
            left: 5px;
            height: 25px;
            width: 25px;
        }

        span {
            position: absolute;
            bottom: 5px;
            right: 5px;
            padding: 5px 10px;
            background-color: #272c33;
            border-radius: 20px;
            color: #fff;
            font-size: 10px;
        }

        .overlay-lock {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #0000009a;
                width: 100%;
                height: 100%;
            }

            svg {
                color: #a3a3a3;
                margin: 0 auto;
                font-size: 20px;
                position: absolute;
                top: 50%;
                bottom: 0;
                left: 0;
                right: 0;
                transform: translateY(-50%);
            }
        }
    }

    .module-lesson-content-head-lesson-name {
        font-family: "latolight";
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px 0px;
        width: 70%;

        .module-lesson-head-lesson-name-number {
            align-items: flex-start;
        }

        p {
            // color: #fff;
        }
    }
}

.module-complete {
    color: #00483c;
    text-decoration: line-through;
    display: flex;
    gap: 10px;
    align-items: center;
}

.module-complete-icon {
    color: #00cdac !important;
    font-size: 30px;
}

.module-complete-icon2 {
    color: #028670 !important;
}

.module-number-icon {
    color: #00cdac !important;
    // background: #282828;
    // border-radius: 50%;
    // padding: 7px 10px;
    font-size: 23px;
}

.module-lesson-icon,
.module-lesson-icon-assessment {
    background: #282828;
    color: #5c5c5c;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

// .module-lesson-icon-assessment {
// 	display: inline-flex;
// }

.module-incomplete,
.module-active {
    display: flex;
    gap: 10px;
    align-items: center;
}

.module-active {
    color: #00cdac;
}

.module-data {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    color: #00cdac;

    svg {
        color: #00cdac !important;
    }
}

.module-infocard {
    background-color: #191c21;
    margin: 30px 0px;
    padding: 30px;
    position: relative;

    p {
        font-size: 15px;
        // margin: 0 10px 10px 10px;
        text-align: center;
        color: #636363;
    }

    h2 {
        background: -webkit-linear-gradient(#00cdac, #02aab0);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 30px;
        margin-bottom: 25px;
    }

    span {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
    }
}

.workshop-image-container {
    margin-bottom: 10px;
}

.infocard-dots {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 120px;
    background-color: transparent;
    background-image: radial-gradient(rgb(11, 158, 151) 10%, transparent 11%),
        radial-gradient(rgb(18, 186, 172) 10%, transparent 11%);
    background-size: 12px 12px;
    background-position: 0 0, 12px 12px;
    background-repeat: repeat;
}

.module-infocard-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 30px;

    a {
        color: #fff;
        text-decoration: underline;
        text-underline-position: under;
    }
}

.module-discussion {
    padding: 50px 30px 30px;

    p {
        font-family: "latolight";
    }
}

.module-discussion-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    a {
        text-decoration: underline;
        color: #fff;
        text-underline-position: under;
    }
}

.module-discussion-card-info {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 45px 0px 30px;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
}

.module-discussion-card-info2 {
    a {
        color: rgb(152, 152, 152);
        margin-right: 10px;
    }

    svg {
        margin-right: 10px;
    }
}

// Topic Quiz and Topic Quiz Completion pages

.quiz-topic-wrapper {
    // margin: 20px;
    // grid-template-columns: 2fr 1.5fr;
    display: grid;
    grid-template-columns: 2fr;
    justify-content: space-between;
    gap: 90px;
}

.quiz-topic-info {
    background-color: #191c21;
    padding: 30px;
    height: fit-content;
    // margin-top: 20px;

    h3 {
        font-weight: 400;
    }

    p {
        margin: 20px 0px;
    }

    button {
        cursor: pointer;
        background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
        padding: 10px 20px;
        border-radius: 8px;
        border: none;

        a {
            color: #000;
        }
    }
}

.quiz-completion-span {
    color: #8e8e8e;
    font-size: 22px;
}

.quiz-completion-para {
    color: #00cdac;
}

/* Member Checkout CSS */
.member-checkout {
    padding: 10px 0;
}

.member-checkout p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}

.member-checkout h3 {
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    margin-bottom: 45px;
    text-align: center;
}

.member-checkout .form-group {
    display: block;
    margin-bottom: 15px;
    background: #191c21;
    border-radius: 8px;
    padding: 19px 30px;
}

.member-checkout .width28 {
    width: 28.1%;
    margin: 0 auto 20px;
}

.member-checkout .width58 {
    width: 57.3%;
    margin-bottom: 20px;
}

.member-checkout .col-lg-6:first-child .width58 {
    margin-left: auto;
}

.member-checkout .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.member-checkout .form-group label {
    position: relative;
    cursor: pointer;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
}

.member-checkout .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background: #000000;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.member-checkout .form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.member-checkout .btn-grup {
    text-align: center;
    margin-top: 50px;
}

.member-checkout .nxt-btn {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    background: #191c21;
    border-color: #191c21;
    border-radius: 8px;
    padding: 12px 40px;
}

.member-checkout .skip-link {
    margin-left: 32px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.checkout-modal .number-btn {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    padding: 35px;
    border-radius: 50%;
    border: 6px solid #02aab0;
    background: transparent;
    margin-bottom: 25px;
}

.checkout-modal h3 {
    font-weight: 400;
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 15px;
}

.checkout-modal p.interest-txt {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

.checkout-modal p.other-txt {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
}

.checkout-modal .main-row {
    align-items: center;
}

.checkout-modal .sub-row {
    align-items: flex-end;
}

.checkout-modal .check-list {
    list-style: none;
    padding-left: 0;
}

.checkout-modal .check-list li {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 17px;
}

.checkout-modal .plan-btn {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-radius: 8px;
    border-width: 0;
    padding: 14px 67px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}

/* Dashboard CSS */
.non-top {
    padding-top: 86px;
}

.non-bottom {
    padding-bottom: 120px;
}

.non-video {
    padding-top: 169px;
    padding-bottom: 120px;
}

.non-video .non-row {
    align-items: center;
}

.non-video h3 {
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    margin-bottom: 28px;
}

.non-video .non-list {
    padding-left: 0;
    list-style: none;
}

.non-video .non-list svg {
    margin-right: 24px;
    margin-top: 5px;
}

.non-video .non-list li {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 17px;
    display: flex;
}

.non-video img {
    float: right;
    width: 100%;
}

.non-video a.non-link {
    background: #191c21;
    border-radius: 8px;
    padding: 14px 20px 14px 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #ffffff;
    position: absolute;
    left: 23px;
    bottom: 42px;
}

.non-hero-box {
    padding-top: 86px;
    padding-bottom: 120px;
}

.non-hero-box .container-fluid {
    padding-left: 70px;
}

.non-hero-box .welcome-hero {
    font-weight: 400;
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 22px;
}

.non-hero-box h3 {
    font-weight: 400;
    font-size: 44px;
    line-height: 53px;
}

.non-hero-box h4 {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 50px;
}

.non-hero-box p.hero-price {
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
}

.non-hero-box p.hero-status {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.non-hero-box .non-hero-btn {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-radius: 8px;
    border-width: 0;
    padding: 12px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    width: 100%;
}

.non-hero-box .slick-slide {
    padding: 0 10px;
}

.non-hero-box .slick-slide img {
    width: 100%;
}

.instructor-box {
    position: relative;
}

.hero-content {
    background: linear-gradient(180deg,
            rgba(25, 28, 33, 0) 25.87%,
            #191c21 99.57%);
    text-align: center;
    padding: 0 38px 30px;
    position: absolute;
    z-index: 999;
    top: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.hero-content .hero-name {
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    text-transform: uppercase;
}

.hero-content .hero-hr {
    width: 16.42px;
    height: 2.76px;
    background: #ffffff;
    opacity: 0.7;
    margin: 10px auto;
}

.hero-content .hero-skill {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.hero-content .hero-coming {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
}

.hero-content .btn {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-radius: 8px;
    border-width: 0;
    padding: 14px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;
}

.non-hero-box .slick-prev:before {
    background-image: url("../images/prev-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.non-hero-box .slick-prev {
    width: 62px;
    height: 62px;
    background: #191c21;
    z-index: 9;
    border-radius: 50%;
}

.non-hero-box .slick-prev:hover,
.non-hero-box .slick-prev:focus {
    background: #02aab0;
}

.non-hero-box .slick-next {
    display: none !important;
}

.non-hero-box .slick-next:before {
    display: none;
}

/* Member page CSS */
.certificate-box {
    margin-top: 48px;
    margin-bottom: 50px;
}

.certificate-box h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 46px;
    // margin-bottom: 50px;
}

.certificate-box .certi-link {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    opacity: 0.7;
    float: right;
}

.certificate-box .my-certy {
    background: #191c21;
    border-radius: 8px;
    padding: 50px 27px;
    position: relative;
}

.certificate-box .my-certy img {
    width: 100%;
}

.certificate-box .view-btn {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
}

.certificate-box .view-btn .btn {
    background: #191c21;
    border-radius: 8px;
    border-color: #191c21;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    padding: 13px 77px;
}

.welcome-box .first-box {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 38px 46px 81px;
    margin-top: 48px;
    margin-bottom: 48px;
}

.welcome-box h3 {
    font-weight: 400;
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 23px;
}

.welcome-box p {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    width: 70.3%;
}

.welcome-box .btn {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-radius: 8px;
    border-width: 0;
    padding: 14px 66px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;
    float: right;
    margin-top: -15px;
}

.dashboard-main-container {
    margin-top: 100px;

    .course-box .course-img {
        height: 350px;
        overflow: hidden;
        border-radius: 7px;
        cursor: pointer;

        .course-content {
            height: unset;
            bottom: 0;
            top: unset;
        }

        &:hover {
            img {
                transition: all 0.25s ease-in;
                transform: scale(1.05);
            }
        }
    }
}

.course-box h3 {
    font-weight: 400;
    font-size: 30px;
    line-height: 46px;
    // margin-bottom: 30px;
}

.course-box .course-all {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    opacity: 0.7;
    float: right;
}

.course-box .course-img {
    position: relative;
}

.course-box .course-img img {
    transition: all 0.25s ease-out;
    width: 100%;
}

.course-box .course-content {
    background: linear-gradient(180deg,
            rgba(25, 28, 33, 0) 10.47%,
            #191c21 99.57%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px 21px;
}

.course-box .course-box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 50px;

    h3 {
        // color: #02AAB0;
        font-weight: bold;
        font-size: 38px;
    }

    span {
        cursor: pointer;
    }
}

.course-box {
    .course-box-course-name {
        background: #191c21;
        margin-bottom: 10px;
        padding: 20px 25px;
        border-radius: 10px;
        transition: height 4s;

        .course-box-course-name-title {
            display: flex;
            justify-content: space-between;
        }

        // h3{
        // 	text-decoration: underline;
        // }
        .course-img {
            width: unset !important;
            // margin-top: 30px;
        }
    }

    .slick-track {
        // margin: unset;
    }
}

.error-404 {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.not-found {
    background-color: #191c21;
    padding: 30px;
    border-radius: 5px;
}

.no-data-found-container {
    width: 200px;
    margin: 20px 0 0 10px;
    text-align: center;
}

.course-content .course-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    // text-decoration: underline;
    cursor: pointer;
}

.course-content .course-hr {
    width: 16.42px;
    height: 2.76px;
    background: #ffffff;
    opacity: 0.7;
    margin: 10px auto;
}

.course-content .course-skill {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 12px;
    max-width: 25ch;
    margin: 0 auto;
    // cursor: pointer;
}

.course-content .progress-info {
    display: flex;
    justify-content: space-between;
}

.course-content .progress-info .sub-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
}

.course-content .progress-info .green-info {
    color: #02aab0;
}

.course-content .progress-info .grey-info {
    color: #00c1ab;
}

.course-content .progress {
    height: 8px;
    background: rgba(95, 196, 184, 0.3);
    border-radius: 71.5px;
}

.course-content .progress-bar {
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-radius: 71.5px;
}

.course-sub {
    span {
        text-align: center;
        display: block;
    }
}

/* Progress Courses CSS */
.progress-box {
    padding-top: 50px;
    padding-bottom: 50px;
}

.progress-box .slick-slide {
    padding: 0 10px;
}

.progress-box .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.progress-box .slick-prev,
.progress-box .slick-next {
    width: 62px;
    height: 62px;
    background: #191c21;
    z-index: 9;
    border-radius: 50%;
}

.progress-box .slick-prev:hover,
.progress-box .slick-prev:focus,
.progress-box .slick-next:hover,
.progress-box .slick-next:focus {
    background: #02aab0;
}

.progress-box .slick-prev {
    left: -28px;
}

.progress-box .slick-next {
    right: -28px;
}

.progress-box .slick-prev:before {
    background-image: url("../images/prev-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.progress-box .slick-next:before {
    background-image: url("../images/nxt-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.ongoing-course-section {
    display: block;
}

.progress-ongoing-box {
    padding: 50px 0;

    .course-content {
        justify-content: space-between;

        .course-skill {
            margin-bottom: 10px;
            cursor: pointer;
        }

        .course-icon {
            width: 20px;
            margin-left: auto;
            cursor: pointer;
        }
    }
}

.course-icon img {
    // width: auto !important;
    float: right;
}

.keep-watching,
.accomplished,
.list-box,
.bookmark-box {
    padding: 25px;

    &.trending-box .content {
        &:hover {
            border: none;
            // transition: border-color 0.5s ease;
        }
    }

    .slick-track {
        align-items: flex-start;
    }

    h3 {
        font-weight: 400;
        font-size: 24px;
        line-height: 46px;
        // margin-bottom: 50px;
    }

    .course-card {
        display: block;
        position: relative;
        // background: linear-gradient(to bottom right, #000, #303030);
        border-radius: 10px;

        .image-container {
            // height: 175px;
            // width: 350px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: all 0.3s ease-in;
            }

            .progress {
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
            }
        }

        .image-container:hover {
            .resume-icon {
                opacity: 1;
                transition: all 0.3s ease-in;
            }

            img {
                transition: all 0.3s ease-in;
                transform: scale(1.1);
            }
        }

        .resume-icon {
            transition: all 0.3s ease-out;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #43454c;
            padding: 15px;
            border-radius: 50%;
            border: 1.5px solid #a4a4a4;
            cursor: pointer;
        }

        .course-content {
            justify-content: space-between;
            width: 100%;

            .progress-info {
                display: none;
            }

            .course-title {
                text-align: left;
                font-family: "Lato-Bold";
                text-underline-offset: 5px;
                font-size: 16px;
            }

            .course-sub {
                padding: 10px 0;

                span {
                    height: 20px;
                    margin-bottom: 10px;
                }
            }

            .course-skill {
                margin-bottom: 10px;
                cursor: pointer;
                max-width: unset;
                text-transform: capitalize;
                text-align: left;
                font-size: 13px;
            }

            .course-icon {
                width: 20px;
                margin-left: auto;
                cursor: pointer;
            }
        }
    }
}

.all-notes {
    padding: 0 25px;
}

// .accomplished {
// 	padding-bottom: 50px;

// 	h3{
// 		font-weight: 400;
// 		font-size: 38px;
// 		line-height: 46px;
// 		margin-bottom: 50px;
// 	}
// }

.modal-content {
    background-color: #000;
    border-radius: 10px;
}

.skeleton-flex-container {
    justify-content: space-between;

    .remarks-container {
        background: #0e0f0f !important;
        padding: 0;

        .remarks-button-container {
            background-color: #1a1c21;
            padding: 0;
        }
    }
}

.remarks-main-container {
    // padding-bottom: 50px;
}

.remarks-modal-main-box {
    .close-modal {
        margin: 0;
        padding: 10px;
        background: #f3f3f3;
        border-radius: 5px;
        color: #000;

        svg {
            height: 30px;
            width: 30px;
        }
    }

    .notes-box {
        margin: 30px;

        span svg {
            margin-left: 0;
        }

        .remark-modal-notes-box {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 15px;
        }

        .title-line {
            margin-bottom: 10px;

            h3 {
                margin-bottom: 0;
                font-family: "Lato-Bold";
            }

            p {
                font-size: 16px;
                margin-right: 5px;
            }

            .remark-modal-instructor {
                display: flex;
                justify-content: flex-start;

                span {
                    margin-right: 5px;
                }
            }
        }

        .notes-course-box {
            border: 0.8px solid #ffffff95;
            padding: 10px 20px;
            height: calc(100vh - 435px);
            overflow: scroll;

            .notes-course-box-lesson-container {
                margin-bottom: 10px;
                min-height: 200px;
            }
        }

        .notes-hr {
            margin: 0 0 10px;
        }

        textarea.form-control {
            background-color: #1a1c21;
            color: #fff;
            border: none;
        }
    }
}

.bookmark-box,
.list-box {
    // padding-top: 25px;
    // padding-bottom: 50px;

    &.trending-box .content {
        border: none;
    }

    h3 {
        font-size: 24px;
    }

    .slick-arrow {
        width: 40px;
        height: 40px;
        top: -50px;
    }

    .slick-prev {
        right: 60px;
        left: auto !important;
    }

    .slick-next {
        right: 0 !important;
    }

    .trending-content {
        justify-content: flex-end;
    }

    .slick-track {
        align-items: flex-start;
    }

    .content {
        border-radius: 7px;
        cursor: pointer;
        // width: 300px;

        .image-container {
            // height: 200px;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

.bookmark-box .bookmark-title {
    display: flex;
    align-items: center;
}

.bookmark-box .bookmark-title p {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    opacity: 0.7;
    margin-bottom: 32px;
}

/* Notes CSS */
.all-notes {
    .slick-arrow {
        width: 40px;
        height: 40px;
        top: -50px;
    }

    .slick-prev {
        right: 60px;
        left: auto;
    }

    .slick-next {
        right: 0;
    }

    .remarks-container {
        text-align: center;
        background: #1a1c21;
        padding: 20px;
        height: 200px;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;

        .remarks-content-box {
            h6 {
                font-family: "Lato-Bold";
            }

            span {
                font-size: 14px;
            }
        }
    }

    .remarks-button-container {
        text-align: center;
        background: #7d7d7d;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;

        span {
            font-family: "Lato-Bold";
        }
    }
}

.all-notes .slick-slide {
    padding: 0 10px;
}

.all-notes .slick-slide img {
    width: 100%;
}

.all-notes h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 46px;
    // margin-bottom: 50px;
}

.all-notes p {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.7;
    // margin-bottom: 31px;
}

.all-notes .slick-dots {
    display: none !important;
}

.all-notes .slick-dots li {
    width: auto;
}

.all-notes .slick-dots li.slick-active button {
    background: #fff !important;
    width: 20px !important;
    height: 3px;
    border-radius: 8px;
}

.all-notes .slick-dots li button {
    background: rgba(255, 255, 255, 0.6) !important;
    border-radius: 50%;
    width: 6px !important;
    height: 6px !important;
    padding: 0 !important;
}

.all-notes .note-content {
    cursor: pointer;
    position: absolute;
    padding: 20px;
    top: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.all-notes .note-img {
    position: relative;
}

.note-content .cat-group {
    display: flex;
}

.note-content .cat-group .cat1 {
    margin-right: 10px;
    background: #fff5cc;
}

.note-content .cat-group .cat2 {
    background: #dad7fe;
}

.note-content .note-cat {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #000000;
    padding: 5px 8px;
    border-radius: 6px;
    width: fit-content;
    margin-bottom: 5px;
}

.note-sub .note-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 7px;
}

.note-sub .dark1 {
    color: #000000;
}

.note-sub .note-txt {
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}

.note-sub .dark-txt {
    color: #000000;
    opacity: 0.4;
}

.note-sub .green-txt {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #02aab0;
}

.notes-box .faq-blue-btn {
    margin: 0 0 0 auto;
    color: #ffffff;
}

/* Course Landing Page CSS */
.new-session {
    padding-top: 121px;
    padding-bottom: 64px;

    .video-section-left {
        width: 55%;
        max-width: 800px;
    }

    #content-section-right {
        width: 45%;
    }
}

.new-session .container-fluid {}

.new-session a.non-link {
    background: #191c21;
    border-radius: 8px;
    padding: 14px 20px 14px 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #ffffff;
    position: absolute;
    left: 28px;
    bottom: 65px;
}

.new-session img {
    width: 100%;
}

.new-session .container-fluid .row {
    align-items: center;
    justify-content: flex-end;
    padding: 35px 0 0;
    // height: calc(100vh - 180px);
    // min-height: 900px;
}

.video-section-left {
    border-radius: 10px;
    overflow: hidden;
    // position: absolute;
    left: 0;
    // top: 50%;
    padding: unset;
    // height: 100%;
    // transform: translateY(-50%)
    // .dyntube-player.dyt-fluid {
    // 	height: calc(100vh - 180px);
    // 	object-fit: cover;
    // }
}

.session-content {
    text-align: center;
    padding: 70px 65px;

    .button-loader-container {
        width: 210px;
        margin: 0 auto;
    }
}

.session-content .new-wise {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 0;
}

.session-content h4 {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 15px;
}

.session-content h3 {
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.session-content .hr1 {
    width: 16.42px;
    height: 2.76px;
    background: #ffffff;
    opacity: 0.7;
    margin: 10px auto;
}

.session-content .name1 {
    // margin-bottom: 25px;
    // text-decoration: underline;
    // text-underline-offset: 5px;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    text-transform: uppercase;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto 25px;
    display: block;
    position: relative;
    transition: all 0.25s ease-out;

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #fff;
        transform-origin: bottom;
        transition: all 0.25s ease-out;
    }

    &:hover {
        &::after {
            // transform: scaleX(1);
            left: -5%;
            width: 110%;
            transform-origin: bottom;
        }
    }
}

.session-content .sign-btn {
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    background-color: #5fc4b8;
    border-radius: 8px;
    border-width: 0;
    padding: 12px 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;
    transition: all 0.4s ease-in-out 0s;
    // outline: 2px solid #5fc4b8;
    outline-offset: 5px;

    svg {
        margin-left: 5px;
        transition: all 0.1s ease-out;
    }

    &:hover {
        // box-shadow: rgb(84, 184, 141) 0px 2px 10px 0px;
        transition: all 0.2s ease-in;
        color: #fff;
        outline: 4px solid #5fc4b8;

        svg {
            transform: translateX(10px) scale(1.1);
            transition: all 0.1s ease-in;
        }
    }
}

.session-content .deal-txt {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 11px;
}

.session-content .offer-txt {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.session-content .hr2:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 28px;
    line-height: 1.5em;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    background-color: #000;
}

.session-content .hr2:before {
    content: "";
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
}

.session-content .hr2 {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: transparent;
    text-align: center;
    height: 1.5em;
    opacity: 1;
    margin: 37px 0 44px;
}

.overview {
    background: linear-gradient(180deg, #202020, transparent);
    padding: 10px 0;

    .overview-content-main-left {
        border-right: 1px solid rgba(168, 171, 193, 0.3);
        width: 65%;

        small {
            font-size: 32px;
        }

        h3 {
            font-size: 50px;
            margin-bottom: 30px;
        }

        .overview-content {
            ul {

                // padding-left: 0;
                li {
                    font-size: 19px;
                    line-height: 1.6;
                    margin-bottom: 14px;
                    position: relative;

                    &::before {
                        color: #fff;
                        content: "• ";
                        font-weight: 600;
                        height: 8px;
                        width: 8px;
                        margin: 0px 16px 0px 0px;
                        position: absolute;
                        left: -20px;
                    }
                }
            }
        }
    }

    .overview-content-main-right {
        width: 35%;

        .meta-box {
            margin-bottom: 0;
            justify-content: flex-start;

            li {
                width: 45%;
            }
        }
    }
}

.meta-box {
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;

    li svg {
        color: #bababa;
        font-size: 30px;
    }
}

.meta-box .meta1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 15px;
    text-align: center;

    span {
        display: none;
    }
}

.meta-box .meta2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    font-family: "latolight";
}

.structure {
    padding: 30px 0;
    background: linear-gradient(0deg, #202020, transparent);
}

.meta-info {
    background: #191c21;
    border-radius: 6px;
    padding: 55px 60px;
    display: flex;
    width: 100%;
    margin: 30px auto 40px;
    align-items: center;
    justify-content: center;
}

.meta-icon {
    margin-right: 25px;
    width: 80px;
}

.meta-info img {
    margin-right: 24px;
}

.meta-info .meta-content h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.meta-info .meta-content p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0;
}

.section-mb {
    padding-bottom: 120px;
}

.trending-box .session-other {
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;

    .discover-tabs {
        justify-content: flex-end;
    }
}

.trending-box .session-other h3 {
    // margin-top: -38px;
}

.curriculam-box {
    // background: linear-gradient(180deg, #202020, transparent);
    padding-top: 30px;
}

.curriculum-title,
.instructor-course-container-title {
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    margin-bottom: 50px;
}

.curriculum-content {
    // background: #191C21;
    border-radius: 6px;
    // padding: 70px 113px;
    padding: 0 10px;
    text-align: center;

    .accordion-flush {
        margin-bottom: 50px;
    }

    .btn-branded {
        display: inline-block;
        font-size: 14px;
        font-family: "latoregular";
        text-transform: capitalize;
        padding: 10px 20px;
        letter-spacing: 0.5px;

        .button-loader {
            width: 70px;
            height: 20px;
        }
    }
}

.curriculum-content .accordion-body {
    padding: 16px 36px 0;
    // .topic-box{
    // 	margin-bottom: 0;
    // }
}

.curriculum-content .accordion-item:last-of-type {
    padding-bottom: 0;
}

.curriculum-content .accordion-button {
    font-weight: 900;
    font-size: 18px;
    line-height: 29px;
    color: #ffffff;
    padding: 0;
    background: transparent;
}

.curriculum-content .accordion-button::after {
    background-image: url("../images/image-svg.svg");
}

.curriculum-content .accordion-button:focus {
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}

.curriculum-content .accordion-header span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    width: 30px;
    height: 30px;
    transform: translateX(-15px);
    border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
}

.curriculum-content .accordion-item {
    background-color: transparent;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-left: 1px solid #02aab0;
    padding-bottom: 70px;

    .accordion-header .accordion-button h4 {
        margin-bottom: 0;
    }
}

.curriculum-content .icon-image-container {
    width: 20px;
}

.topic-box img.topic-img {
    height: 100%;
    // margin-right: 42px;
}

.topic-box {
    // margin: 0 0 26px 28px;
    // height: 200px;
    // overflow: hidden;
    background: #191c21;
    border-radius: 8px;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    cursor: pointer;

    &:hover {
        .topic-box-head-container {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease-in-out;
            }

            .resume-icon {
                opacity: 1;
                transition: all 0.3s ease-in-out;
            }
        }
    }

    .topic-box-head-container {
        width: 320px;
        position: relative;

        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: #00000075;
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }

        .resume-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }

        .image-container {
            // object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
        }

        .topic-box-head-container-content {
            display: none;
            text-align: left;
        }
    }

    .topic-sub {
        width: 65%;
        padding: 25px;
        text-align: left;
    }
}

.topic-box .topic-txt {
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    background-color: #000;
    color: #fff;
    margin-bottom: 5px;
    // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
}

.topic-box h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    // color: #000000;
    margin-bottom: 10px;
}

.topic-box .topic-meta {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.topic-box .topic-meta img {
    margin-left: 9px;
}

.topic-box .topic-txt1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    // color: #000000;
}

.topic-box .image-container {
    // width: 30%;
    width: 250px;
}

// Course detail
.course-detail-wrapper {
    margin: 20px;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    justify-content: space-between;
    gap: 30px;
}

.instructor-content-box {
    max-width: fit-content;

    .learn-more {
        font-size: 16px;
        cursor: pointer;
        // text-decoration: underline;

        svg {
            margin-left: 3px;
            transition: all 0.3s ease-in;
        }

        &:hover {
            color: #02aab0;

            svg {
                transition: all 0.3s ease-in;
                transform: scale(1.1) translateX(2px);
            }
        }
    }
}

.instructor-tab {
    background-color: #191c21;
    border-radius: 5px;
    padding: 20px 25px;
}

.instructor-tab-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    span {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
    }
}

.instructor-pic-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.instructor-image-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 25px;
}

.course-detail-head-container {
    height: fit-content;
    min-height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.course-detail-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: top;
    gap: 10px;
    // margin-top: 30px;

    >div>p {
        color: rgb(99, 99, 99);
        text-transform: uppercase;
        font-size: 15px;

        >span {
            font-size: 11px;
        }
    }
}

.about-instructor {

    // width: 80%;
    h3 {
        text-decoration: underline #ffff;
        margin-bottom: 10px;
        text-underline-position: under;
    }
}

.course-detail-head-img {
    position: relative;
    width: 100%;
    // margin-bottom: 15px;

    img {
        width: 100%;
        border-radius: 8px;
        margin-top: 20px;
    }
}

.detail-head-btns {
    display: inline;

    span {
        margin-right: 10px;
    }

    >button {
        border: none;
        padding: 0px 16px;
    }
}

.head-btn1,
.head-btn2 {
    background-color: #171717;
    color: #fff;
}

.detail-head-btns {
    .active {
        background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    }
}

.head-btn1 {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.head-btn2 {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.course-play-btn1,
.course-play-btn2 {
    position: absolute;
    padding: 10px 12px;
    border-radius: 50%;
    cursor: pointer;
}

.course-play-btn1 {
    bottom: 20px;
    left: 30px;
    background-color: #00000090;
}

.course-play-btn2 {
    top: 30px;
    right: 10px;
    background-color: #fff;
    color: #000;
}

.course-tabs {
    margin: 30px 20px;

    .course-details-lesson-nav-head,
    .course-details-lesson-nav-body {
        display: none;
    }

    .nav-pills {
        display: flex;
        gap: 30px;

        .nav-item {
            a {
                padding: 0;
                color: #fff;
                cursor: pointer;
                font-weight: 600;
            }

            .nav-link.active {
                text-decoration: underline;
                background: none;
                text-decoration-color: #00cdac;
                text-decoration-style: solid;
            }
        }
    }

    .col {
        padding: 0;
    }

    .tab-content {
        padding: 20px 0px;

        .certificate-card-view {
            // padding: 20px;
            // background: #1a1c21;
            border-radius: 4px;

            .my-certy {
                position: relative;
                border: 1px solid #5f1712;
                border-radius: 4px;
                overflow: hidden;

                img {
                    filter: blur(1px);
                }

                .view-btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    button {
                        font-weight: 700;
                        font-size: 20px;
                        color: #000000;
                        border-radius: 6px;
                        padding: 0;
                        border-color: #5fc4b8;
                        letter-spacing: 1px;
                        background: #5fc4b8;
                        transition: background-color 0.5s ease, color 0.5s ease,
                            box-shadow 0.5s ease;
                        height: 50px;
                        min-width: 100px;
                    }
                }
            }
        }
    }
}

.certificate-modal {
    &.modal-dialog {
        margin: 0 auto;
        position: fixed;
        top: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 111111;
        height: 100vh !important;
        align-items: unset !important;
    }
}

#certificate_front {
    /* height: 303mm; */
    // padding: 50px;s
    height: calc(100vh - 95px);
    letter-spacing: 5px;
    background: #000;

    div.container {
        border: 3px solid #5f1712;
        border-spacing: 25px;
        border-collapse: separate;
        height: 100%;
        padding: 50px;
        position: relative;
        max-width: unset;

        h1 {
            width: 90px;
        }

        div.top {
            h2 {
                font-size: 35px;
                margin: 40px 0 20px;
            }

            .user-content h3 {
                font-size: 32px;
                margin-bottom: 20px;
            }

            .course-content h5 {
                font-size: 22px;
                margin-bottom: 10px;
            }
        }

        p {
            font-size: 18px;
            color: #ffffff78;
            margin-bottom: 20px;
        }

        div.bottom {
            position: absolute;
            bottom: 60px;
            left: 60px;
            width: 100%;

            div.signature-container {
                p {
                    margin-bottom: 0;
                }

                .signature-image-container {
                    width: 120px;
                    margin: 30px 0 15px;
                    filter: contrast(0.4);
                }
            }

            .seal-container {
                position: absolute;
                right: 120px;
                bottom: 0;

                p {
                    margin-bottom: 0;
                    text-align: center;
                }

                .seal-image-container {
                    /* filter: invert(1); */
                    margin: 30px 0 0;
                    width: 180px;
                }
            }
        }

        .certificate-modal-button {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(to top, #656565, #898989);
            border: none;
            color: #fff;
            font-size: 16px;
            padding: 15px 20px;
            border-radius: 8px;
            width: fit-content;
            letter-spacing: 2px;
            /* Add transition properties for animation */
            transition: transform 0.3s ease-in-out;
            cursor: pointer;
        }

        /* Add hover style for animation effect */
        .certificate-modal-button:hover {
            transform: translateX(-50%) translateY(-5px);
        }
    }
}

.resources {
    >div h4 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }
}

.resources-documents {
    margin-bottom: 30px;

    p {
        svg {
            cursor: pointer;
            margin-right: 10px;
        }
    }
}

.resources-links {
    >div {
        margin-left: 30px;

        li {
            padding-bottom: 10px;
            cursor: pointer;
        }
    }
}

.course-tab-qna-section {
    .input-group .input-group-text {
        max-height: 50px;
        min-width: 100px;
        padding: 15px 20px;
        justify-content: center;
    }

    .qna-discussion {
        margin: 10px 0;

        .discussion-card {
            margin-bottom: 10px;
            padding: 30px;

            .discussion-card-info {
                margin: 0 0 10px;
                gap: 10px;

                h5 {
                    margin-bottom: 0;
                }

                span {
                    background-color: unset;
                    padding: 0;
                    color: #fff;
                }
            }

            .discussion-card-info2 {
                .discussion-wrapper {
                    grid-template-columns: 1fr;

                    .repiles-info {
                        .repiles-card {
                            .discussion-details-card-info {
                                h5 {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                .replies-comment {
                    padding-left: 0;

                    .quizTextarea {
                        margin-bottom: 10px;
                        height: 100px;
                        margin-top: 10px;
                    }

                    button {
                        background: linear-gradient(to top, #02aab0, #00cdac);
                        border: none;
                        color: #fff;
                        font-size: 16px;
                        border-radius: 8px;
                        max-height: 50px;
                        min-width: 100px;
                        text-transform: uppercase;

                        span {
                            padding: 13px 20px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .qna-btn-container {
        .btn-branded {
            margin: 10px auto 0;
            min-width: 200px;
        }

        .no-pad {
            padding: 0;
        }
    }
}

.course-tab-qanda {
    background: #191c21;
    padding: 15px 20px;
    border-radius: 12px;

    .input-group {
        background: #fff;
        padding: 10px;
        border-radius: 6px;

        input {
            border: none;
        }

        .input-group-text {
            // padding: 5px 20px;
            // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
            background-color: #5fc4b8;
            border: none;
            font-weight: 700;
            border-radius: 8px !important;
            cursor: pointer;
        }
    }
}

.tab-notes {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    background-color: #fff;
}

.tab-notes-left {
    width: 100%;
}

.notes-detailcard {
    background: #191c21;
    padding: 20px;
    color: #fff;
    margin-top: 10px;

    h2 {
        font-size: 24px;
    }
}

.notes-content {
    border: 1px solid #636363;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;

    p {
        font-size: 15px;
        margin-bottom: 0;
    }
}

.notes-content-head {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    span {
        margin-right: 10px;
    }

    h3 {
        font-size: 18px;
    }
}

.course-note-form {
    // width: 55%;
    height: fit-content;
    background-color: #fff;
    border-radius: 4px;
    color: #000;

    h5 {
        color: #000;
        margin: 10px 0 0 15px;
    }

    p {
        padding-top: 10px;
        padding-left: 20px;

        svg {
            margin-right: 10px;
        }

        .form-control {
            display: inline-block;
            border: none;
            width: 90%;
        }
    }

    textarea {
        border: none;
    }

    svg {
        color: #334d6e;
    }

    .form-control:disabled {
        background-color: #fff;
    }
}

.course-note-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 0;

    h5 {
        margin: 0;
    }

    span {
        svg {
            color: #02aab0;
        }
    }

    .course-note-title-lock {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
            margin-right: 5px;
        }
    }
}

.course-about-instructor {
    width: 40%;
    background-color: #191c21;
    padding: 20px;

    a {
        color: #fff;
        text-decoration: underline;
    }
}

.course-about-instructor-info {
    display: flex;
    gap: 30px;
    align-items: center;
    margin: 20px 0px;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    div {
        h3 {
            font-size: 18px;
        }

        p {
            margin-bottom: 0px;
            color: #d1d1d1;
            font-family: "latolight";
        }
    }
}

// Privacy Policy page css
.terms-and-conditions,
.privacy-policy,
.cancellation-refund,
.about-us,
.delete-my-account,
.career {
    .hero-box .row {
        justify-content: center;

        h3 {
            margin-bottom: 40px;
            font-weight: 400;
            font-size: 64px;
            line-height: 77px;
        }

        p {
            // font-family: 'latoregular';
            font-size: 20px;
            margin-bottom: 30px;

            b {
                font-family: "Lato-Bold";
            }
        }

        ul {
            list-style: disc;
        }

        ol {
            margin: 60px 0;

            li {
                font-size: 20px;
                margin-bottom: 20px;

                &::marker {
                    font-weight: bold;
                }

                h6 {
                    font-size: 20px;
                    margin: 30px 0;
                    font-family: "Lato-Bold";
                    text-transform: uppercase;
                }

                span {
                    display: block;
                }
            }
        }
    }
}

.career {
    h3 {
        margin-top: 30px;
    }

    ul {
        list-style: circle;
        text-align: left;

        li {
            font-size: 20px;
            margin-bottom: 30px;
        }
    }
}

.delete-my-account .hero-box .row ol {
    margin: 0;
}

/* About us page CSS */
.weare-box {
    padding-top: 120px;
}

.weare-box h3 {
    font-weight: 900;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
}

.weare-box .logo-with-txt {
    margin-bottom: 51px;
}

.weare-box p {
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;
    font-family: "latolight";
    letter-spacing: 0.02em;
}

.weare-box .video-box img {
    cursor: pointer;
}

.weare-box .logo-image-container {
    width: 500px;
}

.welive-box {
    padding-top: 120px;
}

.welive-box h3 {
    font-weight: 400;
    font-size: 46px;
    line-height: 58px;
    text-align: center;
    margin-bottom: 80px;
}

.welive-box .icon-box {
    background: #191c21;
    border-radius: 12px;
    padding: 45px 32px 34px;
    text-align: center;
}

.welive-box .icon-box img {
    margin-bottom: 20px;
}

.welive-box .icon-box h4 {
    font-weight: 300;
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 20px;
}

.welive-box .icon-box p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
}

.welive-box .icon-image-container {
    width: 50px;
    margin: 0 auto;
}

.team-box {
    padding-top: 120px;
    padding-bottom: 111px;
}

.team-box h3 {
    font-weight: 400;
    font-size: 38px;
    line-height: 58px;
    margin-bottom: 49px;
}

.team-box .slick-slide img {
    width: 100%;
    margin: 0 auto;
}

/* === Hover Effect Start === */
.team-box .content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
}

.team-box .content .content-overlay {
    background: linear-gradient(180deg,
            rgba(25, 28, 33, 0) 61.54%,
            #191c21 96.32%);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.team-box .content:hover .content-overlay {
    opacity: 1;
}

.team-box .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    padding: 24px 39px;
    -webkit-transform: translate(-50%, 25%);
    -moz-transform: translate(-50%, 25%);
    transform: translate(-50%, 25%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.team-box .content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.team-box .fadeIn-bottom {
    top: 80%;
}

/* === Hover Effect end=== */
.team-box .slick-prev,
.team-box .slick-next {
    width: 62px;
    height: 62px;
    background: #191c21;
    z-index: 9;
    border-radius: 50%;
}

.team-box .slick-prev:hover,
.team-box .slick-prev:focus,
.team-box .slick-next:hover,
.team-box .slick-next:focus {
    background: #02aab0;
}

.team-box .slick-prev {
    left: -34px;
}

.team-box .slick-next {
    right: -34px;
}

.team-box .slick-prev:before {
    background-image: url("../images/prev-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.team-box .slick-next:before {
    background-image: url("../images/nxt-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: transparent;
}

.team-box .team-title {
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    text-transform: uppercase;
}

.team-box .team-hr {
    width: 22.58px;
    height: 3px;
    margin: 12px auto;
    background: #ffffff;
    opacity: 0.7;
    text-align: center;
}

.team-box .team-txt {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
}

//mobile-category
.mobile-category {
    padding: 10px 20px;
    background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
    border-radius: 6px;
    margin-bottom: 30px;

    svg {
        margin-right: 10px;
    }
}

.dropdown {
    button::after {
        display: none;
    }
}

.mobile-category-links {
    background: #191c21;
    width: auto;
    margin-top: 10px;

    a {
        color: #fff;
        font-weight: 400;
    }
}

.dropdown-menu a:hover {
    text-decoration: none;
    color: #fff;
}

.slick-track {
    display: flex !important;
    align-items: center;
}

// .modal {
// 	top: 90px;
// }

.modal.show .modal-dialog {
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
}

.FlagBox {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    // overflow: scroll;
    padding: 7px 15px;

    align-items: center;
    border-radius: 8px;
}

.signup-box {
    span {
        // height: 35px;
        color: red;
        display: inline-block;
    }

    .FlagBox {
        position: relative;

        .num {
            margin-right: 10px;
        }
    }
}

.FlagImg {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    // margin: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        width: 100%;
        display: block;
        object-fit: cover;
    }
}

.num {
    color: rgb(0, 0, 0);
    font-size: 17px;
    margin-left: 10px;
}

.overlay {
    position: absolute;
    left: -1px;
    // background: #212529;
    background: hsl(220, calc(var(--saturation-factor, 1) * 7.7%), 22.9%);
    border-radius: 7px;
    width: 90%;
    padding: 15px;
    top: 0;

    overflow: hidden;
    // height: 420px;
}

.label-enter-otp {
    display: flex;
    justify-content: space-between;

    small {
        color: #00cdac;
        cursor: pointer;
    }
}

.modalcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 12px 12px;
    margin-top: 10px;
    cursor: pointer;
    color: rgb(255, 255, 255);

    span {
        margin-right: 5px;
        margin-left: 14px;
        color: #fff;
        font-size: 20px;
    }
}

.box-shadow {
    overflow: scroll;
    // height: 260px;
}

.namebox {
    display: flex;
    align-items: center;
}

.Search {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: row-reverse;
    // padding: 7px 15px;

    // border: 1px solid rgb(218, 227, 237);
    border-radius: 21px;
    border: 1px solid;
    position: relative;

    img {
        width: 100%;
        display: block;
    }
}

.searchimg {
    width: 18px;
    margin-right: 12px;
    display: block;
}

.formsearch {
    width: 100%;
}

.formsearch [type="text"] {
    width: 100%;
    padding: 12px 12px;
    background: transparent;
    font-size: 18px;
    color: #fff;
}

.nav-item {
    :active {
        opacity: 1;
    }
}

.signup-box .overlay {
    // height: 300px;
    top: 65px;
    left: 0;
    width: 100%;
}

.instructor-info-images .instructor-img {
    img {
        width: 100%;
        display: block;
        height: unset;
    }
}

.instructor-icons-info .icon-packs {
    display: flex;
    align-items: center;

    p {
        margin-bottom: unset;
        margin-left: 5px;
    }
}

.contains {
    display: none;
}

.contain-head {
    display: none;
}

.nav-item .faq-btn {
    display: none;
}

.profileImg {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;

    h6 {
        margin: 0 10px 0 0;
    }

    .profile-image-container {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        .default-image {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #fff;
            // padding: 20px;
            border-radius: 50%;

            svg {
                margin-right: 0;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .faq-btn {
        margin-left: 10px;
    }
}

.helloworld {
    width: 100vh;
    height: 100vh;
    background-color: rebeccapurple;
}

.dyt-brand-logo {
    width: 80px !important;
    top: 5px !important;
}

.borderwhite {
    background: hsla(217,
            calc(var(--saturation-factor, 1) * 7.6%),
            33.5%,
            0.48);
    width: 100%;
    height: 1px;
    border-top: thin solid var(--background-modifier-accent);
}

.nav-item {
    h5 {
        display: none;
    }
}

.notificationbox {
    display: flex;
    // gap: 20px;
    align-items: center;
    justify-content: end;
    // width: 270px;
    .border-line{
        border: 1px solid #fff;
        height: 24px;
        margin-right: 15px;
    }
}

.wise-craft{
    display: flex;
    margin-left: 15px;
   .border-line{
        border: 1px solid #fff;
        height: 24px;
    }
}

.notificationbox .nav-item {
    // h5{
    // 	padding:10px
    // }
}

.notifications {
    display: flex;
    gap: 10px;
    align-items: center;
}

.nav-item icon has-badge {
    border-bottom: unset;
}

//  .selected-plan  .plan-price  {
// 	margin-top: 25px;
// }
// .selected-plan .plan-table td {
// 	padding: 35px 0;
// }

.accordion-body {
    p {
        font-size: 14px;
    }
}

.course-box-course-name-title {
    h3 {
        font-size: 20px;
        text-decoration: none;
        margin-bottom: 0;
    }
}

// Course Landing New section

.section-course-detail {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.course-detail-bottom-left {
    width: 55%;
    flex: 1 1;
}

.course-detail-bottom-right {
    width: 45%;

    .module-infocard {
        margin-top: unset;
    }
}

.course-detail-hero-container {
    display: flex;
    justify-content: space-between;

    .course-detail-video-player .complete-button {
        padding: 10px 20px;
        background: linear-gradient(122.17deg, #02aab0 0%, #00cdac 100%);
        border-radius: 6px;
        // margin-bottom: 15px;
        width: fit-content;
        // align-self: flex-end;
        cursor: pointer;
    }
}

.course-detail-hero-main-container {
    margin: 135px 0 20px;
    position: relative;
}

.course-detail-hero-wrapper {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    height: calc(100vh - 160px);
    overflow: hidden;
    // height: auto;

    section.module {
        width: 45%;
        height: 100%;
        overflow-y: scroll;

        .module-container {
            max-width: unset;
        }
    }
}

.course-detail-video-player {
    width: 55%;
    flex: 1;

    .course-detail-head-img {
        min-height: 370px;
    }
}

// .course-completion-container,
// .course-details-container,
// .topic-quiz-container {
// 	margin: 60px 0px;
// }

// .course-detail-container {
// 	margin: 135px 0 60px;
// 	position: relative;
// }

// .course-detail-container .module-lesson-content-complete {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: flex-start;
// 	justify-content: flex-start;
// 	gap: 0px;

// 	p {
// 		color: #d1d1d1;
// 		font-size: 16px;
// 	}
// }

// .module-lesson-bookmark-complete{
// 	width: 100%;
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	margin-bottom: 15px;
// }

// .module-lesson-bookmark-button{
// 	background: rgb(72, 72, 72);
// 	// width: 100px;
// 	padding: 10px 20px;
// 	border-radius: 5px;
// 	// text-align: center;
// 	display: flex;
// 	justify-content: space-between;
// 	svg{
// 		cursor: pointer;
// 		font-size: 22px;
// 		margin-right: 10px;
// 	}
// 	p{
// 		margin-bottom: 0;
// 	}
// }

// .completed-button{
// 	color: #888888;
// 	padding: 10px 20px;
// 	border: 1px solid #02AAB0;
// 	border-style: dashed;
// 	border-radius: 6px;
// 	// margin-bottom: 15px;
// 	width: fit-content;
// 	align-self: flex-end;
// 	cursor: default;
// }

// .lesson-complete-modal-overlay {
// 	background: #000000;
// 	opacity: 0.65;
// 	height: 100vh;
// 	width: 100vw;
// }

// .lesson-complete-modal {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	z-index: 111111112;
// 	// height: 100vh;
// 	// width: 100vw;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	// background: red;
// }

// .lesson-complete-modal-container {
// 	position: absolute;
// 	background-color: #3b3b3b;
// 	// height: 200px;
// 	width: 40%;
// 	border-radius: 15px;
// 	padding: 20px;

// 	.image-container {
// 		display: flex;
// 		justify-content: flex-end;
// 		cursor: pointer;
// 	}

// 	.lesson-complete-modal-content {
// 		display: flex;
// 		flex-direction: column;
// 		justify-content: center;
// 		align-items: center;

// 		p {
// 			font-size: 18px;
// 			font-weight: bold;
// 		}
// 	}

// 	.buttons {
// 		span {
// 			padding: 10px 20px;
// 			background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
// 			border-radius: 6px;
// 			margin-bottom: 15px;
// 			margin-right: 15px;
// 			width: fit-content;
// 			cursor: pointer;
// 			display: inline-block;
// 		}

// 		.no {
// 			background: none;
// 			border: 1px solid #fff;
// 		}
// 	}
// }

// .course-completion-wrapper {
// 	background-color: #191C21;
// 	margin: 20px;
// }

// .course-completion {
// 	padding: 30px;
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// 	gap: 30px;
// 	justify-content: space-between;

// 	img {
// 		border-radius: 12px;
// 		width: 100%;
// 	}
// }

// .course-completion-info {
// 	h3 {
// 		margin-bottom: 20px;
// 	}

// 	a {
// 		display: block;
// 		color: #00CDAC;
// 		margin-top: 30px;

// 		svg {
// 			margin-right: 10px;
// 		}
// 	}
// }

// .course-completion-btns {
// 	display: flex;
// 	gap: 20px;
// 	margin-top: 20px;

// 	button {
// 		background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
// 		border: none;
// 		padding: 5px 20px;
// 		border-radius: 8px;
// 		font-weight: 600;

// 		svg {
// 			margin-right: 10px;
// 		}
// 	}
// }

// .course-hr {
// 	width: 100%;
// 	border: 1px solid #585858;
// 	margin: 90px 0px;
// }

// .faq-wrap {
// 	max-width: 70ch;
// 	margin: 0 auto;

// 	h2 {
// 		margin-bottom: 30px;
// 		text-align: center;

// 	}
// }

// .page-breadcrumbs {
// 	background-color: #191C21;
// 	margin: 20px;
// 	padding: 30px;
// 	display: flex;

// 	svg {
// 		color: #fff;
// 		margin-right: 20px;
// 	}
// }

// .breadcrumbs-links {
// 	display: flex;
// 	flex-wrap: wrap;
// 	gap: 10px;
// 	align-items: center;

// 	a {
// 		color: #9d9d9d;
// 		font-size: 18px;
// 		font-weight: 600;
// 	}

// 	:hover,
// 	:active {
// 		color: #fff;
// 	}
// }
.course-detail-hero-container {
    .faq-wrap {
        max-width: unset;
        margin: 0 auto;
    }

    // h2{
    // 	display: none;
    // }
    .faq-page {
        padding-top: 50px;
    }

    .course-detail-head {
        h3 {
            // font-size: 18px;
        }
    }
}

// .container-width   {

// 	max-width: unset !important	;
// }
.notifications .nav-item {
    border: none;
}

.search-icon-container{
    .border-line{
        border: 1px solid #fff;
    }
}

.join-membership {
    .border-line{
        border: 1px solid #fff;
    }
    .nav-items {
        a {
            padding: 0 15px;
        }
    }
}

.callback {
    background-color: #000;
    // z-index: 1050;
    // padding-top: 118px;
    // padding-bottom: 120px;

    .card {
        background-color: #2e2e2e;
        text-align: center;
        width: 460px;
        margin: 0 auto;
        padding: 30px;
        border: 2px solid #5e5e5e;
        border-radius: 8px;

        .image-container {
            width: 60px;
            height: 60px;
            margin: 0 auto;
        }

        .head-box {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            .success-loader {
                width: unset;

                div {
                    height: 30px !important;
                    width: 30px !important;
                }
            }

            h1 {
                font-size: 18px;
                margin: 0;
            }
        }

        ul {
            padding: 0;

            li {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 0;
                    font-size: 14px;
                }
            }
        }

        h6 {
            margin: 15px 0;
            font-size: 14px;
        }

        .button-box {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .simple-btn {
                padding: 5px 10px;
                border-radius: 4px;
                border: 1px solid #ccc;
                cursor: pointer;
                width: 150px;
                background-color: #585858;

                &:hover {
                    color: #fff;
                    background-color: #404040;
                }

                svg {
                    background-color: unset;
                    margin: 0 10px 0 0;
                }
            }

            :last-child {

                // margin-left: 15px;
                // background-color: #585858;
                &:hover {
                    background-color: #6d6d6d;
                }

                svg {
                    background-color: unset;
                    margin: 0 0 0 8px;
                }
            }
        }

        .query {
            font-size: 12px;
            margin: 10px auto;
            width: 80%;
            line-height: 15px;
        }
    }
}

.maintenance {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    .maintenance-main-container {
        width: 40%;
        margin: 0 auto;

        a {
            text-decoration: underline;
            text-underline-offset: 3px;
        }

        p a {
            transition: all 0.25s ease-in-out;
            color: #5fc4b8;

            &:hover {
                transition: all 0.25s ease-in-out;
                color: orange;
            }
        }

        span {
            font-size: 18px;
        }

        .maintenance-image-container {
            width: 40%;
            margin: 0 auto 10px;
        }
    }
}

// Scrollbar Privacy Policy
.style-scrollbar {
    position: fixed;
    top: 50%;
    right: 15px;
    width: 4px;
    height: 62px;
    pointer-events: none;
    transform: translateY(-50%);
    overflow: hidden;
    border-radius: 2px;
    background: #565656;

    .style-thumb {
        width: 4px;
        height: 12px;
        border-radius: 2px;
        background: #fff;
    }
}

.checkout-box {
    // height: 100vh;
    background: #191c21;
    color: #fff;
    z-index: 1050;
    position: relative;
    padding: 0;
    overflow-y: scroll;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-height: 100vh;

    input,
    select {
        background-color: #2b2b2b;
        color: #fff;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
        width: 20px;
        height: 20px;
        filter: invert(1);
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #2b2b2b;
        background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 97%;
        background-position-y: 50%;
        cursor: pointer;
    }

    label {
        margin-bottom: 10px;
        // cursor: pointer;
    }

    .checkout-logo {
        justify-content: flex-start;
        align-items: baseline;
        padding: 30px 27px;

        .checkout-logo-box {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            // font-family: 'latoregular';
            width: fit-content;
            padding: 0 10px 0 0;

            span {
                font-size: 18px;
            }
        }

        .logo-image {
            width: 220px;
            // padding: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // margin-right: 10px;
            cursor: pointer;
        }

        h4 {
            margin-bottom: 0;
            font-size: 22px;
        }
    }

    .checkout-left,
    .checkout-left-order-summary {
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        padding: 30px 27px;

        .image-container {
            width: fit-content;

            img {
                filter: invert(1);
            }
        }

        .back-button {
            width: fit-content;
            color: #fff;
            // border-right: 1px solid;
            margin-right: 12px;
            padding: 0 12px 0 0;
        }

        h6 {
            color: grey;
            letter-spacing: 1.5px;
            font-size: 26px;
            margin-bottom: 0;
            cursor: pointer;

            &:last-child {
                cursor: default;
            }

            // &:first-child{
            // 	border-left: 1px solid #fff;
            // }
            &.active {
                color: #00c1ab;
            }
        }
    }

    h4,
    h6 {
        // color: #000;
        width: fit-content;
        padding: 0;
    }

    .checkout-inner-box {
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding: 0 30px;

        .hl {
            display: none;
            width: -webkit-fill-available;
        }

        .left-col {
            // border-right: 1px solid #535559;

            .checkout-header,
            .checkout-footer {
                .checkout-header-title {
                    padding: 0 12px 10px;
                }

                .checkout-footer-head {
                    .checkout-checkbox {
                        label {
                            margin-bottom: 0;
                        }
                    }
                }

                .checkout-header-box {
                    align-items: flex-start;

                    h4 {
                        font-size: 20px;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 16px;
                    }
                }

                .accordion {
                    .card {
                        background-color: #000;
                        margin-bottom: 30px;
                        border-radius: 8px;

                        .card-body {
                            padding: 30px;
                        }

                        .checkout-form {
                            padding: 20px;
                            border: 1px solid #535559;
                            border-radius: 8px;
                            margin-bottom: 30px;

                            .form-inputs {
                                background-color: none;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        .normal-box {
                            padding: 20px;
                            border: 1px solid #535559;
                            border-radius: 8px;
                            margin-bottom: 20px;

                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }

                .change-button {
                    display: flex;
                    width: fit-content;
                    margin-left: auto !important;
                    align-self: flex-end;
                    flex-wrap: nowrap;
                    cursor: pointer;
                    color: #00beae;
                    transition: all 0.3s ease;

                    svg {
                        width: 16px;
                        -webkit-width: fit-content;
                        display: inline-block;
                    }

                    &:hover {
                        transition: all 0.3s ease;
                        color: #fff;
                    }
                }

                .checkout-form {
                    padding: 30px 0 0;

                    .dropdown-field {
                        background: #2b2b2b;
                        cursor: pointer;
                    }
                }

                #address {
                    background: #2b2b2b;
                }

                .long-input {
                    width: 100%;

                    &.input-info input:disabled {
                        background-color: #1e1e1e;
                        border: 1px solid #313131;
                    }
                }

                input:disabled {
                    background-color: #1e1e1e;
                    border: 1px solid #313131;
                }

                #gst {
                    text-transform: uppercase;
                }

                #phone {
                    color: #9c9c9c;
                }

                .checkout-checkbox {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: fit-content;

                    input[type="checkbox"] {
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }

                .payment-button {
                    text-align: right;

                    button {
                        background-color: #02b8af;
                        padding: 10px 32px 10px 32px;
                        border-radius: 6px;
                        color: #fff;
                        font-family: "latoregular";
                        outline: none;
                        border: none;
                        margin-left: auto;
                    }

                    .loader-button {
                        padding: 0;
                        width: 178px;

                        .button-loader {
                            height: 44px;
                        }
                    }
                }
            }
        }

        .vl {
            border-right: 1px solid #535559;
            flex: 0;
            align-self: stretch;
            padding: 0;
        }

        .order-summary {
            padding: 30px 30px 0;

            .checkout-left-order-summary {
                display: none;
            }

            .title {
                margin-bottom: 24px;
            }

            .order-header {
                // display: flex;
                // justify-content: space-between;
                // align-items: flex-start;
                background: linear-gradient(0deg, #02b8af40, #02b8af40),
                    linear-gradient(0deg, #02b8af10, #02b8af10);
                padding: 10px 16px 10px 16px;
                border: 1px solid #02b8af66;
                border-radius: 8px;
                color: #02b8af;

                h5 {
                    color: #02b8af;
                }

                .order-header-plan {
                    // width: 80%;

                    .order-header-plan-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .order-header-plan-content-container {
                        // width: 80%;
                    }

                    small {
                        text-decoration: line-through;
                        margin-right: 10px;
                    }
                }
            }

            .order-mid-container {
                border: 1px solid #02b8af40;
                padding: 16px;
                border-radius: 8px;
                color: #98a2b3;

                .order-benefit-container {
                    display: flex;
                    align-items: baseline;

                    svg {
                        margin-right: 15px;
                    }

                    p {
                        font-size: 18px;
                    }
                }

                hr {
                    background-color: #5fc4b8;
                }

                svg {
                    color: #5fc4b8;
                }
            }

            .order-checkout-box {
                .order-cost {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 10px 0;

                    h6,
                    span {
                        font-size: 18px;
                    }
                }

                .order-total {
                    margin-bottom: 0;

                    h6,
                    span {
                        font-size: 26px;
                        font-weight: bold;
                        margin-bottom: 0;
                    }
                }

                .order-discount {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    // margin-bottom: 20px;
                    .order-discount-box {
                        padding: 9px;
                        width: 75%;
                        border: 1px solid #848484;
                        border-radius: 10px 0 0 10px;

                        input {
                            background-color: transparent;
                            padding: 6px;
                            border: none;
                            width: 100%;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            cursor: initial;

                            &::-ms-input-placeholder {
                                /* Edge 12-18 */
                                font-family: "latoregular";
                                text-transform: none;
                            }

                            &::placeholder {
                                font-family: "latoregular";
                                text-transform: none;
                            }

                            &.active {
                                font-weight: bold;
                                font-family: "Lato-Bold";
                                letter-spacing: 1px;

                                // &.success{
                                //     background-color: #eee;
                                // }
                            }

                        }

                        .pricetag {
                            background-color: #333539;
                            color: #5fc4b8;
                            padding: 5px 0;
                            padding-left: 15px;
                            border: 1px solid #848484;
                            white-space: nowrap;
                            position: relative;
                            border-radius: 5px;
                            line-height: 23px;
                            font-family: 'Lato-Bold';
                            display: flex;
                            align-items: center;
                            text-transform: uppercase;
                            display: inline-block;
                        }

                        .close {
                            margin: 0px 8px 0 14px;
                            font-family: 'latolight';
                            color: #fff;
                            font-size: 15px;
                            content: "\00D7";
                            align-self: center;
                            border: 1px solid #848484;
                            border-radius: 50%;
                            padding: 10px;
                            height: 25px;
                            width: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    button {
                        border: 1px solid #848484;
                        padding: 15px 20px;
                        width: 25%;
                        border-radius: 0 10px 10px 0;
                        cursor: not-allowed;
                        font-family: "Lato";
                        color: #fff;
                        background-color: #5a5c5f;

                        &.active {
                            cursor: pointer;
                            background-color: #5fc4b8;
                            border: 1px solid #5fc4b8;
                            color: #fff;
                        }
                    }
                }

                .order-discount-para {
                    font-size: 14px;
                    font-style: italic;
                    position: relative;
                    width: fit-content;
                    text-transform: initial;
                    margin: 10px 0;
                    min-height: 20px;

                    .celebration-loader {
                        position: absolute;
                        top: -10px;
                        bottom: 0;
                        right: -30px;
                        width: 50px;
                        height: 40px;
                        display: inline-block;
                    }

                    &.success {
                        color: #5fc4b8;
                    }

                    &.failure {
                        color: red;
                    }
                }

                .order-gst-para {
                    font-size: 14px;
                    font-style: italic;
                }
            }

            .order-info-container {
                .order-info-para {
                    font-size: 16px;

                    .mail {
                        color: #5fc4b8;
                        font-family: 'Lato-Bold';

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }

            hr {
                margin: 20px 0;
            }
        }
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        padding: 60px 30px;

        p {
            width: 50%;
            font-size: 16px;
            margin-bottom: 0;
        }

        .footer-nav {
            text-align: right;
            width: 50%;

            span {
                border-right: 1px solid #fff;
                padding: 0 10px;
                cursor: pointer;

                &:hover {
                    color: #02b8af;
                }

                &:last-child {
                    border-right: none;
                    padding-right: 0;
                }
            }
        }
    }

}

.datePicker {
    width: 100%;
    cursor: pointer;
    // border-radius: 8px;
}

.voucher-success {
    .myaccount-info-title {
        flex-direction: column;
    }
}

// worshop section

.workshop-container{
    .main-container{
        display: flex;
        .left-container {
            width: 80%;
           background-color: #191C21;
           padding-left: 100px;
           height: 100%;
           background-image: 
    url("../images/Lines.png"), 
    url("../images/wise-talkies-logo-bottom.svg");
  background-repeat: no-repeat;
  background-position: left top, 50px calc(100% - -30px);
  background-blend-mode: color-dodge;
  @media all and (max-width: 1200px) {
      padding-left: 70px;
  }
        @media all and (max-width: 980px) {
            width: 100%;
            background-color: none;
            background-image: none;
            padding: 0px;
        }
           .top-section{
            padding-top: 180px;
            margin-bottom: 30px;
            @media all and (max-width: 980px) {
                padding: 180px 70px 40px 70px;
            }
            @media all and (max-width: 768px) {
                padding: 160px 40px 40px 40px;
            }
            @media all and (max-width: 640px) {
                padding: 140px 20px 40px 20px;
            }
             h1{
                margin-bottom: 30px;
                .logo-container{
                    width: 15%;
                    @media all and (max-width: 1440px) {
                        width: 30%;
                    }
                    @media all and (max-width: 640px) {
                        width: 40%;
                    }
                    img{
                        display: block;
                        width: 100%;
                    }
                }
             }
             .headings{
                display: flex;
                align-items: baseline;
                margin-bottom: 15px;
                .heading{
                    font-size: 52px;
                    font-family: 'Franklin Gothic Medium';
                    margin-right: 10px;
                    @media all and (max-width: 1440px) {
                        font-size: 48px;
                    }
                    @media all and (max-width: 1200px) {
                        font-size: 44px;
                    }
                    @media all and (max-width: 1024px) {
                        // font-size: 34px;
                    }
                    @media all and (max-width: 980px) {
                        font-size: 34px;
                    }
                    @media all and (max-width: 640px) {
                        font-size: 26px;
                    }
                    @media all and (max-width: 480px) {
                        font-size: 24px;
                    }
                    @media all and (max-width: 360px) {
                        font-size: 20px;
                    }
                }
                .sub-heading{
                    font-size: 30px;
                    font-family: 'Franklin Gothic Medium';
                    @media all and (max-width: 1440px) {
                        font-size: 28px;
                    }
                    @media all and (max-width: 1200px) {
                        font-size: 26px;
                    }
                    @media all and (max-width: 1024px) {
                        // font-size: 24px;
                    }
                    @media all and (max-width: 980px) {
                        font-size: 24px;
                    }
                    @media all and (max-width: 640px) {
                        font-size: 20px;
                    }
                    @media all and (max-width: 480px) {
                        font-size: 14px;

                    }
                    @media all and (max-width: 360px) {
                        font-size: 12px;
                    }
                    
                }
             }
             .main-heading{
                font-size: 34px;
                font-family: 'Franklin Gothic Medium';
                // max-width: 570px;
                margin-bottom: 30px;
                @media all and (max-width: 1440px) {
                    font-size: 32px;
                }
                @media all and (max-width: 1200px) {
                    font-size: 30px;
                }
                @media all and (max-width: 1024px) {
                    font-size: 28px;
                }
                @media all and (max-width: 980px) {
                    font-size: 26px;
                }
                @media all and (max-width: 640px) {
                    font-size: 22px;
                }
                @media all and (max-width: 480px) {
                    font-size: 16px;
                }
            }
            .days-count{
                padding: 10px;
                background-color: #184B48;
                border-radius: 8px;
            }
           }
           .middle-section{
            padding-bottom: 10%;
            display: flex;
            @media all and (max-width: 980px) {
                display: block;
                padding: 0px 70px 40px 70px;
            }
            @media all and (max-width: 768px) {
                padding: 0px 40px 40px 40px;
            }
            @media all and (max-width: 640px) {
                padding: 0px 20px 40px 20px;
            }
            .left-content{
                @media all and (max-width: 980px) {
                    margin-bottom: 100px;
                    display: flex;
                    justify-content: center;
                }
            .middle-content{
                background-color: #172529;
                width: 50%;
                border-radius: 25px;
                rotate: -3.85deg;
                @media all and (max-width: 980px) {
                    width: 90%;
                    
                }
                .image-container{
                    transform: translate(20px, 40px);
                    rotate: 3.85deg;
                    width: 100%;
                    img{
                        display: block;
                        width: 100%;
                        border-radius: 25px;
                    }
                }
            }
           }
           .right-content{
            position: relative;
            @media all and (max-width: 980px) {
                position: unset;
            }
            .payment-form-container {
                position: absolute;
                left: -87px;
                width: 400px;
                margin: auto;
                background: #ffffff;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                font-family: Arial, sans-serif;

                @media all and (max-width: 1750px) {
                    left: -187px;
                }
                // @media all and (max-width: 1650px) {
                //     // left: -70px;
                // }
                // @media all and (max-width: 1600px) {
                //     // left: -105px;
                // }
                // @media all and (max-width: 1550px) {
                //     // left: -150px;
                // }
                @media all and (max-width: 1500px) {
                    left: -185px;
                }
                @media all and (max-width: 1440px) {
                    left: -210px;
                }
                @media all and (max-width: 1280px) {
                    left: -225px;
                    top: -80px;
                }
                @media all and (max-width: 1180px) {
                    top: -112px;
                }
                @media all and (max-width: 980px) {
                    position: unset;
                    left: 0;
                    top: 0;
                    width: 500px;
                }
                @media all and (max-width: 640px) {
                    position: unset;
                    left: 0;
                    top: 0;
                    width: 100%;
                }
              }
              .payment-form {
                padding: 20px;
              }
              .title-section {
                text-align: left; 
                padding-bottom: 10px; 
                border-bottom: 2px solid #000; 
                margin-bottom: 20px; 
                width: fit-content; 
              }
              
              .title-section h1 {
                font-size: 24px;
                font-weight: bold;
                margin: 0;
                color: #000; 
              }
              
              .form-section .form-group {
                margin-bottom: 20px;
              }
              
              .form-group label {
                display: block;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 5px;
                color: #000;
              }
              
              .form-group input {
                width: 100%;
                padding: 10px;
                font-size: 14px;
                border: 1px solid #ccc;
                border-radius: 8px;
                outline: none;
                
              }
              input.error-message-border{
                border: 1px solid red;
              }
              
              .form-group input:focus {
                border-color: #5fc4b8;
              }
            
              
              .radio-section {
                margin: 20px 0 0 0;
              }
              
              .radio-option {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                padding: 20px 10px;
                border: 1px solid #ddd;
                border-radius: 8px;
                cursor: pointer;
                background: #f9f9f9;
                .radio-content{
                    display: flex;
                    align-items: center;
                    .radio-description{
                        margin: 0px;
                        color: #000;
                        font-size: 16px;
                        font-weight: bold;
                        .dot{
                            color: #475467;
                        }
                    }
                }
              }
              
              .radio-option:hover {
                background: #f1f1f1;
              }
              
              .radio-option input {
                margin-right: 10px;
              }
              .error-message-phone{
                display: flex;
                align-items: center;
                // gap: 10px;
                border: 1px solid red;
                border-radius: 8px;
                margin-top: 10px;

                .phone-number {
                    border: none;
                    background-color: #fff;
                    border-color: #5fc4b8
                }
                .verify-button{
                    border: none;
                    background-color: #fff;
                    color: #01BFAE;
                    margin-right: 10px;
                }
                .verified-container{
                    display: flex;
                    align-items: center;
                    .verified-icon{
                        width: 20px;
                        img{
                            display: block;
                            width: 100%;
                        }
                    }
                    .verified-badge{
                        color: #01BFAE;
                        font-size: 14px;
                        margin-right: 10px;
                    }
                }
              }
              .phone-number-input {
                display: flex;
                align-items: center;
                // gap: 10px;
                border: 1px solid #ccc;
                border-radius: 8px;
                margin-top: 10px;
                
                .phone-number {
                    border: none;
                    background-color: #fff;
                    border-color: #5fc4b8
                }
                .verify-button{
                    border: none;
                    background-color: #fff;
                    color: #01BFAE;
                    margin-right: 10px;
                }
                .verified-container{
                    display: flex;
                    align-items: center;
                    .verified-icon{
                        width: 20px;
                        img{
                            display: block;
                            width: 100%;
                        }
                    }
                    .verified-badge{
                        color: #01BFAE;
                        font-size: 14px;
                        margin-right: 10px;
                    }
                }
            }
            .otp-buttons{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                .otp-resend{
                    border: none;
                    background-color: #fff;
                    color: #01BFAE;
                    font-size: 14px;
                }
            }
            .error-message{
                color: red;
                font-size: 14px;
                margin-top: 5px;
                font-weight: bold;
            }
            .error-message-input{
                color: red;
                font-size: 14px;
                font-weight: bold;
            }
            .phone-number-input:focus-within {
                    border-color: #5fc4b8;
            }
            .otp-container{
                margin-top: 10px;
                border: 1px solid #ccc;
                padding: 10px;
                border-radius: 8px;
                .otp-heading{
                    color: #000;            
                }
                .otp-description{
                    color: #000;
                }
            }
            .phone-number {
                padding: 8px;
                width: 100%;
            }
            
            .otp-input {
                display: flex;
                gap: 10px;
                margin-top: 10px;
                @media all and (max-width: 480px) {
                    gap: 5px;
                }
                .otp-box {
                    width: 46px;
                    height: 46px;
                    text-align: center;
                    font-size: 18px;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    @media all and (max-width: 480px) {
                        width: 40px;
                        height: 40px;
                        font-size: 14px;
                    }
                    @media all and (max-width: 360px) {
                        width: 32px;
                        height: 32px;
                        font-size: 12px;
                    }
                }
                
            }
            .country-code{
                color: #000;
                margin-left: 10px;
            }
            .otp-box:focus {
                border-color: #007bff;
                outline: none;
            }
            
             .otp-submit {
                padding: 8px 16px;
                background: linear-gradient(90deg, #02AAB0, #00CDAC); 
                color: #000;
                border: none;
                cursor: pointer;
                border-radius: 8px;
            }
            
             .otp-submit:hover {
                background-color: #0056b3;
            }
              .price {
                font-weight: bold;
                color: #000;
              }
              
              .payment-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #EEF2F6;
                padding: 10px 10px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
                @media all and (max-width: 400px) {
                    display: block;
                }
              }
              
              .payment-icons {
                display: flex;
                gap: 5px;
                align-items: center;
                @media all and (max-width: 400px) {
                    justify-content: center;
                    gap: 15px;
                    margin-bottom: 20px;
                }
              }
              
              .payment-icons img {
                width: 40px; 
                height: auto;
                border-radius: 5px; 
                background-color: white; 
                padding: 5px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
              }

              .loder-button{
                background-color: red;
              }
              
              .pay-button {
                padding: 10px 20px;
                font-size: 16px;
                font-weight: bold;
                color: white;
                background: linear-gradient(90deg, #02AAB0, #00CDAC); 
                border: none;
                border-radius: 8px;
                cursor: pointer;
                transition: background 0.3s ease, transform 0.2s;
                @media all and (max-width: 400px) {
                    width: 100%;
                }
              }
              
              .pay-button:hover {
                background: linear-gradient(90deg, #02AAB0, #00CDAC);
                transform: scale(1.05); 
              }
              
              .pay-button:active {
                transform: scale(0.95);
              }
              .pay-button.disabled {
                background: #cccccc; 
                color: #666666; 
                cursor: not-allowed; 
                pointer-events: none; 
              }
           }
           
        }
        .bottom-section{
            margin-bottom: 200px;
            @media all and (max-width: 980px) {
                padding: 0 70px;
                margin: 0px;
                background-image: url("../images/Background.png"), url("../images/wise-talkies-logo-bottom.svg");
                background-repeat: no-repeat;
                background-size: cover;
                padding-bottom: 120px;
                background-blend-mode: soft-light;
                background-size: cover, 30%;
                background-position: left top, 50px calc(100% - -30px);
            }
            @media all and (max-width: 800px) {
                background-position: left top, 50px calc(100% - -15px);
            }
            @media all and (max-width: 768px) {
                padding: 0 40px;
                padding-bottom: 120px;
                background-position: left top, 30px calc(100% - -15px);
            }
            @media all and (max-width: 700px) {
                background-position: left top, 30px calc(100% - -2px);
            }
            @media all and (max-width: 640px) {
                padding: 0 20px;
            }
            @media all and (max-width: 480px) {
                padding-bottom: 90px;
            }
            @media all and (max-width: 424px) {
                padding-bottom: 70px;
            }
            @media all and (max-width: 360px) {
                padding-bottom: 70px;
                background-position: left top, 20px calc(100% - -2px);
            }
                .bottom-heading{
                    font-size: 20px;
                    font-family: 'Franklin Gothic Medium';
                    margin-bottom: 20px;
                    color: #fff;
                }
                .terms-section{
                    margin-bottom: 20px;
                    .terms-heading{
                        font-size: 16px;
                        color: #fff;
                    }
                    .terms-description{
                        font-size: 16px;
                        // max-width: 390px;
                        margin-left: 10px;
                        color: #fff;
                    }
                }
                .bottom-description{
                    font-size: 16px;
                    color: #A2A2A2;
                    max-width: 420px;
                }
           }
        
        }
        .right-container{
            width: 20%; 
            background-image: linear-gradient(180deg, #172529, #184B48);
            @media all and (max-width: 980px) {
                display: none;
            }
        }
    }
    @keyframes fadeInOut {
        0% { opacity: 0; bottom: 10px; }
        10% { opacity: 1; bottom: 20px; }
        90% { opacity: 1; bottom: 20px; }
        100% { opacity: 0; bottom: 10px; }
    }
    
    .custom-snackbar {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background: red;
        color: white;
        font-weight: bold;
        padding: 18px 20px;
        border-radius: 5px;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 250px;
        animation: fadeInOut 10s ease-in-out;
        z-index: 9999;
    }

    .progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: white;
        animation: progressBar 10s linear forwards;
    }
    .close-btn {
        position: absolute;
        top: -5px;
        right: 20px;
        background: none;
        border: none;
        color: white;
        font-size: 20px;
        cursor: pointer;
        width: 2px;
    }
    
    @keyframes progressBar {
        0% { width: 100%; }
        100% { width: 0%; }
    }
    
}

.bottom-banner-workshop {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(to top, #184B48, #172529);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 40px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(100%); 
    opacity: 0; 
    transition: transform 0.4s ease-in-out; 
    z-index: 1000;
    @media all and (max-width:640px){
        display: grid;
        justify-content: center;
    }
}

.bottom-banner-workshop.show {
    transform: translateY(0); 
    opacity: 1; 
}

  
  .content {
    text-align: left;
  }
  
  .date {
    font-size: 14px;
    opacity: 0.8;
  }
  
  h2 {
    font-size: 22px;
    margin: 5px 0;
    @media all and (max-width:768px){
        font-size: 16px;
    }
  }
  .date-content{
    display: flex;
    gap: 20px;
    align-items: center;
    @media all and (max-width:640px){
        display: grid;
    }
  }
  
  .countdown {
    display: flex;
    align-items: center;
    gap: 10px;
    @media all and (max-width:360px){
        gap: 2px;
    }
  }
  .main-time{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .time-box {
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    padding: 10px;
    border-radius: 5px;
    min-width: 70px;
    font-size: 20px;
    font-weight: bold;
    @media all and (max-width:480px){
        min-width: 50px;
        font-size: 16px;
    }
  }
  
  .time-text {
    font-size: 12px;
    margin: 0;
    text-align: center;
    margin-right: 5px;
  }

  .time-text-seconds{
    font-size: 12px;
    margin: 0;
    text-align: center;
    margin-right: 0px;
    margin-left: 4px;
  }
  
  .register-btn {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    background: linear-gradient(90deg, #02AAB0, #00CDAC); 
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s;
    width: 150px;
    @media all and (max-width:640px){
        width: 100%;
    }
  }

  
  .register-btn:hover {
    background: linear-gradient(90deg, #02AAB0, #00CDAC);
    transform: scale(1.05); 
    color: #fff;
  }

//   wise craft style


  .wise-craft-slider-container{
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 130px 0px 60px 0px;
    .slick-next{
        right: 15px;
        width: 40px;
        height: 40px;
        background-color: #000;
        border-radius: 8px;
        z-index: 1;
    }
    .slick-prev{
        left: 15px;
        width: 40px;
        height: 40px;
        background-color: #000;
        border-radius: 8px;
        z-index: 1;    
    }
    .slick-next:hover,
    .slick-next:focus,
    .slick-prev:hover,
    .slick-prev:focus {
        background: #000;
    }
    .slick-next:before{
        z-index: 1;
        background-color: none;
        content: url('../images/next.png');
    }
    .slick-prev:before {
        z-index: 1;
        background-color: none;
        content: url('../images/prev.png');
        
    }

    .slick-dots{
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        list-style: none;
        padding: 0;
        margin: 0 ;
    }

    .slick-dots li {
        display: inline-block !important;
        visibility: visible !important;
        width: 5px !important;
        // margin-right:  15px !important;
    }
    .slick-dots li button {
        background-color: #333;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: 0.5; 
        border: none;
        top: -10px;
        // margin-right: 10px !important;
        
    }
    .slick-dots li.slick-active button {
        background-color: #184B48 !important;
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;

 
    }
    .slick-dots li button:before{
        content:" ";
    }

    .wise-craft-slider{
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border-radius: 10px;
        color: #fff;
        padding: 20px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .wise-craft-slider-left{
        padding: 40px;
    margin-left: 40px;
    width: 40%;
    display: flex;
    justify-content: center; /* Centers the image */
    align-items: center;
    }
    .wise-craft-slider-image{
        width: 100%;
        max-width: 300px; /* Adjust the max width */
        height: 400px; /* Set fixed height */
        background-color: #000;
        border-radius: 10px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover; /* Ensures the image fills the div without distortion */
            border-radius: 10px;
        }
    }
    .wise-craft-slider-right{
        flex: 1;
    }
    .wise-craft-slider-right-image{
        display: grid;
        justify-content: center;
        margin: 0 auto;
    }
    .film-workshop-title{
        color: #fff;
        margin-bottom: 20px;
        background-color: #184B48;
        padding: 8px 12px;
        border-radius: 40px;
        font-size: 16px;
        text-align: center;
        width: 180px;
    }
    .wise-craft-slider-title{
        margin-bottom: 20px;
        font-size: 36px;
        font-weight: 700;
        font-family: "lato-bold";
    }
    .wise-craft-slider-description{
        margin-bottom: 20px;
        font-size: 18px;
        max-width: 680px;
        color: #DCDCDC;
    }
    .wise-craft-slider-date{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .wise-craft-slider-date-icon{
        width: 20px;
        margin-right: 10px;
    }
    .wise-craft-slider-date-text{
        font-size: 18px;
        color: #FFFFFF;
        margin: 0px;
        font-family: "lato-bold";
    }
    .wise-craft-slider-date-icon img{
        width: 100%;
        display: block;
    }
    .wise-craft-slider-location{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .wise-craft-slider-location-icon{
        width: 20px;
        margin-right: 10px;
    }
    .wise-craft-slider-location-text{
        font-size: 18px;
        color: #FFFFFF;
        margin: 0px;
        font-family: "lato-bold";
    }
    .wise-craft-slider-location-icon img{
        width: 100%;
        display: block;
    }
    .wise-craft-slider-button{
        margin-top: 10px;
        padding: 12px 28px;
        background-color: #5EC4B8;
        border-radius: 8px;
        color: #000;
        border: none;
        cursor: pointer;
        font-size: 18px;
        font-weight: 700;
        font-family: "lato-bold";
        width: 150px;
    }
    .wise-craft-slider-button:hover{
        background-color: #5FC4B8;
        color: #fff;
    }
    
  }

  .wise-craft-mobile-section{
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 130px 0px 0px 0px;
    display: none;
    
    .wise-craft-mobile-content {
        position: relative;
        overflow: hidden;
    }
    
    .wise-craft-mobile-image img {
        width: 100%;
        height: 300px;
    }
    
    .wise-craft-mobile-date-container {
        color: white;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        .film-workshop-title{
            color: #fff;
            margin-bottom: 20px;
            background-color: #184B48;
            padding: 8px 12px;
            border-radius: 40px;
            font-size: 14px;
            text-align: center;
            width: 150px;
            position: absolute;
            top: 20px;
            left: 10px;
        }
    }
    
    .wise-craft-mobile-text-container {
        padding: 12px;
        color: white;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 80%, transparent);
    }
    
    .wise-craft-mobile-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    .wise-craft-mobile-location {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .wise-craft-mobile-location-text {
        margin: 0px;
        font-size: 14px;
        color: #FFFFFF;
        font-family: "latoregular";
        font-weight: 500;
    }
    .location-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
    .wise-craft-mobile-date{
        background: linear-gradient(90deg, #000007 0%, #202123 160%);
        border-radius: 6px;
        padding: 6px 12px;
        width: 60px;
        text-align: center;
        position: absolute;
        top: 20px;
        right: 10px;
    }
    .wise-craft-mobile-date-month{
        margin-bottom: 0px;
        font-size: 14px;
        color: #FFFFFF;
        font-family: "latoregular";
        font-weight: 500;
    }
    .wise-craft-mobile-date-day{
        margin-bottom: 0px;
        font-size: 14px;
        color: #FFFFFF;
        font-family: "latoregular";
        font-weight: 500;
    }
    .slick-dots{
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        list-style: none;
        padding: 0;
        margin: 0 ;
    }

    .slick-dots li {
        display: inline-block !important;
        visibility: visible !important;
        width: 5px !important;
    }
    .slick-dots li button {
        background-color: #000000;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: 0.5; 
        border: none;
        top: -10px;
        
    }
    .slick-dots li.slick-active button {
        background-color: #0a948c !important;
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;

 
    }
    .slick-dots li button:before{
        content:" ";
    }
  }

  .wisecraft-faq-box{
        padding-top: 63px;
        padding-bottom: 120px;
        max-width: 70%;
        margin: 0 auto;
    

        .faq-title{
            font-weight: 700;
            font-size: 52px;
            color: #FFFFFF;
            font-family: "latoregular";
            line-height: 77px;
            letter-spacing: 0.05em;
            margin-bottom: 15px;
            
        }
    
    // .faq-blue-btn {
    //     font-weight: 700;
    //     font-size: 16px;
    //     line-height: 21px;
    //     text-align: center;
    //     // text-transform: uppercase;
    //     color: #000000;
    //     padding: 14px 57px;
    //     // background: linear-gradient(122.17deg, #02AAB0 0%, #00CDAC 100%);
    //     background-color: #5fc4b8;
    //     border-radius: 8px;
    //     border-color: #5fc4b8;
    //     display: block;
    //     // width: 200px;
    //     margin: 0 auto;
    //     margin-top: 50px;
    // }
    
    .question {
        padding: 18px;
        margin-bottom: 30px;
        background: #191c21;
        box-shadow: 0px 28.6524px 63.0354px rgba(126, 126, 126, 0.15);
        border-radius: 12px;
        cursor: pointer;
    }
    
    .question h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    
    .question p {
        margin-top: 27px;
        color: #E1E1E1;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        font-family: "latolight";
    }
    
    .question div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .question div h4 {
        margin-bottom: 0;
    }
    
    .faq-btn {
        background: transparent;
        border: 0;
    }
    
    .faq-btn svg {
        color: #02aab0;
    }
    
    .question-title {
        cursor: pointer;
        font-weight: 700;
        margin-bottom: 15px;
        font-size: 20px;
        color: #FFFFFF;
        font-family: "latoregular";
    }
  }

  .wisecraft-use-case{
    padding: 80px 40px 0px 40px;
    background: linear-gradient(145deg, #202123 0%, #00000080 50%);
    .hero{
        margin-bottom: 50px;
        h1{
            font-weight: 700;
            font-size: 52px;
            color: #FFFFFF;
            font-family: "latoregular";
            line-height: 66px;
            letter-spacing: 0.05em;
            span{
                color: #02AAB0;
            }
        }
        .description{
            display: flex;
            align-items: end;
            gap: 30px;
            p{
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                color: #ffffff;
                font-family: "latolight";
                margin-top: 15px;
                max-width: 590px;
    
            }
            .line{
                border: 1px solid #343537;
                width: 100%;
            }
        }
        
    }
    .features{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .feature{
            background: linear-gradient(90deg,  #00000080 0%,  #202123 160% );
            border: 1px solid #343537;
            padding: 24px;
            border-radius: 12px;
            width: 24%;
            box-sizing: border-box;
            .icon{
                width: 25px;
                margin-bottom: 20px;
                img{
                    width: 100%;
                    display: block;
                }
            }
            h3{
                font-size: 26px;
                font-weight: 600;
                color: #FFFFFF;
                margin-bottom: 20px;
                font-family: "latoregular";
            }
            p{
                font-size: 16px;
                color: #C3C3C3;
                font-family: "latolight";
                max-width: 230px;
            }
        }
    }
  }


  .past-events {
    padding: 0px 40px 80px 40px;
    .load-more{
        border-radius: 8px;
        margin: auto;
        display: flex;
        justify-content: center;
        border: 1px solid #5EC4B8;
        padding: 12px 24px;
        background: #000;
        color: #fff;
        margin-top: 30px;
      }
}

.past-events h1 {
    font-size: 26px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.event-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
}

.event-card {
    background-color: #000;
    color: white;
    // border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 100%;
  }
  
  .event-image {
    position: relative;
    width: 100%;
    height: 240px;
    
  }
  
  .event-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
  
  .date-container {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: rgba(7, 7, 7, 0.9);
    padding: 5px 10px;
    // border-radius: 8px;
    width: 100%;
    height: 35px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .calendar-image  {
    width: 16px;
    margin-right: 5px;
    img{
        display: block;
        width: 100%;
        border-radius: 0px;
    }
  }
  .event-date {
    font-size: 14px;
    color: white;
    margin: 0px;
  }
  
  .event-content {
   margin-top: 20px;
  }
  
  .event-title {
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
  }
  
  .event-location {
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 5px;
  }
  
  .event-explore {
    color: #02AAB0;
    text-decoration: none;
    margin-top: 8px;
    display: inline-block;
    font-weight: 700;
  }
  
  .event-explore:hover {
    text-decoration: underline;
  }


.upcoming-events{
    padding: 0 40px 80px 40px;
    .upcoming-events-title{
        font-size: 26px;
        font-weight: 700;
        color: #FFFFFF;
        margin-bottom: 30px;
    
    }
    .upcoming-single-event-image{
        display: flex;
        align-items: center;
        justify-content: end;
        border-radius: 10px;
        color: #fff;

    }
    .upcoming-single-event-image-overlay{
        padding: 30px;
        width: 55%;
        background:
        linear-gradient(to left, rgba(0, 0, 0, 0.7) 98%, transparent),
    }
    .upcoming-single-event-card-content{
       width: fit-content;
       margin: 0 auto;
    }
    .upcoming-single-event-workshop-title{
        color: #fff;
        margin-bottom: 20px;
        font-size: 16px;
        background: #184B48;
        padding: 8px 12px;
        border-radius: 40px;
        width: 180px;
        text-align: center;
    }
    .upcoming-single-event-title{
        font-size: 22px;
        font-weight: 700;
        color: #FFFFFF;
        margin-bottom: 20px;
    }
    .upcoming-singgle-event-description{
        font-size: 16px;
        color: #DCDCDC;
        margin-bottom: 20px;
        max-width: 680px;
    }
    .upcoming-single-event-date{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .upcoming-single-event-date-icon{
        width: 20px;
        margin-right: 10px;
    }
    .upcoming-single-event-date-icon img{
       display: block;
       width: 100%;
    }
    .upcoming-single-event-date-text{
       margin: 0px;
       font-size: 16px;
       font-family: "lato-bold";
    }

    .upcoming-single-event-location{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .upcoming-single-event-location-icon{
        width: 20px;
        margin-right: 10px;
    }
    .upcoming-single-event-location-icon img{
       display: block;
       width: 100%;
    }
    .upcoming-single-event-location-text{
       margin: 0px;
       font-size: 16px;
       font-family: "lato-bold";
    }
    .upcoming-single-event-button{
        font-size: 16px;
        background-color: #5EC4B8;
        color: #000;
        margin-top: 10px;
        border-radius: 8px;
        border: none;
        padding: 8px 12px;
        cursor: pointer;
        text-align: center;
        font-weight: 700;
        font-family: "lato-bold";
    }
    .upcoming-single-event-button:hover{
        background: #5EC4B8;
        color: #fff;
    }

    .upcoming-event-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        gap: 20px;
    }
    
    .upcoming-event-card {
        background-color: #000;
        color: white;
        overflow: hidden;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        width: 100%;
      }
      
      .upcoming-event-image {
        position: relative;
        width: 100%;
        height: 320px;
        
      }
      
      .upcoming-event-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
      
      .upcoming-date-container {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        background: rgba(7, 7, 7, 0.9);
        padding: 5px 10px;
        // border-radius: 8px;
        width: 100%;
        height: 35px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    
      .upcoming-calendar-image  {
        width: 16px;
        margin-right: 5px;
        img{
            display: block;
            width: 100%;
            border-radius: 0px;
        }
      }
      .upcoming-event-date {
        font-size: 14px;
        color: white;
        margin: 0px;
      }
      
      .upcoming-event-content {
       margin-top: 20px;
       margin-bottom: 20px;
      }
      
      .upcoming-event-title {
        font-size: 18px;
        font-weight: 700;
        color: #FFFFFF;
      }
      
      .upcoming-event-location {
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 10px;
      }
      
      .upcoming-event-explore {
        color: #02AAB0;
        text-decoration: none;
        margin-top: 8px;
        display: inline-block;
        font-weight: 700;
      }
      
      .upcoming-event-explore:hover {
        text-decoration: underline;
      }
      .upcoming-event-grid-mobile{
        display: none;
      }
}

.single-page{
    padding: 130px 80px 60px 80px;
    position: relative;

    .top-container{
        
    }

    .single-page-image{
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .single-page-image img {
        display: block;
        width: 100%;
        height: 450px; 
        // object-fit: cover;
        border-radius: 16px;
    }
    .single-page-share{
        display: flex;
        align-items: center;
        background-color: #184B48;
        width: 100px;
        padding: 8px 16px;
        border-radius: 40px;
        position: absolute;
        right: 90px;
        top: 135px;
        
    }
    .single-page-share-image{
      width: 15px;
      margin-right: 10px;
    }
    .single-page-share-image img{
        width: 100%;
        display: block;

    }
    .single-page-share-btn{
        background: none;
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        cursor: not-allowed;
    }
    .bottom-container{
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
    }
    .left-content{
        width: 50%;
        margin-right: 20px;
        .title-section{
            margin-bottom: 40px;
            h3{
                font-size: 28px;
                color: #FFFFFF;
                font-weight: 700;
                font-family: "lato-bold";
                margin-bottom: 12px;
            }
            p{
                font-size: 22px;
                font-weight: 500;
            }
        }
        .event-details{
            margin-bottom: 40px;
            .date-content{
                display: flex;
                align-items: center;
                gap: 0px;
                margin-bottom: 10px;
                .event-date-icon{
                    width: 15px;
                    margin-right: 10px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                p{
                    margin-bottom: 0px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .location-content{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .event-location-icon{
                    width: 15px;
                    margin-right: 10px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                p{
                    margin-bottom: 0px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .time-content{
                display: flex;
                align-items: center;
                .event-time-icon{
                    width: 15px;
                    margin-right: 10px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                p{
                    margin-bottom: 0px;  
                    font-size: 18px;
                    font-weight: 500;
                }
            }

        }
        .about-section{
            margin-bottom: 40px;
            h4{
                font-size: 26px;
                font-weight: 700;
                font-family: 'lato-bold';
                margin-bottom: 20px;
                color: #FFFFFF;
            }
            p{
                font-size: 18px;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
        .guests-section{
            margin-bottom: 40px;
            h4{
                font-size: 26px;
                font-weight: 700;
                font-family: 'lato-bold';
                color: #FFFFFF;
                margin-bottom: 20px;
            }
            .guest-list{
                display: flex;
                gap: 10px;
                overflow-x: auto; 
                white-space: nowrap;
                padding-bottom: 10px;
                .guest-icon {
                    width: 160px;
                    flex-shrink: 0;
                    
                    img {
                        display: block;
                        width: 100%;
                        height: 160px;
                        border-radius: 10px;
                    }
            
                    p {
                        margin-bottom: 5px;
                        margin-top: 5px;
                        font-size: 18px;
                        font-weight: 500;
                        font-family: "lato-regular";
                    }
                }
            }
        }
        .program-section{
            h4{
                font-size: 26px;
                font-weight: 700;
                font-family: 'lato-bold';
                color: #FFFFFF;
                margin-bottom: 20px;
            }
            .day {
                margin-bottom: 20px;
            }
            
            .day-label {
                background-color: #1d3c34;
                color: white;
                padding: 8px 15px;
                border-radius: 20px;
                font-weight: bold;
                display: inline-block;
                margin-bottom: 10px;
            }
            
            .sessions {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
            
            .session {
                display: grid;
                grid-template-columns: 1fr 1fr; /* Equal space for title and speaker */
                font-size: 1.1em;
                align-items: center;
            }
            
            .title::before {
                content: "• "; /* Unicode bullet character */
                color: white;
                font-weight: bold;
                margin-right: 8px;
            }
            
            .title {
                font-weight: bold;
            }
            
            .speaker {
                text-align: left;
            }  
        }
    }
    .right-content{
        width: 50%;
        display: contents;
        h3{
            font-size: 26px;
            font-weight: 700;
            font-family: "lato-bold";
            color: #FFFFFF;
            padding: 20px 20px 0px 20px;
        }
        .form-date-content{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding: 0px 20px;
        }
        .form-date-icon{
            width: 15px;
            margin-right: 10px;
        }
        .form-date-icon img{
            display: block;
            width: 100%;  
        }
        .form-date-content p{
            margin-bottom: 0px; 
        }
        .form-location-content{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding: 0px 20px;
        }
        .form-location-icon{
            width: 15px;
            margin-right: 10px;
        }
        .form-location-icon img{
            width: 100%;
            display: block; 
        }
        .form-location-content p{
            margin-bottom: 0px;  
        }
        .form-groups{
            background-color: #202123;
            // padding: 24px;
            width: 420px;
            border-radius: 10px;
            height: 100%;
            .personal-info{
                padding: 20px;
                h5{
                    color: #fff;
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-weight: 700;
                }
                .otp-container{
                    margin-top: 10px;
                    border: 1px solid #ccc;
                    padding: 10px;
                    border-radius: 8px;
                    .otp-heading{
                        color: #fff;            
                    }
                    .otp-description{
                        color: #fff;
                    }
                }
                .phone-number {
                    padding: 8px;
                    width: 100%;
                }
                
                .otp-input {
                    display: flex;
                    gap: 10px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    color: #FFFFFF;
                    @media all and (max-width: 480px) {
                        gap: 5px;
                    }
                    .otp-box {
                        width: 46px;
                        height: 46px;
                        text-align: center;
                        font-size: 18px;
                        border: 1px solid #ccc;
                        border-radius: 8px;
                        color: #fff;
                        @media all and (max-width: 480px) {
                            width: 40px;
                            height: 40px;
                            font-size: 14px;
                        }
                        @media all and (max-width: 360px) {
                            width: 32px;
                            height: 32px;
                            font-size: 12px;
                        }
                    }
                    
                }
                .otp-box:focus {
                    border-color: #00483c;
                    outline: none;
                }
                
                 .otp-submit {
                    padding: 8px 16px;
                    background: linear-gradient(90deg, #02AAB0, #00CDAC); 
                    color: #fff;
                    border: none;
                    cursor: pointer;
                    border-radius: 8px;
                }
                
                 .otp-submit:hover {
                    background-color: #0056b3;
                }
                .otp-buttons{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;
                    .otp-resend{
                        border: none;
                        background-color: #0000;
                        color: #01BFAE;
                        font-size: 14px;
                    }
                }
            }
            .form-section{
                    .verify-btn{
                        border: none;
                        background: #5EC4B8;
                        color: #fff;
                        width: 100%;
                        padding: 10px;
                        border-radius: 10px;
                        margin-top: 10px;
                        font-weight: 700;
                    }
                    .verify-btn-diseble{
                        border: none;
                        background: #707173;
                        color: #fff;
                        width: 100%;
                        padding: 10px;
                        border-radius: 10px;
                        margin-top: 10px;
                        font-weight: 700;
                        cursor: not-allowed;
                    }
            }
            .form-group{
                margin-bottom: 10px;
                label{
                    margin-top: 10px;
                }
                input{
                    width: 100%;
                    padding: 10px;
                    background-color: #191C21;
                    border: 1px solid #2F2F2F;
                    border-radius: 10px;
                }
                input:focus {
                    border-color: #5fc4b8;
                }
                .form-phone-number-input{
                    display: flex;
                    align-items: center;
                    border: 1px solid #2F2F2F;
                    border-radius: 8px;
                    background-color: #191C21;
                    
                    .country-code{
                        margin: 0 10px;   
                    }
                    .phone-number {
                        border: none;
                        background-color: #191C21;
                        border-color: #5fc4b8
                    }
                    .verify-button{
                        border: none;
                        background:none;
                        color: #01BFAE;
                        font-weight: 700;
                        margin-right: 10px;
                    }
                    .verified-container{
                        display: flex;
                        align-items: center;
                        .verified-icon{
                            width: 20px;
                            img{
                                display: block;
                                width: 100%;
                            }
                        }
                        .verified-badge{
                            color: #01BFAE;
                            font-size: 14px;
                            margin-right: 10px;
                        }
                    }
                
                }
                .form-phone-number-input:focus{
                    border-color: #5fc4b8;
                }
            }
            
        }
        .pricing{
              margin-top: 20px;
              span{
                font-size: 20px;
                font-weight: 600;
              }  
            }
            .radio-section {
                margin: 20px 0 0 0;
              }
              
              .radio-option {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                padding: 12px 16px;
                border-radius: 16px;
                cursor: pointer;
                background: #23262B;
                border: 2px solid transparent;
                transition: all 0.3s ease;
            }
            
            .radio-option:hover, 
            .radio-option:has(input:checked) {
                background: #2E3238;
               
            }
            
            .radio-content {
                display: flex;
                align-items: center;
                gap: 10px;
            }
            
            .radio-description {
                margin: 0;
                color: #FFFFFF; 
                font-size: 14px;
                font-weight: bold;
            }
            
            .radio-option input {
                appearance: none;
                width: 18px;
                height: 18px;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                outline: none;
                position: relative;
                cursor: pointer;
            }
            
            .radio-option input:checked {
                border-color: #02AAB0;
                background: #02AAB0;
            }
            
            .radio-option input:checked::before {
                content: "";
                width: 8px;
                height: 8px;
                background: white;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            
            .price {
                font-size: 14px !important;
                font-weight: bold;
                color: #FFFFFF;
            }
            
            .price del {
                color: #A0A0A0;
                font-size: 14px;
                margin-right: 5px;
            }
            .booking-section{
                background-color: #0B0E13;
                padding: 30px 20px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .booking-button{
                padding: 10px 28px;
                color: #000;
                background-color: #5EC4B8;
                border: none;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 700;
            }
            .booking-button-section{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .error-message-wisecraft{
                color: red;
                font-size: 14px;
                margin-top: 10px;
            }
    }
}

// past-single-style

.past-single-page{
    padding: 130px 80px 60px 80px;
    position: relative;
    .top-container{
        margin-bottom: 40px;
        
    }
    .single-page-image1{
        position: relative;
        display: inline-block;
        width: 100%;
        
    }
    .single-page-image{
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .single-page-image img {
        display: block;
        width: 100%;
        height: 450px; 
        object-fit: cover;
        border-radius: 16px;
    }

    .single-page-image1 img {
        display: block;
        width: 100%;
        height: 450px; 
        object-fit: cover;
        border-radius: 16px;
        filter: brightness(0.5); 
    }

    .play-button-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ff0000;
        border-radius: 22%;
        cursor: pointer;
        width: 77px;
        height: 55px;  
    }
    
    .play-button {
        background: none;
        border: none;
        color: white;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        left: 10px;
        bottom: -2px;
    }
    .single-page-share{
        display: flex;
        align-items: center;
        background-color: #184B48;
        width: 100px;
        padding: 8px 16px;
        border-radius: 40px;
        position: absolute;
        right: 90px;
        top: 135px;
    }
    .single-page-share-image{
      width: 15px;
      margin-right: 10px;
    }
    .single-page-share-image img{
        width: 100%;
        display: block;

    }
    .single-page-share-btn{
        background: none;
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        cursor: not-allowed;
    }
    .details-section{
        .title-section{
            margin-bottom: 40px;
            h3{
                font-size: 28px;
                color: #FFFFFF;
                font-weight: 700;
                font-family: "lato-bold";
                margin-bottom: 12px;
            }
            p{
                font-size: 22px;
                font-weight: 500;
                max-width: 700px;
            }
        }
        .event-details{
            margin-bottom: 40px;
            .date-content{
                display: flex;
                align-items: center;
                gap: 0px;
                margin-bottom: 10px;
                .event-date-icon{
                    width: 15px;
                    margin-right: 10px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                p{
                    margin-bottom: 0px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .location-content{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .event-location-icon{
                    width: 15px;
                    margin-right: 10px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                p{
                    margin-bottom: 0px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .time-content{
                display: flex;
                align-items: center;
                .event-time-icon{
                    width: 15px;
                    margin-right: 10px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                p{
                    margin-bottom: 0px;  
                    font-size: 18px;
                    font-weight: 500;
                }
            }

        }
    }
    .description-section{
        display: flex;
        margin-bottom: 40px;
        .content-section{
            width: 50%;
            padding-right: 50px;
            .about-section{
                margin-bottom: 40px;
                h4{
                    font-size: 26px;
                    font-weight: 700;
                    font-family: 'lato-bold';
                    margin-bottom: 20px;
                    color: #FFFFFF;
                }
                p{
                    font-size: 18px;
    
                }
            }
            .guests-section{
                margin-bottom: 40px;
                h4{
                    font-size: 26px;
                    font-weight: 700;
                    font-family: 'lato-bold';
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }
                .guest-list{
                    display: flex;
                    gap: 10px;
                    overflow-x: auto; 
                    white-space: nowrap;
                    padding-bottom: 10px;
                    .guest-icon {
                        width: 160px;
                        flex-shrink: 0;
                        
                        img {
                            display: block;
                            width: 100%;
                            height: 160px;
                            border-radius: 10px;
                        }
                
                        p {
                            margin-bottom: 5px;
                            margin-top: 5px;
                            font-size: 18px;
                            font-weight: 500;
                            font-family: "lato-regular";
                        }
                    }
                }
            }
            .program-section{
                h4{
                    font-size: 26px;
                    font-weight: 700;
                    font-family: 'lato-bold';
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }
                .day {
                    margin-bottom: 20px;
                }
                
                .day-label {
                    background-color: #1d3c34;
                    color: white;
                    padding: 8px 15px;
                    border-radius: 20px;
                    font-weight: bold;
                    display: inline-block;
                    margin-bottom: 10px;
                    font-size: 18px;
                }
                
                .sessions {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
                
                .session {
                    display: grid;
                    grid-template-columns: 1fr 1fr; /* Equal space for title and speaker */
                    font-size: 1.1em;
                    align-items: center;
                }
                
                .title::before {
                    content: "• "; /* Unicode bullet character */
                    color: white;
                    font-weight: bold;
                    margin-right: 8px;
                }
                
                .title {
                    font-weight: bold;
                    font-size: 18px;
                }
                
                .speaker {
                    text-align: left;
                    font-size: 18px;
                }  
            }
        }
        .feature-event-section{
            width: 50%;
            border-left: 1px solid #343537;
            padding-left: 100px;
            h4{
                font-size: 22px;
                font-weight: 700;
                font-family: 'lato-bold';
                color: #FFFFFF;
                margin-bottom: 30px;

            }
            .feature-event-container{
                

            }
            .feature-event-item{
                border-bottom: 1px solid #343537;
                margin-bottom: 20px;
                p{
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #FFFFFF;
                }
            }
            .date-content{
                display: flex;
                align-items: center;
                gap: 0px;
                margin-bottom: 10px;
                .event-date-icon{
                    width: 15px;
                    margin-right: 10px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                p{
                    margin-bottom: 0px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .location-content{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .event-location-icon{
                    width: 15px;
                    margin-right: 10px;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                p{
                    margin-bottom: 0px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .event-explore{
                color: #02AAB0;
                text-decoration: none;
                margin-top: 8px;
                display: inline-block;
                font-weight: 700;
                margin-bottom: 10px;
            }
        }
    }

    .event-highlights {
        h1{
            font-size: 26px;
            font-weight: 700;
            color: #FFFFFF;
            margin-bottom: 30px;
            text-align: center;
        }
        .description{
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
            margin-bottom: 30px;
            text-align: center;
        }
        .gallery-container {
            column-count: 2;
            column-gap: 10px;
        }
        
        .gallery-item {
            display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        }
        
        .gallery-item img {
            width: 100%;
            // height: 400px;
            object-fit: cover;
            border-radius: 8px;
            display: block;
        }
    
        
        .load-more {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }
}



  
  