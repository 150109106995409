.admin-navigation {
    margin-top: 36px;
    background: #191c21;
    // margin-bottom: 118px;
    .nav-container {
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav .nav-link {
        padding: 1rem 0;
        margin-right: 1.5rem;
        color: #fff;
        background-color: transparent;
        &.active,
        &:hover {
            background-color: transparent;
            color: #02aab0;
            a {
                color: #02aab0;
            }
        }
    }
    .dropdown.show {
        .dropdown-toggle {
            color: #02aab0;
        }
        .dropdown-menu {
            padding: 1rem;
        }
        a.dropdown-item {
            color: #fff;
            &:hover {
                color: #02aab0;
            }
        }
    }
}
.remove-caret .dropdown-toggle::after {
    display: none;
}
