@media (max-width:1920px) and (min-width:1901px) {
	.col-four-box {
		margin-left: -269px;
		margin-top: 115px;
	}

	.col-four-box-left {
		margin-top: 116px;
		transform: translateX(260px);
	}

	.right-tab {
		width: auto;
		margin-top: -132px;
	}
}

@media (max-width: 1920px) {
	.membership-box .box {
		display: none;
	}
	.login-box .row {
		align-items: center;
	}

}

@media all and (max-width:1600px) {
	.login-box .login-sub {
		left: 4%;
		transform: translateY(-170px);
	}
	.category-title{
		font-size: 34px;
	}
}

@media (min-width: 1500px) {
	// .new-session .video-section-left {
	// 	transform: translateX(-75%);
	// 	left: auto;
	// 	width: 50%;
	// }
	.landing-instructor-box .content{
		max-height: unset;
	}
	// .landing-instructor-content{
	// 	min-height: unset;
	// }
}

@media (min-width: 1440px) {
	.overlay {
		width: 76%;
	}
	.module-container{
		height: 600px;
	}
}

@media (max-width: 1400px) {

	.myaccount-info,
	.myaccount-info2 {
		max-width: 100%;
		width: 100%;
	}
	// .fullcontainer{
	// 	margin-left: 10px;
	// }
	.discover-subtitle {
		font-size: 20px;
	}
	.discover-txt{
		font-size: 12px;
		font-weight: 500px;
	}

	.contact-txt li span {
		font-size: 18px;
	}


	.team-box .slick-slide img {
		width: 96%;
	}

	.setting-icons .nav-item a, .delete-account-box span {
		font-size: 14px;
	}


	.testimonial-one img {
		width: 55%;
	}

	.tab-content {
		padding: 20px 0px;
	}

	.device-info {
		width: 100%;
		max-width: 100%;
	}

	.device-data {
		width: 100%;
	}
}

@media all and (max-width: 1280px) {
	.cross-right-box {
		padding: 48px 38px 30px 30px;
	}
	.session-content {
		h3{
			font-size: 22px;
		}
	}
	.input-info label {
		font-size: 16px;	
	}
	.trending-title{
		font-size: 16px;
	}

	.discover-subtitle {
		font-size: 16px;
	}
	
	// .myaccount-info h3 {
	// 	font-size: 17px;
	// }
	.setting-icons .nav-item a {
		font-size: 12px;
	}
	.setting-icons, .delete-account-box {
		padding: 15px;
	}
	.transactions{
		td{
			font-size: 12px;
			
		}
	}
	.myaccount-infos {
		flex-direction: column-reverse;
	}

	.all-notes p {

		font-size: 12px;
	}

	.note-sub {
		position: relative;
		// top: -36px;
	}

	.team-box {
		padding-top: 60px;
	}

	// .landing-instructor-content{
	// 	min-height: 154px;
	// }

	.testimonial-one img {
		width: 55%;
	}

	.cross-right-box h3 {
		font-size: 35px;
	}

	.testimonial-txt {
		right: 11%;
		top: 31%;
	}

	.new-session .container-fluid .row {
		padding: 0;
	}

	// .login-form {
	// 	padding:  56px;
	// }

	.category-title{
		font-size: 28px;
	}

	.course-detail-head-container {
		min-height: 330px;
	}

	.course-detail-video-player .course-detail-head-img {
		min-height: 330px;
	}

}

@media (min-width: 1200px) {
	// .trending-box .container {
	// 	max-width: 1140px;
	// }

	.all-notes .note-content {
		// top: -8%;
	}

	.membership-box .icon {
		display: none;
	}

	// .coming-soon .container {
	// 	max-width: 1140px;
	// }

	.testimonial-one img {
		width: 40%;
	}

	.testimonial-txt {
		right: 25%;
		top: 21%;

	}
	.package-details .sub-title{
		margin-bottom: 90px;
	}
}

@media (max-width: 1200px) {
	.membership-box .icon {
		display: none;
	}

	.gift-grid {
		justify-content: center;
	}

	.instructor-cousre-images {
		gap: 25px;
	}

	.meta-box .meta2 {
		font-size: 20px;
	}

	.meta-info {
		margin: 50px 0;
	}

	.trending-box h3 {
		font-size: 30px;
	}

	.categorys .card-details h3 {
		font-size: 21px;
	}

	.discussion-wrapper {
		grid-template-columns: 1fr;
	}

	.contributors {
		max-width: 420px;
		width: 420px;
	}

	.all-notes .note-content {
		// top: -8%;
	}

	.discussion-card-tag,
	.discussion-card-info {
		span {
			padding: 6px 10px;
		}
	}

	.info2-icons {
		right: 10px;
	}

	.course-completion,
	.case-study {
		grid-template-columns: 1fr;
	}

	.course-note-form {
		width: 100%;
	}

	.course-about-instructor {
		width: fit-content;
	}

	.right-tab {
		margin-top: 14px;
	}

	.community-section .col-xl-4 {
		width: 100%;
	}

	.community-section .col-xl-8 {
		width: 100%;
	}

	.modalcontainer {
		span {
			font-size: 14px;
		}
	}

	.course-content .course-title {
		font-size: 20px;
	}

	.contributors-head p {
		font-size: 18px;
	}

	.contributors {
		display: flex;
		width: unset;
		max-width: unset;
		justify-content: space-between;
	}

	.contributors-discussions {
		width: 44%;
		margin-top: unset;
	}

	.communities-form-detail {
		width: 100%;
	}

	.communities-form h2 {
		font-size: 50px;
	}

	.contributors-box {
		width: 50%;
	}

	.package-name h3 {
		font-size: 26px;
	}
	.video-placeholder {
		padding: 0 20px;
	}
	.search-form{
		width: 180px;
		padding: 6px 14px;
	}
}

@media all and (max-width: 1080px) {
	.header-right-container{
		width: 35%;
	}
	.main-hero-box-container {
		min-height: 500px;
	}

	.discover-title,
	.cross-title {
		font-size: 38px;
		line-height: 50px;
	}

	// .landing-instructor-content{
	// 	min-height: 178px;
	// }

	.course-hr{
		margin:25px 0 ;
	}
	.course-detail-hero-wrapper {
		height: calc(100vh - 77px);
	}
	// .trending-box .content {
	// 	width: 300px !important;
	// }
	.module-content .module-read-more p {
		font-size: 14px;
	}
	.module-info .question-title{
		font-size: 18px;
	}

	.course-detail-head-container {
		min-height: 280px;
	}

	.course-detail-container .module-content {
		// padding: 0 20px;

		.module-lesson-content-head .lesson-image {
			width: 40%;
		}
	}

	.course-detail-hero-container{
		.course-detail-head{
			h3{
				font-size: 25px;
			}
		}
	}

	.category-class-card .card-details hr {
		margin: 10px auto;
	}

	.category-title{
		font-size: 24px;
	}

	.course-detail-video-player .course-detail-head-img {
		min-height: 260px;
	}

	.signup-box p.first-txt {
		font-size: 30px;
	}


	.modalcontainer {
		span {
			// font-size: 14px;
		}
	}

	.communities-head {
		margin: 30px 0;
	}

	.login-form {
		padding: 35px;
	}

	.welive-box .col-lg-4 {
		width: 48%;
		margin-bottom: 15px;
	}

	.welive-box .col-lg-4 {
		margin: 0 auto;
		margin-bottom: 15px;
	}

	.weare-box {
		padding-top: 40px;
	}

	// .discover-tabs {
	// 	margin-top: 40px;
	// 	margin-bottom: 50px;
	// }

	.testimonial-txt {
		right: 4%;
		top: 45%;
		padding: 43px 46px;
	}

	.category-page {
		// max-width: 850px !important;
	}

	.new-session{
		padding-bottom: 0;
	}
	.nav-item{
		a{
			font-size: 14px;
		}
	}
	.Nav{
		a{
			font-size: 14px;
		}
	}
	.Header .nav-item.icon h6 {
		font-size: 14px;
	}
	

}

@media (max-width:1040px) and (min-width:1025px) {

	.weare-box .video-box img {
		width: 100%;
	}

	.team-box .slick-slide {
		padding: 0 10px;
	}

	.team-box .team-title {
		font-size: 22px;
	}

	.team-box .content-details {
		transform: translate(-50%, 19%);
	}

	.team-box .slick-prev {
		left: -22px;
	}

	.team-box .slick-next {
		right: -11px;
	}

	.slick-slide img,
	.discover-box .slick-slide img,
	.trending-box .slick-slide img,
	.coming-soon .slick-slide img {
		width: 100%;
	}

	.testimonials-box .slick-slide img {
		width: 55%;
	}

	.hero-box .slick-slide,
	.discover-box .slick-slide,
	.trending-box .slick-slide,
	.coming-soon .slick-slide {
		padding: 0 10px;
	}

	// .discover-box .slick-prev,
	.trending-box .slick-prev,
	.coming-soon .slick-prev {
		left: -25px;
	}

	// .discover-box .slick-next,
	.trending-box .slick-next,
	.coming-soon .slick-next {
		right: -21px;
	}

	.discover-box .content .content-overlay,
	.discover-box .content-details {
		width: 100%;
	}

	// .discover-box .content:hover .content-details {
	// 	left: 50%;
	// }

	// .discover-box .fadeIn-bottom {
	// 	top: 82%;
	// }

	.discover-box .content-details {
		padding: 15px;
	}

	.discover-subtitle {
		font-size: 20px;
	}

	.trending-box .content-details {
		padding: 24px 20px;
	}

	.trending-title {
		font-size: 24px;
	}

	.package-name .plan-btn {
		padding: 12px 20px;
		width: 100%;
	}

	.package-details .plan-hr {
		margin-bottom: 65px;
	}

	.package-details .sub-title{
		margin-bottom: 60px;
	}

	// .package-box .plan-table td {
	// 	height: 108px;
	// }

	// .package-name h3 {
	// 	font-size: 26px;
	// }

	.contact-txt li {
		font-size: 18px;
	}

	.login-box img.login-bg {
		width: 100%;
	}

	.login-sub p {
		font-size: 20px;
	}

	.col-four-box {
		// margin-top: 50px;
	}

	.cross-right-box {
		padding: 35px;
	}

	.cross-right-box h3 {
		font-size: 35px;
		line-height: 43px;
	}

	.col-four-box-left {
		// margin-top: 48px;
	}

	.coming-soon .slick-slide .snip1584 {
		// width: 448px;
		// height: 252px;
	}


}

@media (min-width:1024px) {

	// .all-notes .slick-prev,
	// .all-notes .slick-next {
	// 	display: none !important;
	// }

	.testimonials-box .container {
		max-width: 1400px;
	}

	.weare-box {
		padding-top: 25px;
	}


}

@media (max-width:1024px) and (min-width:992px) {
	.footer {
		padding: 51px 15px 0 15px;
	}

	.bottom-footer {
		padding: 15px 18px;
	}

	.testimonial-txt {
		padding: 40px;
		width: auto;
		right: 38%;
		top: 11%;
	}

	.discover-box .slick-prev,
	.trending-box .slick-prev,
	.coming-soon .slick-prev {
		left: -30px;
	}

	.discover-box .slick-next,
	.trending-box .slick-next,
	.coming-soon .slick-next {
		right: -16px;
	}

	.col-four-box {
		// margin-top: 50px;
	}

	.cross-right-box {
		padding: 35px;
	}

	.cross-right-box h3 {
		font-size: 30px;
		line-height: 43px;
	}

	.col-four-box-left {
		// margin-top: 48px;
	}

	.coming-soon .slick-slide .snip1584 {
		// width: 459px;
		// height: 259px;
		// transform: translateX(-5px);
	}

	.team-box .slick-slide {
		padding: 0 10px;
	}

	.team-box .team-title {
		font-size: 22px;
	}

	.team-box .content-details {
		transform: translate(-50%, 19%);
	}

	.team-box .slick-prev {
		left: -22px;
	}

	.team-box .slick-next {
		right: -11px;
	}

	.weare-box .video-box img {
		width: 100%;
	}

	// .hero-box .slick-slide img,
	// .discover-box .slick-slide img,
	// .trending-box .slick-slide img,
	// .coming-soon .slick-slide img {
	// 	width: 100%;
	// }

	// .hero-box .slick-slide,
	// .discover-box .slick-slide,
	// .trending-box .slick-slide,
	// .coming-soon .slick-slide {
	// 	padding: 0 10px;
	// }

	.discover-box .content .content-overlay,
	.discover-box .content-details {
		width: 100%;
	}

	// .discover-box .content:hover .content-details {
	// 	left: 50%;
	// }

	// .discover-box .fadeIn-bottom {
	// 	top: 82%;
	// }

	.discover-box .content-details {
		padding: 15px;
	}

	.discover-subtitle {
		font-size: 20px;
	}

	.trending-box .content-details {
		padding: 24px 20px;
	}

	.trending-title {
		font-size: 22px;
	}

	.package-name .plan-btn {
		padding: 12px 20px;
		width: 100%;
	}

	.package-details .plan-hr {
		margin-bottom: 65px;
	}

	.package-details .sub-title {
		margin-bottom: 60px;
	}
	// .package-box .plan-table td {
	// 	height: 108px;
	// }

	.package-name h3 {
		font-size: 26px;
	}

	.login-box img.login-bg {
		width: 100%;
	}

	.login-sub p {
		font-size: 20px;
	}
}

@media (min-width:992px) and (max-width:1081px) {
	.grey-box .right-title {
		font-size: 30px;
		line-height: 36px;
	}
	// .transactions tr td {
	// 	padding:20px 10px ;
	// }

	.contact-form {
		padding-left: 30px;
	}

	.contact-txt li span {
		font-size: 15px;
	}

	.trending-box .session-other h3 {
		margin-top: unset;
	}

	.category-class-card .card-details {
		a.btn-branded {
			font-size: 12px;
			// width: fit-content;
			padding: 12px 12px;
		}
		.course-title span, h3 {
			font-size: 18px;
		}	
	}

	.category-class-card:hover .card-details{
		a.btn-branded {
			transform: translateY(5%);
		}
	}

	.category-page .categorys {
		flex-wrap: unset;
	}

	.signup-box .overlay {
		// height: 380px;
		// top: 180px;
	}
}

@media all and (min-width: 992px) {
	.categorys .categorys-links.hide-lg {
		display: block;
	}
	.hide-xl {
		display: none;
	}
}

@media all and (max-width: 992px) {
	.header-right-container{
		width: 100%;
	}
	.wise-craft-container{
		display: none;
	}
	.wise-craft-mobile{
		border-bottom: 1px solid #ced4da;
		display: block;
		margin-bottom: 25px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
	}
	.Nav {
		background: #191C21;
		border-radius: 0;
		align-items: flex-end;
		flex-direction: column;
		height: fit-content;
		z-index: 9;
		padding: 20px;
		// width: 80%;
		transform: translateY(56px);
		width: 100%;
		position: fixed;
		top: -55px;
		left: 0%;
		height: 100vh;
		// overflow: scroll;
		div.Burger{
			margin-right: 30px;
		}
	}
	// .new-session .video-section-left{
	// 	width: 75%;
	// 	margin: 0 auto;
	// }
	// .new-session .session-content{
	// 	width: 100%;
	// 	padding: unset;
	// }
	.new-session #content-section-right {
		width: 100%;
	}
	// .new-session .container{
	// 	flex-wrap: wrap;
	// }
	// .notifications .nav-item {
	// 	border-bottom: unset;
		
	// }

	.new-session .container-fluid .row {
		padding: 0;
	}

	.new-session .video-section-left{
		width:85%
		;
	}
	.video-section-left{
		left: 0;
		margin: 0 auto;
		right:0;
		
	}

	.login-box .container-fluid .col-lg-7,
	.container-fluid .col-lg-5 {
		padding-right: 0;
	}

	// .login-box .container-fluid .col-lg-5 {
	// 	padding-right: 0;
	// }

	// .categorys .hide-md{
	// 	display: none;
	// }

	// .video-section-left{
	// 	top: 110px;
	// }
	.wise-craft{
		.border-line{
			border: none;
		}
	}

	.Header{

	
	.nav-item{
		&.icon{
			padding: 10px;
		}
	}
}

	.notificationbox{
		.border-line{
			border: none;
			display: none;
		}
		width: 100%;
		gap: 0;

		a{
			// padding: 20px;
			font-size: 15px;
			
			
		}
		hr{
			border-bottom: 1px solid #ced4da;
		}
		h5{
			font-size: 15px;
		}
		flex-direction: column;
		align-items: unset;
		margin-top: 25px;
		.nav-item{
			padding: 10px;
			display:flex ;
			justify-content: space-between;
			align-items: center;
			margin-right: 0;

			h5{
				margin-bottom: 0;
			}
		}
	}
	.nav-item{
		border-bottom: 1px solid #ced4da;
	}
	.contain-head{
		border-bottom: 1px solid #ced4da;
	}

	.mobile-box {
		display: flex;
		align-items: center;
	}

	.mobile-serach {
		display: inline-block;
		margin-right: 15px;
		display: none;
	}
	

	.Nav .dropdown-menu {
		// position: initial !important;
		margin-bottom: 30px;
		position: absolute;
		top: 0;
		left: 0;
		max-height: 65vh;
		overflow-y: scroll;
	}

	.Nav .dropdown,
	.Nav .nav-item,
	.Nav .search-form {
		margin-bottom: 15px;
	}

	.search-form{
		width: 80%;
	}

	.Burger {
		display: inline;
		margin-right: 0;
	}

	.Burger-close {
		display: inline;
	}

	.mobile-box {
		display: inline-flex;
		align-items: center;
	}

	.instructor-info-images {
		grid-template-columns: 1fr;
		.instructor-img{
			width: 60%;
			margin: 0 auto;
		}
	}

	.instructor-cousre-images {
		justify-content: center;
	}

	.referral-list {
		display: grid;
		grid-template-columns: 1fr;
		justify-items: center;
		gap: 50px;
		margin: 60px auto;
	}

	.inviteForm {
		width: 100%;
		max-width: 100%;
	}

	.input-info input {
		// padding-right: 120px;
	}
	.input-info span {
		font-size: 12px;
	}


	.course-details-wrapper,
	.quiz-topic-wrapper,
	.course-detail-wrapper {
		grid-template-columns: 1fr;
		// justify-items: center;
	}

	.articles-head {

		.articles-tabs {
			gap: 30px;
		}
	}

	.membership-main h3 {
		font-size: 30px;
		margin-bottom: 10px;
	}

	.plan-title h1 {
		font-size: 44px;
		text-align: center;
	}

	.package-table {
		flex-wrap: nowrap;
		overflow-x: scroll;
		padding: 20px 0;
	}

	.membership-box .package-table {
		flex-wrap: wrap;
		overflow: unset;
		padding: unset;
	}

	.membership-box .container .row {
		justify-content: center;
	}

	.membership-box .plan-width {
		width: 95%;
		// margin-bottom: 50px;
		margin: 0 auto;
	}

	.membership-box button {
		display: none;
	}

	.membership-box .plan-width:first-child {
		display: none;
	}

	.selected-plan {
		margin-top: -20px;
	}

	.package-name h3 {
		font-size: 26px;
	}

	.discussion-details-play {
		max-width: 100%;
		width: 100%;
	}

	.discussion-details-section {
		padding-left: 0px 15px;
	}

	// .discussion-details-card-info {
	// 	display: block;
	// }

	.communities-form-detail {
		width: 100%;
	}

	.faq-accordions,
	.faq-box {
		max-width: 100%;
	}

	.wisecraft-faq-box{
		max-width: 100%;
	}
	.wisecraft-faq-box .faq-title {
		font-size: 38px;
	}

	.discover-tabs li {
		font-size: 17px;
	}

	// .fixed {
	// 	padding: 30px 50px !important;
	// }

	// .Header {
	// 	padding: 30px 50px;
	// }

	// .discover-box .container {
	// 	max-width: 90%;
	// }

	.cross-right-box {
		padding: 32px;
	}

	// .overlay-header{
	// 	display: none;
	// }
	.nav-item  .faq-btn{
		display: block;
	}
	.join-membership .nav-items a {
		padding: unset;
	}
	.fullcontainer{
		margin-left:unset;

		.join-membership{
			.border-line{
				border: none;
			}
			.nav-items {
				border-bottom: 1px solid #ced4da;
				width: 100%	;
				margin-bottom: 25px;
			}
			.nav-items{
				width: 100%	;
				padding: 10px	
			}
		}
	}
	.new-session{
		padding-bottom: 0;
	}
	.session-content{
			padding: 30px;
	}
	.overview{
		.container{
			flex-direction: column;
			.overview-content-main-left{
				width: 100%;
				border-right: none;
			}
			.overview-content-main-right{
				width: 100%;
				margin: 30px 0 0;
			}
		}
	}
	.package-name {
		height: unset;
	}
	.join-membership {
		flex-direction: column;
		 
	
	}
	.overlay-header{
		right: 0;
		top: 10px;
	}
	.nav-pills{
		.nav-item{
			border-bottom: none;
		}
	}
	.course-details-main-container{
		.faq-box{
			padding-bottom: unset;
		}
		.non-bottom{
			display: none;
		}
	}
	.course-detail-hero-container{
		.module-info .question-title {
			font-size: 14px;
		}
		.course-detail-head h3 {
			font-size: 20px;
		}
		p{
			font-size: 12px;
		}
		.instructor-tab-title span {
			font-size: 20px;
			font-weight: 200;
		}
		.course-note-form h5 {
			font-size: 15px;
			width: 70% 	;
		}
		.tab-notes{
			span{
				width: 30%;
				font-size: 14px;
			}
		}
	}

	.course-detail-video-player .course-detail-head-img {
		min-height: 200px;
	}

	.course-tabs{
		.course-details-lesson-nav-head, .course-details-lesson-nav-body.active{
			display: block;
		}
	}

	.course-details-lesson-section{
		display: none;
	}

	.module-content div p {
		font-size: 16px;
	}
	
	.module-info .question-title{
		margin-bottom: unset;
	}
	
	.workshop-image-container {
		width: 50%;
	}
	.module-details{
		width: 48%;
	}
	.module-workshop{
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
		
	}
	.module-container{
		max-width: unset;
	}
	.notificationsicon{
		display: none;
	}
	.overlay-header{
		width: 200px;
		display: none;
	}
	.fullcontainer{
		width: 100%;
		overflow: scroll;
		height: 100%;
		display: block;
		
	}
	.search-icon{
		display: none;
	}
	.setting-icons {
		
		.nav-item{
			border-bottom: unset;
			display: unset;
			
		}
	}
	.Header .notificationbox .nav-item.has-badge .badge {
		font-size: 8px;
		left: unset;
		right: 0;

		
	}
	.profileImg{
		padding: unset;
		// width: 30px;
		// height: 30px;

	}

	.Nav a {
		font-size: 15px;
	}
		
	.setting-icons .nav-item a {
		font-size: 14px;
	}
	.Header .nav-item.has-badge .badge {
		font-size: 8px;
	}

	.Header .nav-item.icon h6 {
		font-size: 15px;
	}
	
	.notificationbox .nav-item{
		h5{
			display: block; 
		}
	}

	.Nav .navdrop1 {
		display: none;
	}

	.contain-head {
		display: block;
		margin-bottom: 25px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
	}



	.contains {
		display: block;
		background-color: #000;
		padding: 30px;
		border: 0.6px solid rgb(42, 42, 42);
		display: flex;
		align-items: center;
		flex-direction: column;
		min-width: 14em !important;
		margin-bottom: 25px;
		border-radius: 10px;
	

		a {
			font-family: 'latolight';
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 25px;
			padding: 0;
		}

		a:last-child{
			margin-bottom: 0;
		}


	}

	.instructor-info-images .instructor-img img {
		width: 75%;
		margin: 0 auto;
	}

	.certificate-box .my-certy {
		margin-top: 25px;
		margin-bottom: 25px;
	}

	.hero-btn {
		font-size: 20px;
	}

	.hero-box {
		padding-bottom: 60px;
	}


	.signup-box .box-shadow {
		height: 310px;
	}
	
	// .signup-box {
	// 	width: 90%;
	// 	margin: 0 auto;
	// 	left: 50%;
	// 	top: 130px;
	// }

	// .new-session .container {

	// 	max-width: 780px;
	// }

	.meta-box .meta2 {
		font-size: 16px;
		text-transform: capitalize;
	}

	.weare-box .container .col-md-6 {
		width: 100%;
	}

	.trending-box .session-other {
		flex-wrap: wrap;
		margin-bottom: 20px;

		.col-lg-4 {
			width: 100%;
		}

		.col-lg-8 {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		h3 {
			margin-top: 30px;
			text-align: center;
		}

		.discover-tabs {
			justify-content: center;
		}
	}

	.membership-box .icon {
		display: block;
		position: absolute;
		right: 25px;
		top: 25px;
	}

	.membership-box .box {
		display: block;
	}

	.membership-box .package-name .plan-price {
		margin-bottom: 20px;
	}

	.membership-box .package-name h3 {
		margin-top: 20px;
	}

	.membership-box .plan-width {
		margin-bottom: unset;
	}

	.membership-box {
		padding-bottom: 50px;
	}

	.membership-box .package-table row {
		display: none;
	}

	.membership-box .package-details {
		display: none;
	}

	.membership-box .package-box .plan-table {
		display: none;
	}

	.membership-box .package-name {
		position: relative;
		padding: 12px;
		margin-top: 30px;
	}

	.settings-container {
		.delete-account-box{
			display: none;
		}
	}

	.billing-info, .checkout-box {
		.input-info{
			label{
				font-size: 16px;
			}
		}
		span {
			font-size: 14px;
		}
		.overlay {
			.Search {
				.formsearch {
					input {
						font-size: 14px;
					}
				}
			}
		}
	}

	.myaccount {
		.delete-account-box{
			display: block;
			margin: 10px auto 0;
			padding: 30px;
			text-align: center;
			h3{
				margin-bottom: 20px;
				font-size: 20px;
			}
		}
	}
	.video-placeholder {
		height: 40vh;
		padding: 20px;
	}
}

@media (max-width:991px) {
	.landing-signup-box{
		.grey-box{
			.row{
				gap: 30px;
				.btn-wisetalkies{
					margin: 0;
				}
			}
		}
	}
	.topic-box {
		flex-direction: column;

		.topic-box-head-container{
			display: flex;
			width: 100%;

			.image-container{
				width: 200px;
			}
			.topic-box-head-container-content{
				display: block;
				width: 70%;
				padding: 0 25px;
			}
		}
		.topic-sub{
			width: 100%;
			padding: 20px 0 0;
			.topic-txt, h3{
				display: none;
			}
			.topic-txt1{
				margin-bottom: 0;
			}
		}
		img.topic-img{
			object-fit: cover;
		}
	}
	
	.curriculum-content {
		.accordion-header {
			span {
				font-size: 12px;
			}
			
			h4 {
				width: 80%;
			}
		}
		.topic-box {
	
			.topic-box-head-container{
	
				.topic-box-head-container-content{
					padding: 25px 25px 0;
				}
			}
			.topic-sub{
				padding: 20px;
			}
		}

	}

	.training-includes {
		.training-content-main-box{
			grid-gap: 20px 0;
		}

		.training-content-box {
			// margin-bottom: 24px;
		}
	}

	.coming-soon .slick-slide img {
		width: 100%;
	}
	.signup-box{
		// top: 500px;
	}

	.classes-container{
		margin-top: 0;
	}

	.course-detail-container .module-content {

		.module-lesson-content-head .lesson-image {
			width: 30%;
		}
	}

	.settings-container {
		.transaction-container{
			padding: 20px;

			.transaction-history{
				padding: 15px;

				h3 {
					margin-bottom: 15px;
				}

				.transactions th {
					width: 30%;
					padding: 13px;
				}
			}
		}
	}

	.setting-icons {
		padding:unset !important;
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		margin-bottom: 10px;

		.nav-item {
			// width: 20%;
			transition: all 0.5s ease;
			
			&:has(.nav-link.active){
				transition: all 0.5s ease;
				width: 50%;
			}

			.nav-link {
				height:100%;
				align-items: center;
			}
			
			.nav-link.active span{
				// transition: all 0.35s ease;
				// height: 100%;
				display: block;
			}
			
			span{
				// transition: all 0.5s ease;
				// height: 0;
				// height: 0;
				display: none;
			}

			a{
				display: flex;
				justify-content: center;
				margin-bottom: 0;
			}
		}
	}
	.signup-modal .col-lg-6:last-child{
		position: absolute !important;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.signup-modal {
		// padding-bottom: 100px;
		.background-image {
			height: calc(100vh - 100px);
			overflow: hidden;
		}
	}

	.overlay {
		z-index: 11111;
	}

	.contact-form {
		padding-left: unset;
	}

	.contact-form h3 {
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.contact-form [type=submit] {
		width: 50%;
		margin: 30px auto 0;
	}

	.grey-box .right-title {
		font-size: 38px;
	}

	.footer {
		padding: 50px 30px 0 30px;
	}

	.footer h6 {
		// margin-top: 20px;
	}

	.bottom-footer {
		padding: 40px 15px 40px 15px;
	}

	.bottom-footer p {
		text-align: start;
		font-size: 13px;
	}

	.bottom-footer p.right-txt {
		text-align: center;
		font-size: 13px;
	}

	.cross-right-box h3 {
		font-size: 30px;
		line-height: 35px;
	}

	.cross-right-box p {
		font-size: 14px;
		line-height: 22px;
	}

	.cross-right-box a {
		font-size: 14px;
		line-height: 22px;
	}

	.testimonials-box .slick-prev,
	.coming-soon .slick-prev {
		left: -30px;
	}

	.testimonials-box .slick-next,
	.coming-soon .slick-next {
		right: -30px;
	}

	.note-sub .note-title {
		font-size: 16px;
	}

	.Nav .Burger {
		width: 95%;
		text-align: right;
	}

	.category-page {
		.alert-get-started {
			flex-direction: column;

			p{
				margin-bottom: 10px;
			}
			.alert-action{
				flex-direction: column;

			}
		}
	}

	.checkout-box {
		padding-bottom: 0;
		.checkout-inner-box{
			flex-wrap: wrap;
			flex-direction: column-reverse;

			.checkout-left{
				display: none;
			}

			.vl{
				display: none;
			}
			.hl{
				display: block;
				// width: 100%;
				margin: 30px 0;
				flex-shrink: unset;
			}

			.order-summary {
				.checkout-left-order-summary{
					display: flex;
					padding: 0 0 30px;
				}
				.order-checkout-box .order-discount {
					.order-discount-box{
						width: 80%;
					}
					button{
						width: 20%;
					}
				}
			}
		}
		.footer-content{
			flex-wrap: wrap;
			p, .footer-nav{
				width: 100%;
				text-align: center;
				margin-bottom: 10px;
			}
		}
	}

	footer .container {
		max-width: unset !important;
	}
}

@media all and (max-width: 981px) {
	.right-tab {
		margin-top: 25px;
	}

	.login-box .overlay{
		top: -10px;
	}

	.referral-page {
		margin-bottom: 100px;
	}

	.welive-box {
		padding-top: 60px;
	}

	.communities-form h2 {
		font-size: 40px;
	}

	.community-trending-sub h4 {
		font-size: 22px;
	}

	.testimonial-txt {
		right: 27%;
		width: 500px;
		top: 40%;
		padding: 43px 46px;
	}

	.testimonial-txt blockquote {
		font-size: 17px;
	}

	.login-box {
		padding-bottom: 70px;
	}
	.account-delete-otp-container{
		width: 80%;
	}

}

@media (max-width: 980px) {

	.main-hero-box-container .hero-box-content-image-mask-container {
		-webkit-mask-image: linear-gradient(90deg, transparent, #000 20.3rem);
	}

	.login-box .box-shadow {
		height: 252px;
	}
	.transactions td {
		font-size: 12px;
	}
	.discover-subtitle {
		font-size: 20px;
	}
	.discover-box .content {
		width: unset !important;
	}

	.progress-box {
		padding-top: 20px;
		padding-bottom: 60px;
	}

	.curriculum-content {
		padding: 35px 30px 35px 55px;
	}

	.curriculum-content .accordion-button {
		font-size: 18px;
		font-weight: 400;

	}

	.detail-modal .modal-content .main-container .signup-box .myaccount-info {
		.myaccount-info-title{
			padding: 0;
		}

		hr{
			margin-bottom: 10px;
		}

		.myaccount-form::-webkit-scrollbar{
			display: block;
			// height: 5px;
			width: 8px;
			border-radius: 50%;
		}
		/* Track */
		.myaccount-form::-webkit-scrollbar-track {
			background: #2a2c31;
			border-radius: 20px;
			height: 80%;
		}

		/* Handle */
		.myaccount-form::-webkit-scrollbar-thumb {
			background: #5a5a5a;
			border-radius: 20px;
		}

		/* Handle on hover */
		.myaccount-form::-webkit-scrollbar-thumb:hover {
			background: #686868;
		}
		.myaccount-form {
			height: calc(100vh - 300px);
			overflow-y: scroll;
			
			.form-inputs {
				margin-bottom: 15px;
				flex-wrap: wrap;
				gap: 10px;

				.input-info{
					width: 100%;
				}
			}
		}

		.myaccount-head-btn{
			margin: 30px 0 0;
		}
	}

	.bottom-banner{

		.offer{
			width: 100%;
			margin-bottom: 10px;
			text-align: center;
		}
		.time-container{
			width: 100%;
			justify-content: center;
		}
	}

	.maintenance{
		.maintenance-main-container{
			width: 80%;
		}
	}

	.footer{
		.footer-logo-box{
			.footer-logo {
				width: 70px;
			}
			
			.flag-container{
				span{
					font-size: 14px;
					margin-right: 15px;
				}
				.flag-logo{
					width: 40px;
				}
			}
		}
	}
	.wisecraft-use-case {
		.hero{
			h1{
				font-size: 44px;
				line-height: 54px;
			}
			.description{
				p{
					font-size: 20px;
				}
			}
		}
		.features{
			.feature{
				width: 48%;
				margin-bottom: 30px;
				h3{
					font-size: 20px;
					margin-bottom: 10px;
				}
			}
		}	
	}

	.past-events{
		.event-grid{
			grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
		}
	}
	.upcoming-events{
		.upcoming-event-grid{
			grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
		}
	}
	.past-single-page{
		padding: 130px 40px 60px 40px;
		.description-section {
			.feature-event-section{
				padding-left: 80px;
			}
		}
		.play-button-overlay{
			width: 55px;
			height: 33px;
			.play-button{
				font-size: 18px;
				left: 0px;
				bottom: -14px;
			}
		}
		.single-page-share{
			right: 52px;
		}
	}
	.single-page{
		padding: 130px 40px 60px 40px;
		.single-page-share{
			right: 52px;
		}
	}
	.wise-craft-slider-container{
		.wise-craft-slider-left{
			margin-left: 20px;
			margin-right: 40px;
			padding: 40px 0px;
		}
	}

}

@media (min-width: 768px) and (max-width: 991px) {
	
	.container {
		max-width: 720px;
	}
	
	.landing-instructor-title {
		font-size: 16px;
	}
	// .course-detail-main-container {
	// 	max-width: 540px;
	// }

	.category-page .col-md-9 {
		width: 100%;
		max-width: 100%;
	}

	.coming-soon{
		.slick-slide{
			.snip1584{
				padding: 15px 30px;
				.coming-content{
					.coming-cat{
						font-size: 12px;
						padding: 7px 12px;
					}
					.coming-sub{
						.coming-title, .coming-txt{
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {

	.main-hero-box-container {
		.hero-box-content-container {
			width: 34%;
		}
	}

	// .container {
	// 	max-width: 840px;
	// }
	.topic-box img.topic-img{
		object-fit: cover;
	}
	
	.curriculum-content .accordion-flush {
		padding: unset
	}

	.team-box .slick-next {
		right: -12px;
	}

	.team-box .slick-prev {
		left: -13px;
	}

	.category-page .col-md-6 {
		width: 33.333%;
	}
}

@media all and (max-width: 768px) {
	.testimonial-txt {
		padding: 24px 24px;
		right: 15%;
	}

	.container-width   {
		max-width: unset !important	;
	}
	.course-workshoup{
		.workshop-image-container {
			width: unset;
		}
	}
	.course-detail-hero-container .col-lg-6{
		width: 100%	;
	}
	.course-detail-hero-wrapper{
		height: unset;
		flex-direction: column;
		.course-detail-video-player{
			width: 100%;
		}
		section.module{
			width: 100%;
		}

	}

	.lesson-complete-modal-container {
		width: 70%;
	}
	
	.section-course-detail{
		flex-direction: column;
		.course-detail-bottom-left{
			width: 100%;
		}
		.course-detail-bottom-right{
			width: 100%;
		}
		.course-detail-hero-container .course-note-form h5{
			width: 100%;
		}

	}

	.workshop-box{
		display: flex;
	}

	.workshop-boxs{
		flex-direction: column;
	}
	
	.module-details{
		span{
			font-size: 14px;	
		}
	}

	.module-infocard{
		h2{
				font-size: 25px;
		}
	}

	.infocard-dots{
		width: 60px;
		height: 60px	;
	}

	.wisecraft-use-case {
		padding: 40px 40px 0px 40px;
		.hero{
			h1{
				font-size: 38px;
			}
			.description{
				p{
					font-size: 18px;
				}
			}
		}
		.features{
			.feature{
				h3{
					font-size: 18px;
					margin-bottom: 10px;
				}
				p{
					font-size: 14px;
				}
			}
		}
	}

	.past-events{
		.event-grid{
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		}
	}
	.upcoming-events{
		.upcoming-event-grid{
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		}
	}
	
// .notificationbox .nav-item .faq-btn {
// 		display: none;
// 	}

	.settings-container {
		.transaction-container {
			.transaction-history {
				padding: 15px;

				.transactions {
					.transactions-heading {
						display: none;
					}

					tr{
						display: flex;
						flex-direction: column;
						border-top: 1px solid #787878;
						border-bottom: none;

						td{
							padding: 0;
							text-align: left;
						}
					}
				}
			}
		}
	}

	.transactions td {
		padding: 12px;
	}

	.instructor-course-container {
		.accordion-header .accordion-button{
			// flex-direction: column;
			align-items: center;
			.accordion-image-container{
				width: 100%;
			}
			.accordion-content-container{
				width: 100%;
				margin: 10px 0 0;
			}
		}
		.accordion-body .accordion-item{
			padding: 35px 0;

			.accordion-body {
				padding: 16px 0;
			}
		}
	}

	.instructor-cousre-images {
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-content: center;
	}

	.instructor-cousre-images>div> {
		p {
			font-size: 25px;

		}

		img {
			width: 100%;
			height: 100%;
		}

	}

	.instructor-info-titles h1 {
		font-size: 40px;
	}

	.faq-search-form {
		margin-bottom: 25px;
	}

	.faq-head h3 {
		font-size: 34px;
	}


	.team-box h3 {
		font-size: 34px;
		margin-bottom: 25px;
	}

	.hero-box {
		h3 {
			font-size: 27px;
			line-height: 40px;
		}
	}

	.testimonial-txt {
		top: 17%;
		width: 320px;
		right: 37%;
		padding: 35px 12px;
	}

	.session-content {
		padding: 25px 65px;
	}

	.curriculam-box .container {
		max-width: unset;
	}

	.new-session .container-fluid {
		max-width: unset;
	}

	.selected-block {
		h3 {
			font-size: 17px;
		}

		h2 {
			font-size: 26px;
		}
	}

	.discussion-tags {
		a {
			font-size: 14px;
		}
	}

	.category-page .alert-get-started {
		text-align: center;
		justify-content: center;
	}

	.signup-box {
		p.first-txt {
			font-size: 24px;
		}

		h3 {
			font-size: 25px;
		}

		p.sub-txt {
			font-size: 16px;
		}
	}

	.Structure .container {
		max-width: unset;
	}

	.meta-box {
		gap: unset;

		svg {
			display: none !important;
		}

		li {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			align-items: baseline;
		}

		.meta1 {
			display: flex;
			margin-bottom: 10px;
			margin-right: 5px;

			span{
				display: block;
			}
		}
	}

	.meta-info {
		padding: 30px;
	}

	.meta-info .meta-content {
		h3 {
			font-size: 17px;
		}

		p {
			font-size: 14px;
		}
	}

	.curriculum-content .accordion-button {
		font-size: 16px;
	}

	.meta-icon {

		display: flex;
		align-items: center;
	}

	.overview {
		.overview-content-main-right {
			.meta-box {
				li{
					width: 100%;
					justify-content: center;
				}
			}
		}
	}

	.accordion-item h2 {
		font-size: 50px;
	}

	.contact-box p {
		font-size: 20px;
	}

	.contact-box h3 {
		font-size: 45px;
		margin-bottom: 15px;
	}

	.referral-page .input-group .form-control {
		font-size: 12px;
	}

	.all-notes .slick-dots {
		bottom: -33px;
	}

	.bookmark-box {
		padding-top: 40px;
	}

	.contributors {
		display: block;
	}

	.contributors-box {
		width: 100%;
	}

	.contributors-discussions {
		width: 100%;
		margin-top: 50px;
	}

	.admin-navigation .container {
		padding: 0 15px;
	}


	.hero-box .hero-btn {
		margin-bottom: 25px;
		font-size: 16px;
	}

	.weare-box p {
		font-size: 18px;
	}

	.welive-box h3 {
		font-size: 34px;
		margin-bottom: 35px;
	}

	.welive-box .col-lg-4 {
		width: unset;
	}

	.team-box {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.membership-box .package-box .box h5 {
		font-size: 16px;
	}

	.membership-box .package-box .box h3 {
		font-size: 24px;
	}

	.checkout-box {
		.checkout-inner-box{
			text-align: left;
		}
	}

	.hero-box{
		text-align: center;
	}

	.career{
		.hero-box .row{
			h3{
				font-size: 40px;
				line-height: 45px;
			}
		}
	}

	.main-hero-box-container {
		height: unset;
	}

	.main-hero-box-container .container-fluid .col-lg-4 {
		// padding-bottom: 25px;
	}

	.login-box .login-sub p {
		font-size: 25px;
	}

	.classes-container .col-sm-12 {
		width: 50%;
	}

	.category-page .alert-get-started .alert-action p {
		margin-right: unset;
	}

	.category-class-card .card-details a {
		font-size: 14px;
	}

	.detail-modal .modal-content {
		width: 80%;
		.main-container .signup-box .myaccount-info .myaccount-form {
			padding: 10px;
		}
	}
	.account-delete-otp-container{
		width: 100%;
	}

	.past-single-page{
		.details-section{
			.title-section{
				h3{
					font-size: 26px;
				}
				p{
					font-size: 18px;
				}
			}
			.event-details{
				.date-content{
					p{
						font-size: 16px;
					}
				}
				.location-content{
					p{
						font-size: 16px;
					}
				}
				.time-content{
					p{
						font-size: 16px;
					}
				}
			}
		}
		
		.description-section{
			display: block;
			.content-section{
				width: 100%;
				padding-right: 0px;
				.about-section{
					h4{
						font-size: 24px;
					}
					p{
						font-size: 16px;
					}
				}
			}
			.feature-event-section{
				display: none;
			}
		}
		.single-page-image1{
			img{
				height: 300px;
			}
		}
		.single-page-image{
			img{
				height: 300px;
			}
		}
		.event-highlights{
			h1{
				font-size: 24px;
				margin-bottom: 15px;
			}
		}
	}
	.single-page{
		.single-page-image{
			img{
				height: 300px;
			}
		}
		.bottom-container{
			display: block;
			.left-content{
				margin-right: 0px;
				width: 100%;
				.title-section{
					h3{
						font-size: 26px;	
					}
					p{
						font-size: 20px;
					}
				}
				.event-details{
					.date-content{
						p{
							font-size: 16px;
						}
					}
					.location-content{
						p{
							font-size: 16px;
						}
					}
					.time-content{
						p{
							font-size: 16px;
						}
					}
				}
				.about-section{
					h4{
						font-size: 24px;
					}
					p{
						font-size: 16px;
					}
				}
			}
			.right-content{
				width: 100%;
				h3{
					font-size: 22px;
				}
				.form-groups{
					width: 100%;
					.personal-info{
						h5{
							font-size: 16px;
						}
					}
				}
				.pricing{
					span{
						font-size: 16px;
					}
				}
				.price{
					font-size: 14px !important;
				}
				.radio-description{
					font-size: 14px;
				}
				
			}
		}
	}
	.wise-craft-slider-container{
		.wise-craft-slider-title{
			font-size: 28px;
		}
		.wise-craft-slider-description{
			font-size: 16px;
		}
		.wise-craft-slider-date-text{
			font-size: 16px;
		}
		.wise-craft-slider-location-text{
			font-size: 16px;
		}
		
	}
	.past-events{
		h1{
			font-size: 24px;
		}
	}
	.upcoming-events{
		.upcoming-events-title{
			font-size: 24px;
		}
		.upcoming-single-event-card-content{
			background-blend-mode: color-dodge;
			padding: 20px;
			border-radius: 8px;
			background: rgb(255, 255, 255, 0.3);
		}
		.upcoming-single-event-image-overlay{
			width: 60%;
			padding: 30px 30px 30px 0px;
		}
		.upcoming-single-event-date{
			padding: 10px;
			border-radius: 8px;
			background: rgb(255, 255, 255, 0.3);
		}
		.upcoming-single-event-date-icon{
			width: 16px;
		}
		.upcoming-single-event-date-text{
			font-size: 14px;
		}
		.upcoming-single-event-location{
			padding: 10px;
			border-radius: 8px;
			background: rgb(255, 255, 255, 0.3);
		}
		.upcoming-single-event-location-icon{
			width: 16px;
		}
		.upcoming-single-event-location-text{
			font-size: 14px;
		}
	}
	

}

@media (min-width:641px) and (max-width:768px) {
	.Structure .container {
		max-width: unset;
	}

	.course .container {
		max-width: 750px;
	}

	// .meta-box .meta1 {
	// 	font-size: 16px;
	// }

	.meta-box {
		justify-content: center;
		align-items: baseline;
		gap: unset;

		li {
			justify-content: center;
		}

	}

	.meta-box .meta2 {
		// font-size: 14px;
	}

}

@media (min-width:768px) and (max-width:980px) {

	.community-section .col-xl-8 {
		width: 90%;
		margin: 0 auto;
	}

	.community-section .col-xl-4 {
		width: 90%;
		margin: 0 auto;
	}
	
	.hero-btn {
		font-size: 16px;
	}
}

@media (max-width: 786px) {
	.hero-box {
		padding-bottom: 60px;
	}

	.checkout-box {
		padding-bottom: 0;
	}
	.menu-popup-links{
		a{
			padding: unset;
		}
	}
	// .overlay-header {
	// 	bottom: 30px;
	// 	height: 100px;
	// 	top: 473px;
	// 	right: 20px;	
	// }
	.nav-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		h5{
			display: block;
			margin-right: 20px;
		}

	}

	.discover-box {
		padding-bottom: 60px;
	}

	.coming-soon {
		padding-top: 60px;
		padding-bottom: 50px;
	}

	.testimonials-box {
		padding-top: unset;
		padding-bottom: 50px;
	}

	.faq-box {
		padding-bottom: 50px;
	}
	.wisecraft-faq-box{
		padding-bottom: 50px;
	}

	.wisecraft-faq-box .faq-title {
		font-size: 32px;
	}

	.chat-area-msg {
		right: 20px;
		margin-left: 20px;
	}

	.bookmark-courses {
		justify-content: center;
	}

	.breadcrumbDetails {
		padding: 0;
		padding-bottom: 30px;
	}

	.discover-tabs li {
		font-size: 16px;
	}

	.terms-and-conditions,.privacy-policy {
		.hero-box {
			text-align: left;
		}
	}
}

@media (min-width: 769px) and (max-width:840px) {
	.trending-box .container {
		max-width: 720px;
	}

	.meta-box .meta2 {
		font-size: 13px;
	}

	.coming-soon .container {
		max-width: 720px;
	}

	// .trending-title {
	// 	font-size: 21px;
	// }

	.trending-txt {
		font-size: 12px;
	}
}

@media all and (max-width: 767px) {
	.hero-box .container-fluid {
		padding-left: 0;
		// width: 90%;
		margin: 0 auto;
	}

	.main-hero-box-container .hero-box-content-container {
		// margin-bottom: 25px;
		position: static;
	}

	.main-hero-box-container .hero-box-content-image-mask-container {
		-webkit-mask-image: none;
	}

	// .category-class-card {
	// 	width: 300px;
	// }

	.bottom-border {
		flex-direction: column
	}

	.bottom-border .col-lg-5 {
		text-align: center;
		margin-bottom: 20px;
	}

	.footer {
		padding: 20px 10px 0;
	}

	
	.footer-contents {
		padding: 0;
	}

	.footer p {
		margin-left: 0;
	}

	.bottom-footer p {
		text-align: center;
	}

	.footer-privacy {
		justify-content: center;
	}
	.footer .footer-logo-box{
		justify-content: center;
	}
	.delete-my-account .hero-box{
		text-align: left;
	}
}

@media (min-width:481px) and (max-width:768px) {
	// .footer {
	// 	padding: 21px 15px 0 15px;
	// }

	.trending-box h3 {
		font-weight: 400;
		font-size: 32px;

	}

	.apple-badge img,
	.google-play-badge img {
		margin-right: 20px;
	}

	.coming-soon .slick-slide .snip1584 {
		// width: 340px;
		// height: 190px;
		transform: translateX(-6px);

	}

	.coming-soon .slick-slide div {
		margin: 0 5px;
	}

	.right-tab {
		margin-top: 33px;
	}

	.testimonial-txt {
		width: auto;
		right: 38%;
		top: 25%;
	}

	.trending-box .slick-slide img {
		width: 95%;
		margin: 0 auto;
	}

	.cross-right-box {
		padding: 20px;
	}

	.col-four-box {
		margin-left: -35px;
		// margin-top: 48px;
	}

	.col-four-box-left {
		// margin-top: 38px;
		transform: translateX(40px);
	}

	// .hero-box .container-fluid {
	// 	padding-left: 50px;
	// }

	.contact-grey {
		padding: 24px;
	}

	.contact-info {
		padding: 24px;
	}

	.contact-txt li {
		font-size: 18px;
	}

	.contact-form {
		padding-left: 12px;
	}

	.plan-title h1 {}

	.package-details {
		padding: 15px;
	}

	.package-name {
		padding: 15px;
	}


	.package-name .plan-btn {
		padding: 8px 12px;
		font-size: 15px;
	}


	.package-box .plan-table td {
		height: 108px;
	}

	.package-name .plan-btn {
		margin-bottom: 110px;
	}

	.weare-box .video-box img {
		width: 100%;
	}

	.team-box .slick-slide {
		padding: 0 10px;
	}

	.hero-box .slick-slide img,
	.discover-box .slick-slide img,
	.trending-box .slick-slide img {
		width: 100%;
	}

	.hero-box .slick-slide {
		padding: 0;
	}

	.discover-box .slick-slide,
	.trending-box .slick-slide {
		padding: 0 10px;
	}

	.discover-box .content .content-overlay,
	.discover-box .content-details {
		width: 100%;
	}

	.discover-box .content-details {
		padding: 30px 32px;
	}

	.login-box img.login-bg {
		width: 100%;
	}

	.alert-get-started {
		display: grid;

		>p {
			font-size: 18px;
		}

		p {
			margin-bottom: 12px;
		}

		.alert-action {
			display: grid;

			a.btn {
				display: block;
				margin-top: 12px;
			}
		}
	}

	.hide-md {
		display: none;
	}

	.trending-box .content:hover .content-details {
		top: 41%;
	}

	.trending-box .session-other-content:hover .content-details {
		top: 50%;
	}

	.discover-subtitle {
		font-size: 16px;
	}

	.discover-hr{
		margin: 10px auto 12px;
	}
	
	.discover-box .discover-box-content-head p{
		font-size: 16px;
	}

	.category-class-card .card-details p {
		font-size: 12px;

	}

	.trending-txt {
		font-size: 10px;
	}

	.trending-hr {
		margin-top: 8px;
		margin-bottom: 8px;
	}
}

@media all and (max-width: 575px) {
	.cross-space-bottom {
		margin-bottom: 50px;
	}

	.grey-box .right-list li {
		font-size: 12px;
	}

	.cross-section .container-fluid .cross-space-bottom .col-four-box {
		margin-left: 0;
	}

	.cross-section .container-fluid .cross-space-bottom .col-four-box-left {
		transform: translateX(0);
	}

	.reverse-column {
		flex-direction: column-reverse;
	}
}

@media (min-width:481px) and (max-width:575px) {
	.grey-box .col-lg-5 h3 {
		font-size: 30px;
	}

	.admin-navigation .nav .nav-link {
		margin-right: 15px;
	}

	.signup-modal .background-image {
		overflow: unset;
	}

	.categorys .card-details {
		h3, .course-title span {
			font-size: 16px;
		}
	}	

	// .nav-pills a {
	// 	font-size: 14px;
	// }

	// .signup-sub {

	// 	top: 40%;
	// 	left: 16%;
	// }

}

@media (max-width:640px) {
	.Header{
		padding: 30px 15px;
		.header-right-container{
			.header-logo-container{
				padding-left: 0;
				padding-right: 10px;
			}
			.flag-container{
				span {
                    font-size: 12px;
					margin-right: 10px;
                }
				.border-line{
					margin-right: 10px;
				}
			}
		}
		
	}
	.section-mb {
		padding-bottom: 50px;
	}
	.main-hero-box-container .hero-image-container {
		// max-height: 400px;
	}


	.landing-signup-box{
		.grey-box{
			padding: 50px;
		}
	}

	.landing-instructor-modal.modal-dialog {
		width: 90%;
	}
	.testimonial-txt {
		right: 28%;
	}
	// .lesson-complete-modal-container {
	// 	width: unset;
	// }
	.module-discussion{
		padding: unset;
		margin-bottom: 25px;
	}
	.module-details span{
		font-size: 13px;
	}

	.tab-content p{
		display: flex;
	}

	.Structure .meta-box {
		li{
			display: flex;
		}
		align-items: baseline;
		align-content: baseline;
	}

	.instructor-icons-info {
		gap: 20px;
	}

	.instructor-cousre-images>div>p {
		font-size: 20px;
	}

	.instructor-info-images .instructor-img img {
		width: 100%;
	}

	.bookmark-box {
		padding-top: unset;
	}

	.certificate-box .my-certy {
		margin-bottom: 10px;
	}


	.right-one-box .blue-btn {
		font-size: 15px;
	}

	.signup-box .box-shadow {
		height: 275px;
	}

	.signup-modal img.signup-bg {
		width: unset;
	}

	// .signup-box .overlay {
	// 	width: 100%;
	// 	height: 100%;
	// 	top: 0;
	// 	left: 0;
	// }

	.signup-box .box-shadow {
		height: 320px;
	}

	.faq-box .question h4 {
		font-size: 14px;
	}
	.wisecraft-faq-box .question h4{
		font-size: 14px;
	}

	.curriculum-content {
		padding: 25px 20px 25px 40px;
	}

	.curriculum-content .accordion-item {
		padding-bottom: 50px;

	}

	.meta-icon {
		margin-bottom: 20px;
	}

	.meta-info .meta-content p {
		line-height: 25px;
	}

	.topic-box {
		.image-container {
			width: 100%;
		}
		.topic-sub {
			width: 100%;
		}
		h3{
			font-size: 14px;
		}
	}

	.login-box .overlay {
		width: 90%;
	}

	.new-session {
		// padding-bottom: 20px;
	}

	.meta-info {
		margin: 25px 0;
	}

	.weare-box .logo-image-container {
		width: 400px;
	}

	.all-notes .note-content {
		top: 0;
	}

	.right-one-box .container-fluid {
		padding: 0 18px;
	}

	.main-hero-box-container {
		height: unset;
	}

	.membership-main p {
		margin-bottom: 10px;
	}

	.session-other-content .trending-title {
		font-size: 14px;
	}

	.delete-my-account {
		.hero-box .row {
			h3 {
				font-size: 40px;
				line-height: 40px;
				margin-bottom: 20px;
			}
			p{
				font-size: 14px;
				margin-bottom: 15px;
			}
			ul{
				padding-left: 2rem;
			}
			ol li{
				font-size: 14px;
			}
		}
	}
	.delete-modal .myaccount-info {
		.myaccount-checkbox{
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: left;
		}
	}

	.checkout-box{
		.row, .col{
			padding: 0;
		}
		.checkout-logo{
			padding: 30px;
			.logo-image{
				padding: 0 12px 0 0;
			}
		}
		.checkout-inner-box{
			padding: 15px;
			.order-summary{
				padding: 15px;
			}
			.checkout-left, .checkout-left-order-summary{
				h6{
					font-size: 18px;
				}
				.image-container{
					padding: 1px;
				}
			}
			.left-col{
				.checkout-header{
					padding: 30px 0;
					.checkout-header-title{
						padding: 0;
					}
					.checkout-form{
						.form-inputs{
							flex-direction: column;
							.input-info {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}			

	.maintenance {
		.maintenance-main-container {
			.maintenance-image-container {
				width: 80%;
			}
		}
	}

	.wisecraft-use-case {
		.hero{
			h1{
				font-size: 34px;
			}
			
		}
	}
	.wise-craft-slider-container .wise-craft-slider-description {
		max-width: 260px;
	}


}

@media (max-width: 576px) {
	.trending-box .container .row .col-sm-6 {
		width: 50%;
	}
	.bottom-footer .bottom-footer-text-content{
		justify-content: center;
	}

	.detail-modal .modal-content .main-container .signup-box {
		padding: 20px;
		.myaccount-info .myaccount-head-btn {
			margin: 20px 0 0;
		}
	}
	.account-delete-otp-container{
		.otp-input{
			margin: 0;
		}
	}
	.video-placeholder {
		height: 30vh;
		// padding: 20px;
	}

	.wisecraft-use-case {
		padding: 40px 20px 0px 20px;
		.hero{
			margin-bottom: 30px;
			h1{
				font-size: 26px;
				line-height: 34px;
				text-align: center;
			}
			.description{
				
				p{
					font-size: 14px;
					text-align: center;
				}
				.line{
					display: none;
				}
			}
		}
		.features{
			.feature{
				width: 100%;
				h3{
					font-size: 18px;
					margin-bottom: 10px;
				}
			}
		}	
	}

	.past-events{
		padding: 40px 20px;
		h1{
			font-size: 22px;
		}
		.event-grid{
			grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		}
	}
	.upcoming-events{
		padding: 40px 20px;
		.upcoming-event-grid{
			grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		}
	}
	.wisecraft-faq-box{
		padding-top: 10px;
	}
	.past-single-page{
		padding: 130px 20px 60px 20px;
		.top-container{
		}
		.single-page-share{
			right: 32px;
			width: 90px;
		}
		.single-page-image1{
			img{
				height: 230px;
			}
		}
		.single-page-image{
			img{
				height: 230px;
			}
		}
		.single-page-share-btn{
			font-size: 14px;
		}
		.single-page-share-image {
			width: 12px;
		}
		.details-section{
			.title-section{
				h3{
					font-size: 18px;
				}
				p{
					font-size: 14px;
				}
			}
			.event-details{
			}
		}
		.description-section{
			.content-section{
				.about-section{
					h4{
						font-size: 18px;
					}
					p{
						font-size: 14px;
					}
				}
				.guests-section{
					h4{
						font-size: 18px;
					}
				}
				.program-section{
					h4{
						font-size: 18px;
					}
					.title{
						font-size: 14px;
					}
					.speaker{
						font-size: 14px;
					}
					.day-label{
						font-size: 14px;
					}
				}
			}
		}
		.event-highlights{
			h1{
				font-size: 18px;
			}
			.description{
				font-size: 14px;
			}
			.gallery-container{
				column-count: 1;
			}
		}
	}
	.single-page{
		padding: 130px 20px 60px 20px;
		.top-container{
		}
		.single-page-share{
			right: 32px;
			width: 90px;
		}
		.single-page-image1{
			img{
				height: 230px;
			}
		}
		.single-page-image{
			img{
				height: 230px;
			}
		}
		.single-page-share-btn{
			font-size: 14px;
		}
		.single-page-share-image {
			width: 12px;
		}
		.bottom-container{
			.left-content{
				.title-section{
					h3{
						font-size: 18px;
					}
					p{
						font-size: 14px;
					}
				}
				.event-details{
				}
				.about-section{
					h4{
						font-size: 18px;
					}
				}
				.guests-section{
					h4{
						font-size: 18px;
					}
				}
				.program-section{
					h4{
						font-size: 18px;
					}
					.title{
						font-size: 14px;
					}
					.speaker{
						font-size: 14px;
					}
					.day-label{
						font-size: 14px;
					}
				}
			}	
		}
		
	}
	.wise-craft-slider-container{
		display: none;
	}
	.wise-craft-mobile-section{
		display: block;
	}
	.upcoming-events{
		padding: 40px 20px 0px 20px;
		.upcoming-events-title{
			font-size: 22px;
		}
		.upcoming-event-grid-mobile{
			display: block;
		}
		.upcoming-single-event{
			display: none;
			
		}
	}
}


@media (max-width: 530px) {

	.trailer-watch-btn {
		a {
			padding: 10px;
			font-size: 12px;
		}
	}
	.wisecraft-faq-box .faq-title {
		font-size: 26px;
	}

}

@media (max-width: 481px) {
	.membership-main h3 {
		margin: 0 auto;
	}

	.faq-head {
		.faq-search-form {
			border-radius: 10px;
		}
	}

	.quiz-topic-info {
		padding: 10px;
	}

	.referral-page {
		padding: 34px 0px;
	}

	.referrals {
		padding: 34px 20px;
	}

	.share-btn,
	.invite-btn {
		margin-right: -10px;
	}

	
	.form-control,
	.input-group-text {
		padding-left: 0px;
	}


	.tab-notes .form-control {
		padding: .375rem .75rem;
	}

	.invite-btn,
	.share-btn {
		display: none;
	}

	.invite-btn2 {
		display: block;
	}

	.membership-box .plan-width {}

	.membership-box .plan-width:first-child {
		width: 80%;
	}

	.input-info input {
		padding-right: 60px;
	}

	.setting-attach {
		width: 90%;
	}

	.case-study-attach {
		.setting-attach {
			margin: 0;
			width: 90%;
		}
	}

	.trailer-info {
		left: 8px;
		right: 8px;
		gap: 10px;
	}

	.trailer-details {
		max-width: 15ch;

		h3 {
			font-size: 20px;
		}

		p {
			font-size: 14px;
		}
	}

	.quiz-form {
		grid-template-columns: 1fr;
	}

	.quizBtn1,
	.quizBtn2 {
		padding: 10px 15px;
	}

	.attachment {
		width: 100%;
	}
}

@media (max-width:480px) {
	.admin-navigation .container {
		padding: 0 25px;
	}
	.pop-up-box .pop-up-box-child {
		width: 80%;
	}
	.profileImg{
		h6{
			display: none;
		}
	}
	.hero-box {
		.marginTop {
			margin-top: 0;
		}
	}

	.modal-box {
		h3{
			font-size: 20px;
		}
	}

	.landing-signup-box{
		.grey-box{
			padding: 30px;
			.row{
				.right-list {
					li {
						font-size: 14px;
					}
				}
				.btn-wisetalkies{
					// padding: 12px 65px;
					height: 55px;
					font-size: 15px;
				}
			}
		}
	}

	.landing-instructor-box {
		padding-bottom: 60px;
		.content {
			display: block !important;

			// .content-image-box {
			// 	height: 300px;
			// }
			.landing-instructor-content {
				// min-height: unset;
				.landing-instructor-title{
					font-size: 16px;
					line-height: 40px;
				}
				.landing-instructor-txt{
					font-size: 12px;
				}
			}
		}
	}

	.landing-instructor-modal .modal-box-container {
		margin: 40px 40px 58px;
		max-height: unset;
		padding: 0;
		.modal-box {
			max-height: 78vh;

			.content-details-box .content-feature-box-work{
				flex-wrap: wrap;
			}
			h2{
				font-size: 24px;
			}
			.content-head-box p{
				font-size: 14px;
			}
		}
	}
	.landing-instructor-modal.modal-dialog{
		height: 90vh !important;
	}

	.training-includes .training-title h3{
		line-height: 40px;
	}

	.download-app{
		.app-content{
			h3{
				font-size: 30px;
				line-height: 40px;
			}
			.app-image-container{
				width: 50%;
			}
		}
	}

	.discover-subtitle{
		line-height: 24px;
	}

	.discover-hr{
		margin: 10px auto 10px;
	}

	.instructor-read-more p {
		text-align: initial;
	}
	
	.course-note-title{
		span{
			small{
				display: none;
			}
		}
	}

	.course-detail-hero-container .course-note-form h5 {
		width: unset;
	}

	.workshop-box{
		flex-direction: column;

	}
	.instructor-tab{
		padding: 10px 15px;
	}
	.signup-sub{
		// top: 11%;
	}

	.page-breadcrumbs{
		a{
			font-size: 12px;

		}
		align-items: baseline;
		svg{
			display: none;
		}
	}
	
	.module-infocard h2{
		font-size: 20px;
	}

	.course-detail-container{
		.module-content {
			margin-top: 10px;
			// padding: 0 10px;

			span.module-incomplete {
				font-size: 14px;
			}
			
			.module-lesson-content-head {
				.module-lesson-content-head-lesson-name{
					gap: 0;
				}
				.lesson-image {
					width: 40%;
				}
			}

			.module-lesson-content p {
				font-size: 14px;
			}
			
		}
		.module-lesson-button{
			padding: 8px 14px;
			svg{
				font-size: 18px;
			}
		}
		// .module-container{
		// 	height: unset;
		// }

		.course-detail-wrapper {
			margin: 0 20px;
		}

		.course-detail-head-container {
			min-height: 235px;
		}

		.course-hr{
			display: none;
		}
	}

	.lesson-complete-modal-container {
		width: 90%;
	}

	.module-discussion p {
		font-size: 12px;
	}
	.module-workshop {
		flex-direction: column;
		gap: 0;
		.workshop-image-container{
			width: 100%;
		}
		.module-details{
			width: 100%;
		}
	}
	.instructor-tab-title span{
		font-size: 16px;
	}
	.course-about-instructor-info div p{
		font-size: 14px;
	}
	.course-detail-container .module-lesson-content-complete p{
		font-size: 14px;
	}

	.module-info .question-title {
		font-size: 14px;
	}
	.module-content .module-read-more p {
		font-size: 12px;
	}
	.course-detail-head{
		h3{
			font-size: 18px;
		}
	}
	.course-note-form h5 {
		font-size: 13px;
	}
	.course-note-title{
		span{
			font-size: 13px;
		}
	}
	.device-notifications {
		right: -55px;
		
		// overflow: scroll;
		height: 295px;
		width: 200px;
	}

	.notifications-info {
		height: 220px;
	}

	.device-notifications-title{
		h3{
			font-size: 20px;	
		}
	}
	.notification{
		padding: 10px 0 ;

		p{
			font-size: 12px;
		}
	}

	.instructor-icons-info .icon-packs p {
		display: none;
	}

	.breadcrumbs-links {
		align-items: flex-end;
	}

	.testimonial-txt {
		width: 100%;
		top: 65%;
		right: 0;
	}

	.instructor-wrapper {
		padding: 10px;
	}

	.reviews-info {
		flex-wrap: wrap
	}

	.breadcrumbs-links a {
		font-size: 14px;
	}

	.instructor-info-titles h1 {
		font-size: 35px;
	}

	.instructor-cousre-images {
		grid-template-columns: 1.2fr;
	}

	.reviews h3 {
		margin-bottom: 10px;
	}

	.reviews-card p {
		text-align: unset;
		font-size: 14px;
	}

	.articles-share h2 {
		text-align: center;
	}

	.articles-share-links {
		justify-content: center;
	}

	.category-class-card {
		&:hover {
	
			.card-details {
				transform: translateY(-20%);
			}
		}

		.card-details .btn-branded {
			transform: translateY(150%);
		}

		&:hover .card-details .btn-branded {
			transform: translateY(15%);
		}
	}

	.category-class-card .card-details a {
		font-size: 12px;
		padding: 12px;
	}

	.communities-form h2 {
		font-size: 30px;
	}

	.right-one-box .grey-btn ,
	.right-one-box .blue-btn {
		font-size: 13px;
		margin-top: 15px;
	}

	.right-one-box .d-grid {
		padding-left: 25px;
		padding-right: 25px;
	}

	.login-box .form-control {
		font-size: 12px;
	}

	.signup-box p.first-txt {
		font-size: 22px;
	}

	.signup-modal input[type=text] {
		font-size: 12px;
	}

	.signup-box .overlay {
		// top: 0px;
		// height: 365px;
	}

	.signup-box .box-shadow {
		height: 275px;
	}

	.signup-modal {
		padding-top: 50px;
	}

	.signup-box h3 {
		text-align: center;
	}

	.signup-sub {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.signup-box .FlagBox {
		padding: 0 15px;
		// margin-bottom: 15px;
	}

	.signup-box .form-control {
		font-size: 12px;
	}

	.signup-box [type=submit] {
		padding: 7px 67px;
	}

	.signup-box .overlay {
		top: 55px;
	}

	.signup-box .form-label {
		font-size: 16px;
	}

	.signup-box .modalcontainer .namebox {
		span {
			width: 80%;
		}
	}

	.certificate-box h3 {
		font-size: 30px;
		// text-align: center;
		margin-bottom: 10px;
	}

	.certificate-box .certi-link {
		font-size: 20px;
		margin-bottom: 0px;
	}

	.community-trending-sub h4 {
		font-size: 14px;
	}

	.contributors {
		margin-bottom: 30px;
	}

	.new-session {
		padding-bottom: unset;
	}

	.faq-box h3 {
		font-size: 30px;
		margin-bottom: 10px;
		line-height: 40px;
	}
	.wisecraft-faq-box h3 {
		font-size: 30px;
		margin-bottom: 10px;
		line-height: 40px;
	}

	.discover-tabs {
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.contact-box h3 {
		font-size: 35px;
		line-height: unset;
		text-align: center;
	}

	.overlay {
		// top: -7px;
	}

	.selected-block h2 {
		font-size: 22px;
	}

	.login-form [type=text] {
		font-size: 12px;
	}

	.FlagBox .num {
		font-size: 12px;
		margin-right: 10px;
	}

	.overlay {
		// height: 250px;
	}

	.weare-box p {
		font-size: 16px;
	}

	.welive-box h3 {
		font-size: 33px;
	}

	.testimonial-one {
		display: flex !important;
		flex-direction: column-reverse;
	}

	.testimonial-txt {
		position: unset;
		padding: 17px 24px;
	}

	.testimonial-one img {
		width: 100%;
		margin-bottom: 15px;
	}

	.login-box .login-sub p {
		font-size: 19px;
	}

	.login-box .login-form {
		p {
			text-align: center;
		}
		h3{
			text-align: center;
		}
	}

	.login-form .PhoneInput {
		padding: 12px;
	}

	.login-form [type=submit] {
		// margin: 10px 0;
		padding: 12px 35px;
	}

	.category-page .alert-get-started p {
		margin-right: unset;
	}

	.package-box .plan-table td {
		font-size: 16px;
	}

	.package-details .sub-title {
		font-size: 18px;
	}

	.Header {
		align-items: center;
		// padding: 30px 30px;
	}

	.snip1584 {
		.coming-title {
			font-size: 16px;
		}
		.coming-txt {
			font-size: 14px;
			margin-bottom: 0;
		}
	}

	.bookmark-box {
		padding-top: 10px;
	}

	.progress-box {
		padding-top: unset;
		padding-bottom: unset;
	}

	.session-content h3 {
		font-size: 16px;
	}

	.session-content {
		.new-wise {
			margin-bottom: 10px;
			font-size: 16px;

		}

		h4 {
			font-size: 18px;
			margin-bottom: 0;
		}

	}
	
	.session-content {
		.name1 {
			font-size: 18px;
		}
	}

	.curriculum-content {
		padding: 25px 18px;

		.topic-box {
			.topic-box-head-container {
				.topic-box-head-container-content{
					padding: 10px;
				}
			}
			.topic-sub{
				padding: 15px;
			}
		}

		.accordion-item{
			border: none;
			.accordion-header span {
				display: none;
			}
		}
		.accordion-body {
			padding: 10px;
		}
		.accordion-header h4 {
			font-size: 16px;
		}
	}

	.curriculum-title {
		font-size: 30px;
		margin-bottom: 10px;
	}

	.meta-info img {
		margin-right: unset;
	}

	.topic-box .image-container {
		width: 100%;
		
	}

	.topic-box .topic-sub {
		width: 100%;
		padding: 10px;

		h3{
			// font-size: 12px;
		}
	}

	.topic-box {
		margin: unset;
		margin-top: 25px;
		height: unset;
		img{
			height: 200px;
		}
		.topic-txt1{
			margin-bottom: 0;
		}
	}

	.read-more{
		font-size: 14px;
	}

	.instructor-info-general-title{
		margin-bottom: 0;
	}

	.instructor-info-images{
		width: 100%;
		margin-bottom: 10px;
		.instructor-img{
			width: 70%;
			margin: 0 auto;
		}
	}

	.instructor-about-info q {
		font-size: 14px;
	}

	.instructor-course-container {
		.accordion-item{
			padding: 15px;
			.accordion-body{
				.btn-branded{
					padding: 10px 15px;
				}
			}
		}
		.accordion-body {
			padding: 0;
			.accordion-item {
				padding: 10px 0;
				.accordion-header{
					h4{
						font-size: 14px;
						margin-bottom: 0;
					}
					.accordion-button::after{
						background-size: 0.75rem;
					}
				}
				.accordion-body{
					padding: 0;
				}
			}
		}
		.topic-box {
			.topic-box-head-container .topic-box-head-container-content{
				padding: 0 0 0 10px;

				h3{
					font-size: 12px;
				}
			}
			.topic-txt1{
				font-size: 12px;
			}
		}
	}
	.instructor-about-info{
		margin: 50px auto;
	}

	.accordion-body p {
		text-align: initial;
		font-size: 13px;
	}

	.contact-box h4 {
		text-align: center;
		font-size: 25px;
	}

	.contact-txt li span {
		font-size: 15px;
	}

	.contact-form [type=submit] {
		width: 75%;
	}

	.discussion-card-info {
		h5 {
			// font-size: 12px;
		}

		span {
			font-size: 12px;
		}
	}
	.discussion-card-info2{
		.discussion-wrapper{
			.discussion-details-section{
				padding: 10px;
				.repiles-info{
					padding-left: 10px;
					.repiles-card{
						.discussion-details-card-info{
							gap: 10px;
						}
					}
				}
			}
		}
	}

	.selected-block button {
		font-size: 15px;
	}

	.selected-block {
		padding-top: 10px;
		padding-bottom: 15px;
		h3{
			text-align: center;
		}
		.selected-details{
			flex-direction: column;
			align-items: center;
			text-align: center;
			.selected-block-price-head{
				justify-content: center;
			}
		}
	}

	.all-notes h3 {
		// text-align: center;
		font-size: 30px;

	}

	.course-box h3 {
		text-align: center;
		font-size: 30px !important;
	}

	.communities-head row p {
		text-decoration: unset;
	}

	.communities-head {

		h2 {
			text-align: center;
		}

		p {
			font-size: 14px;
		}
	}

	.contributors-head p {
		font-size: 15px;
	}

	.contributors-info .font {
		font-size: 12px;
	}

	.communities-form .communities-form-detail .communities-discussion-form .form-control {
		padding-left: 10px;
	}

	.grey-box .right-list li {
		font-size: 12px;
	}

	.weare-box .logo-image-container {
		width: 330px;
	}

	.package-box .plan-table td {
		font-size: 13px;
	}

	.faq-box .question p {
		text-align: unset;
		font-size: 14px;
	}

	.wisecraft-faq-box .question p {
		text-align: unset;
		font-size: 14px;
	}
	.wisecraft-faq-box .faq-title {
		font-size: 22px;
	}

	.main-hero-box-container {
		height: unset;
	}

	.membership-main h3 {
		font-size: 25px;
	}

	.membership-box .plan-title h1 {
		font-size: 33px;
		margin-bottom: 10px;
	}
	
	.callback {
		padding-top: 120px;
    	padding-bottom: 120px;
		.card{
			width: 90%;
			ul{
				margin-bottom: 0;
				li{
					flex-direction: column;
				}
			}
			.button-box{
				flex-direction: column;
				:last-child{
					margin-left: 0;
					margin-top: 10px;
				}
			}
			.query{
				width: 100%;
			}
		}
	}

	.testimonial-one img {}

	.login-box .login-form {
		p {
			font-size: 16px;
			margin-bottom: 15px;
			margin-top: 5px;
		}
		form.position p {
			font-size: 12px;
		}
	}

	.login-box .login-sub {
		left: 8%;
		transform: translateY(-121px);
	}

	.login-box .login-form h3 {
		font-size: 20px;
	}

	.login-box .login-form .login-title {
		font-size: 20px;
		text-align: center;
	}

	.fixed {
		// padding: 30px 30px !important;
	}

	.footer .col-sm-4 {
		width: 35%;
	}

	.width-adjuster {
		width: 32%;
	}

	.footer p {
		font-size: 13px;
	}

	.footer-contents ul li {
		font-size: 13px;
		line-height: 16px;
	}

	.trending-box h3 {
		font-weight: 400;
		font-size: 30px;
		// text-align: center;
	}

	.bookmark-box {
		padding-bottom: 40px;
	}

	.coming-soon h3 {
		font-size: 30px;
		font-weight: 400;
	}

	.grey-box .right-title {
		font-size: 30px;
		line-height: 40px;
	}

	.footer-privacy {
		flex-direction: column;
		align-items: center;
	}

	.Nav .Burger {
		width: 93%;
		text-align: right;
		// margin: 26px 0 0 0;
		svg{
			cursor: pointer;
		}
	}

	.Nav div.Burger{
		margin-right: 10px;
	}

	.Nav a {
		font-size: 16px;
		line-height: 16px;
		// padding: 0 5px;
	}

	.dropdown-menu a {
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 20px;
	}

	.found-title {
		font-size: 24px;
		line-height: 28px;
	}

	.found-txt {
		font-size: 18px;
		line-height: 21px;
	}

	.login-box {
		padding-top: 100px;
		padding-bottom: 60px;
	}

	.login-form {
		padding: 10px 25px;
	}

	.search-btn {
		width: 249px;
	}

	.Nav .dropdown-menu {
		// width: 277px;
		padding: 18px;
	}

	.bottom-footer p.right-txt {
		text-align: center;
	}

	.coming-soon .slick-slide .snip1584 {
		// width: 385px;
		// height: 217px;
		padding: 15px;
	}

	.right-one-box .container-fluid {
		padding: 0 15px;
	}

	.grey-box {
		padding: 20px 15px;
		.rupee-txt{
			font-size: 20px;
    		line-height: 30px;
		}
	}

	.right-tab {
		margin-top: 20px;
	}

	// .testimonial-one img {
	// 	width: 55%;
	// }


	.testimonial-txt p {
		margin-bottom: 0;
	}

	.testimonials-box .slick-dots {
		margin: 0 auto;
	}

	.testimonials-box .slick-dots li {
		height: 0px;
	}

	.testimonials-box .slick-prev {
		left: -3%;
	}

	.testimonials-box .slick-next {
		right: -3%;
	}

	.testimonial-txt blockquote {
		font-size: 19px;
	}

	.testimonial-title {
		font-size: 14px;
	}

	.trending-link {
		margin-bottom: 15px;
		font-size: 18px;
	}

	.trending-box .row {
		align-items: center;
		margin-bottom: 44px;
		margin: 0 auto;
	}

	.trending-box .slick-slide img {
		width: 100%;
	}

	.trending-box .col-sm-6 {
		width: 50%;
	}

	.trending-box .slick-prev {
		left: -10px;
	}

	.trending-box .slick-next {
		right: -10px;
	}

	.col-four-box {
		margin-left: 0px;
		margin-top: 8px;
	}

	.coming-soon .slick-prev,
	.coming-soon .slick-next {
		width: 48px;
		height: 48px;
		top: 114%;
	}

	.coming-soon .slick-next {
		right: 0px;
	}

	.coming-soon .slick-prev {
		left: 71%;
	}

	.cross-right-box h3 {
		font-size: 25px;
	}

	.reverse-column {
		flex-direction: column;
	}

	.col-four-box-left {
		margin-top: 8px;
		transform: translateX(0px);
		order: 2;
	}

	.cross-right-box {
		padding: 15px 18px;
	}

	.discover-box .content .content-overlay {
		width: 385px;
	}

	.discover-box .slick-slide img {
		width: 100%;
	}

	.hero-box .container-fluid {
		padding-right: 0;
	}

	// .hero-btn {
	// 	margin-bottom: 20px;
	// }

	.found-box img {
		width: 100%;
	}

	.contact-box {
		padding-top: 110px;
		padding-bottom: 60px;
	}

	.contact-grey {
		padding: 24px;
		margin: 0 auto;
	}

	.contact-info {
		padding: 24px;
		// margin-bottom: 40px;
	}

	.contact-txt li {
		font-size: 16px;
		line-height: 19px;
	}

	.contact-txt li img {
		width: 24px;
	}

	.contact-form {
		padding-left: 12px;
	}

	.package-table {
		flex-wrap: nowrap;
		overflow-x: scroll;
		padding: 20px 0;
	}

	/* width */
	.package-table::-webkit-scrollbar {
		height: 5px;
		border-radius: 50%;
	}

	/* Track */
	.package-table::-webkit-scrollbar-track {
		background: rgba(2, 170, 176, .5);
		border-radius: 20px;
	}

	/* Handle */
	.package-table::-webkit-scrollbar-thumb {
		background: rgba(0, 205, 172, .5);
		border-radius: 20px;
	}

	/* Handle on hover */
	.package-table::-webkit-scrollbar-thumb:hover {
		background: #00CDAC;
	}

	.all-notes .slick-prev,
	.all-notes .slick-next {
		width: 62px;
		height: 62px;
		background: #191C21;
		z-index: 9;
		border-radius: 50%;
	}

	.all-notes .slick-prev:hover,
	.all-notes .slick-prev:focus,
	.all-notes .slick-next:hover,
	.all-notes .slick-next:focus {
		background: #02AAB0;
	}

	.all-notes .slick-prev {
		left: -10px;
	}

	.all-notes .slick-next {
		right: -10px;
	}

	.all-notes .slick-prev:before {
		background-image: url('../images/prev-icon.png');
		background-repeat: no-repeat;
		background-position: center;
		width: 24px;
		height: 24px;
		font-size: 24px;
		color: transparent;
	}

	.all-notes .slick-next:before {
		background-image: url('../images/nxt-icon.png');
		background-repeat: no-repeat;
		background-position: center;
		width: 24px;
		height: 24px;
		font-size: 24px;
		color: transparent;
	}

	.selected-block button {
		float: none;
		margin: 15px auto 0;
		display: block;
	}

	.weare-box .video-box img {
		width: 100%;
	}

	.welive-box .icon-box {
		margin-bottom: 20px;

	}

	// .login-box img.login-bg {
	// 	height: 454px;
	// }

	.login-sub p {
		font-size: 17px;
	}


	.hero-box{
		padding-bottom: 40px;
	}

	.discover-box {
		padding-bottom: 20px;
	}

	.hero-box .slick-slide {
		padding: 0 7px;
	}

	// Discover Box

	.discover-box .content {
		display: block !important;
	}

	.discover-box .slick-slide img,
	.trending-box .slick-slide img {
		width: 100%;
	}

	.discover-box .content .content-overlay,
	.discover-box .content-details {
		width: 100%;
	}

	.discover-box .content-details {
		padding: 30px 32px;
	}

	.discover-box .content:hover .content-details {
		// left: 50%;
	}

	.discover-box .fadeIn-bottom {
		// top: 90%;
	}

	.remarks-modal-main-box .notes-box {
		margin: 0;
		.close-modal{
			top: 20px;
			right: 20px;
			padding: 5px;
			svg{
				height: 25px;
				width: 25px;
			}
		}
		.title-line h3 {
			font-size: 30px;
		}
		.single-notes-navigation {
			float: right;
			small{
				display: none;
			}
			.single-notes-navigation-icon{

				svg{
					margin-left: -3px;
					&:last-child{
						display: none;
					}
				}
			}
		}
	}

	.notes-box span {
		float: left;
		margin-bottom: 15px;
	}

	.notes-box .buttons button {
		margin-bottom: 15px;
	}

	.alert-get-started {
		display: grid;

		>p {
			font-size: 15px;
		}

		p {
			margin-bottom: 12px;
		}

		.alert-action {
			display: grid;

			a.btn {
				display: block;
				margin-top: 13px;
			}
		}
	}

	.signup-modal .col-lg-6:last-child {
		// position: absolute;
		// top: 20%;
		// left: 0%;
	}

	.signup-box {
		margin: 0 10px;
		padding: 20px;
	}

	.signup-sub img {
		width: 200px;
	}

	.mobile-signup {
		margin: 0;
	}

	.close-modal {
		position: absolute;
		top: 0;
		z-index: 999;
		right: 0;
	}

	.signup-sub p {
		font-size: 16px;
	}

	.session-content {
		// padding-top: 30px;
		// padding: 0 65px;
	}

	.meta-info {
		width: 100%;
		margin: 50px auto;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-top: 0;
	}

	.question h4 {
		font-size: 14px;
	}

	// .topic-box {
	// 	flex-direction: column;
	// }

	// .topic-box img.topic-img {
	// 	height: auto;
	// 	margin-right: 0;
	// 	width: -webkit-fill-available;
	// }
 .accordion-button {
		font-size: 16px;
	}

	.team-box .slick-slider {
		margin: 0 20px;
	}

	.team-box .slick-prev {
		left: -28px;
	}

	.team-box .slick-next {
		right: -28px;
	}

	.apple-badge,
	.google-play-badge {
		width: 75px;
	}

	.apple-badge img,
	.google-play-badge img {
		margin-right: 20px;
	}

	.hide-mob {
		display: none;
	}

	.non-hero-box .container-fluid {
		padding-left: 12px;
	}

	.trending-box .col-md-6 {
		width: 100%;
	}

	.non-hero-box {
		padding-top: 35px;
		padding-bottom: 35px;
	}

	.non-video {
		padding-top: 35px;
		padding-bottom: 35px;
	}

	.non-bottom {
		padding-bottom: 0px;
	}

	.non-hero-box .slick-prev {
		left: -10px;
	}

	.non-hero-box .slick-next:hover,
	.non-hero-box .slick-next:focus {
		background: #02AAB0;
	}

	.non-hero-box .slick-next {
		display: block !important;
	}

	.non-hero-box .slick-next {
		width: 62px;
		height: 62px;
		background: #191C21;
		z-index: 9;
		border-radius: 50%;
		right: -10px;
	}

	.non-hero-box .slick-next:before {
		display: initial;
	}

	.non-hero-box .slick-next:before {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASCAYAAABit09LAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABVSURBVHgBtdLBCQAgCAVQaYJGaIQ2b4RGaIRGqA3MwKtpoMKny6NCBUQslHZPeBWBTJkcFZdwPO6XXHGlrFDcE9hqW26Tn7YivT2/SB6hH2KYLYt7AImN4jATjtkRAAAAAElFTkSuQmCC);
		background-repeat: no-repeat;
		background-position: center;
		width: 24px;
		height: 24px;
		font-size: 24px;
		color: transparent;
	}

	.welcome-box .first-box {
		padding: 16px;
	}

	.welcome-box .btn {
		float: none;
		margin-top: 0;
	}

	.welcome-box p {
		font-size: 16px;
		line-height: 19px;
		width: auto;
	}

	.course-box .col-lg-6 {
		width: 50%;
	}

	.progress-box .slick-prev {
		left: -10px;
	}

	.progress-box .slick-next {
		right: -10px;
	}

	.member-checkout .width28 {
		width: 100%;
	}

	.member-checkout .width58 {
		width: 100%;
	}

	// .category-class-card .card-details h3 {
	// 	font-size: 16px;
	// }

	.category-class-card .card-details p {
		font-size: 12px;
	}

	.category-class-card hr {
		margin: 4px auto 6px;
	}

	.classes-container .col-sm-12 {
		width: 100%;
	}

	.category-class-card .card-details {
		padding: 10px;
	}

	.category-class-card .btn-branded {
		padding: 4px 8px;
		font-size: 11px;
		display: block;
	}

	.contact-box p {
		font-size: 15px;
		line-height: 17px;
	}

	.contact-box .text-center {
		text-align: left !important;
	}

	.hide-sm {
		display: none;
	}

	.discover-tabs li {
		font-size: 14px;
		margin: 0 10px;
	}

	.discover-title, .cross-title {
		font-size: 30px;
		line-height: 40px;
	}

	.bottom-footer {
		padding: 20px;
	}

	.notes-box {
		margin: 50px 30px;
	}

	.settings-container {
		.settings-subscription{
			margin-bottom: 20px;
		}
		
		.settings-head {
			padding: 25px;
			margin-bottom: 20px;

			svg {
				display: none;
			}
			
			.breadcrumbs-links {
				align-items: center;
			}
		}

		
		.myaccount{
			.myaccount-head-btn .button-loader-container .button-loader{
				height: 34px;
			}
			.myaccount-infos {
				h3 {
					font-size: 20px;
					margin-bottom: 25px;
				}
				.myaccount-info2{
					h3 {
						padding: 20px;
						margin-bottom: 0;
					}

					.info2-your-photo{
						padding: 35px;
					}

					.setting-attach {
						margin: 0 auto 5%;
						display: none;
					}
					small{
						bottom: 8px;
						font-size: 12px;
					}

					.info2-image-overlay{
						display: block;
					}
				}
			}
		}
	}

	.setting-icons {
		flex-wrap: wrap;

		.nav-item {
			&:has(.nav-link.active){
				width: unset;
			}

			a{
				padding: 16px;
				svg {
					margin-right: 0;
				}
			}
			.nav-link.active {
				span {
					display: none;
				}
			}
		}
	}

	.delete-modal .myaccount-info {
		.myaccount-head-btn span[type="submit"]{
			padding: 7px 40px;
		}
	}

	.myaccount-info-title {
		// flex-wrap: wrap;
		flex-direction: column;
		gap: 20px;
	}
	.chatbox {
		.chat-area-msg p {
			padding: 10px;
			font-size: 12px;
		}
	}
	.myaccount-head-btn button {
		
		font-size: 12px;
		svg{
			width: 10px;
			vertical-align: top;
		}
	}
	.myaccount-head-btn button {
		padding: 8px 22px;
	}
	.transaction-pagination{
		p{
			font-size: 12px;
		}
		ul li{
			font-size: 12px;
		}
	}
	.input-info label {
		font-size: 12px;
	}
	.myaccount-form .form-inputs {
		flex-wrap: wrap;
		.input-info {
			width: 100%;
		}
	}

	.checkout-box {
		.checkout-logo{
			.logo-image{
				width: 150px;
			}
			h4{
				font-size: 18px;
			}
		}
		.checkout-inner-box {
			.checkout-left, .checkout-left-order-summary{
				flex-wrap: wrap;
				gap: 6px;
			}
			.checkout-header{
				.checkout-form{
					.form-inputs{
						// flex-direction: column;
						.input-info{
							// width: 100%;
							label{
								font-size: 16px;
							}
							input{
								padding-right: 15px;
							}
						}

					}
				}
				.checkout-footer{
					label{
						font-size: 14px;
					}
				}
			}
			.order-summary {
				h2{
					font-size: 18px;
				}
				h5, .order-mid-container .order-benefit-container p{
					font-size: 14px;
				}
				.order-checkout-box {
					.order-discount {
						.order-discount-box{
							flex: 1;
							padding: 5px;

							.pricetag{
								font-size: 14px;

								&::before{
									text-indent: 11px;
									left: -16px;
									border-right: 16px solid #5fc4b8;
									border-top: 12px solid transparent;
									border-bottom: 13px solid transparent;
								}
							}
							.close{
								height: 18px;
								width: 18px;
								font-size: 12px;
							}
						}
						button{
							width: 35%;
							padding: 11px 20px;
						}
					}
					.order-total {
						h6,span{
							font-size: 16px;
						}
					}
				}
			}
		}

		.footer-content .footer-nav{
			margin-bottom: 60px;
		}
	}
	.terms-and-conditions,.privacy-policy, .cancellation-refund{
		.hero-box .row{
	
			h3{
				font-size: 26px;
				line-height: 30px;
				margin-bottom: 20px;
			}
			
			p {
				font-size: 16px;
			}
	
			ol {
				li {
					font-size: 16px;
					margin-bottom: 16px;
	
					h6{
						font-size: 16px;
					}
				}
			}
		}
	}

	.bottom-footer .bottom-footer-text-content{
		flex-direction: column;
		align-items: center;
	}

	.bottom-border{
		.address{
			display: none;
		}
	}

	.bottom-banner{

		.offer{
			width: 100%;
			margin-bottom: 10px;
			text-align: center;
		}
		.time-container{
			width: 100%;
			flex-direction: column;
			gap: 20px;

			.timer{
				margin-right: 0;
			}
		}
	}

	.promotional-modal{
		.modal-content{
			.winner-date{
				display: inline-block;
				width: 100%;
			}

			.btn-branded{
				width: fit-content;
			}

			.secret-code{
				width: 100%;
			}
		}
	}

	.no-data-found-container{
		margin: 30px 0;
	}

	.detail-modal .modal-content {
		width: 100%;
		.input-info {
			label{
				font-size: 16px;
			}
			input {
				padding-right: 15px;
			}
		}
	}
	.account-delete-otp-container{
		.otp-input{
			margin: 0;
		}
		.disable-account-resend-container{
			width: 100%;
			 display: flex;
			 justify-content: center; 
			 align-items: center; 
			 gap: 5px;
		}
		.delete-account-error{
			font-size: 14px;
			padding: 0;
		}
		.myaccount-head-btn{
			flex-direction: column;
			.cancel, .submit{
				padding: 7px 40px;
			}
		}
	}
	.video-placeholder {
		height: 20vh;
		padding: 10px;
	}
}

@media (min-width:481px) {
	.hide-mob {
		display: inline-block;
	}

	.hide-des {
		display: none;
	}
}

@media all and (max-width: 360px) {
	.footer .col-sm-4 {
		width: 36%;
	}

	.curriculum-content {
		padding: 25px 18px;

		.topic-box {
			.topic-box-head-container {
				.topic-box-head-container-content{
					display: none;
				}
				.image-container{
					width: 100%;
					margin: 15px;
				}
			}
			.topic-sub{
				padding: 0 15px 15px;
				.topic-txt,h3{
					display: block;
				}
				.topic-txt1{
					font-size: 12px;
				}
			}
		}

		.accordion-item{
			border: none;
			.accordion-header span {
				display: none;
			}
		}
		.accordion-body {
			padding: 10px;
		}
		.accordion-header h4 {
			font-size: 16px;
		}
	}

	.course-detail-hero-container .course-detail-head h3 {
		font-size: 16px;
	}
	.module-question {
		padding: 10px;
	}
	.course-detail-hero-container .module-info .question-title {
		font-size: 12px;
	}
	.course-detail-hero-container .instructor-tab-title span {
		font-size: 16px;
	}

	// .breadcrumbs-links a{
	// 	font-size: 12px;
	// }
	.module-infocard {
		padding: 20px;
	}
	.module-info .question-title{
		font-size: 13px;
	}
	.module-details span{
		font-size: 12px;
	}
	.course-detail-head {
		h3{
			font-size: 14px;
		}
		p{
			font-size: 12px;	
		}
	}
	.instructor-pic-title{
		span{
			font-size: 14px;
		}
	}

	.course-detail-container .module-content .module-lesson-content-head .lesson-image {
		width: 70%;
	}
	.course-detail-container .module-content .module-lesson-content {
		.module-incomplete{
			font-size: 12px;
			line-height: 1.5;
			
		}
		p{
			white-space: nowrap;
			width: 90px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: unset;
		}
	}

	.course-detail-container .course-detail-head-container {
		min-height: 160px;
	}
	
	.course-detail-container .module-lesson-content-complete{
		p {
			font-size: 14px;
		}
	}

	.course-box .course-box-title h3{
		font-size: 22px;
	}

	.instructor-wrapper-infos {
		gap: 15px;
	}

	.instructor-info-titles h3 {
		margin-bottom: 10px;
	}

	.reviews-info img {
		margin: 0 auto;
	}

	.instructor-icons-info {
		gap: 25px;
	}

	.page-breadcrumbs {
		padding: 15px;
		flex-direction: column;
		
	}
	.course-detail-head > div > p > span{
		font-size: 10px;
	}

	.package-name h3 {
		font-size: 22px;
	}

	.membership-box .package-name .plan-price {
		font-size: 18px
	}

	.checkout-box {
		.pricetag span{
			display: inline-block;
			width: 90px;
			text-overflow: ellipsis;
			overflow: hidden;
			line-height: 1;
			/* align-self: center; */
			vertical-align: middle;
		}
	}

	.hero-box h3 {
		font-size: 32px;
	}
	
	.career .hero-box .row h3 {
		font-size: 32px;
		line-height: 40px;
	}

	.faq-box h3 {
		// font-size: 24px;
	}

	.wisecraft-faq-box .faq-title {
		font-size: 18px;
	}

	.faq-head p {
		font-size: 14px;

	}

	.signup-modal input[type=text] {
		padding: 8px;
	}

	.FlagBox {
		padding: 0px 15px;
	}

	.signup-box .FlagBox .num {
		// margin-right: 0;
	}

	.signup-box .overlay {
		// top: 0;
	}

	.signup-box p.first-txt {
		font-size: 20px;
		margin-bottom: unset;
	}

	.signup-box p.sub-txt {
		font-size: 12px;
		margin-bottom: 10px;
	}

	.meta-info .meta-content p {
		font-size: 12px;
	}

	.session-content h4 {
		font-size: 18px;
	}

	.session-content .name1 {
		font-size: 14px;
	}

	.meta-info {
		// margin: 10px 0;
	}

	.curriculum-title {
		font-size: 25px;
	}

	.curriculum-content .accordion-button {
		font-size: 13px;
	}

	.session-content {
		padding: 10px 0 20px 10px;

		.new-wise {
			font-size: 15px;
		}

	}

	.contact-txt li span {
		font-size: 13px;
	}


	.contact-box {
		p {
			font-size: 12px;
			margin-bottom: 10px;
			text-align: center;
		}

		h3 {
			font-size: 25px;
		}
		.contact-txt li{
			margin-bottom: 10px;
		}
		.contact-form .form-control {
			padding: 11px;
		}
	}

	.welive-box h3 {
		font-size: 30px;
	}

	.selected-block {
		.selected-details{
			flex-direction: column;
			align-items: center;
		}
		h2 {
			font-size: 20px;
		}

		h3 {
			font-size: 16px;
		}
	}

	.selected-block button {

		padding: 12px 34px;
	}

	.weare-box .logo-image-container {
		width: 300px;
	}

	.width-adjuster {
		width: 32%;
	}

	.faq-box .question p {
		font-size: 13px;
	}

	.wisecraft-faq-box .question p {
		font-size: 13px;
	}

	.login-box .login-sub p {
		font-size: 15px;
	}

	.membership-box .package-box .box h5 {
		font-size: 13px;
	}

	.membership-box .package-box .box h3 {
		font-size: 20px;
	}

	.testimonial-txt blockquote {
		font-size: 14px;
	}

	.testimonials-box h3 {
		font-size: 30px;
	}

	.testimonial-txt {
		right: 1%;
		top: 61%;
	}

	.login-form label {
		font-size: 13px;
	}

	.category-page .alert-get-started p {
		font-size: 14px;
	}

	.category-page a.btn {
		font-size: 13px;
	}

	.package-box .plan-table td {
		font-size: 13px;
	}

	.package-details .sub-title {
		font-size: 14px;
	}
	.settings-container {
		
		.myaccount{
			
			.myaccount-infos {

				.myaccount-info2{

					small{
						bottom: 3px;
						width: 90%;
						margin: 0 auto;
					}
				}
			}
		}
	}

	.membership-main h3 {
		font-size: 22px;
		margin-bottom: 5px;
	}

	.overview {
		.overview-content-main-right {
			.meta-box {
				li{
					justify-content: flex-start;
					.meta1, .meta2 {
						font-size: 15px;
					}
				}
			}
		}
	}

	.course-tab-qna-section .input-group .input-group-text{
		min-width: unset;
	}
	.no-data-found-container{
		width: unset;
	}

}
